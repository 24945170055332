import React from "react";
import { IPlan } from "../../types/ApiTypes";
import SubscriptionPlanFeature from "./SubscriptionPlanFeature";
import Flex from "../container/Flex";

export default function PlanFeaturesContainer({plan}: {plan: IPlan}) {
    return (
        <Flex 
            className="p-2"
            fullWidth
        >
            {
                plan.features && !!plan.features.length
                ? plan.features.map(f => <SubscriptionPlanFeature feature={f} key={f.feature._id} />)
                : <span>Zugriff auf das System</span>                   
            }
        </Flex>
    )
}