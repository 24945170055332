import React from "react";
import { useNavigate } from "react-router-dom";
import ClientContactList from "../../components/clientContacts/ClientContactList";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import useModal from "../../hooks/useModal";
import useTenantSettings from "../../hooks/useTenantSettings";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";
import WithPermissions from "../../components/permissions/WithPermissions";

export default function ClientContactManagement() {

    const { options } = useTenantSettings(true);

    return (
        <WithPermissions permissions={[ "users.clientContacts.read" ]}>
            <Page header={Locale.titles.clientContactManagement} actionButtons={
                options?.interfaces?.datev?.canCreateClientContactsManually 
                ? <UserUpdateForm isClientContact={true} color="primary" title="Neuen Mandantenkontakt erstellen" text="Neuer Mandantenkontakt" /> 
                : undefined 
            }>
                <ClientContactList />
            </Page>
        </WithPermissions>
    )
}