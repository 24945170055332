import React from "react";
import { useAllTeamsStatistics } from "../../../state/swr/statistics/useAllTeamsStatistics";
import { useAnyTeamUserStatistics } from "../../../state/swr/statistics/useAnyTeamUserStatistics";
import { useTeams } from "../../../state/swr/teams/useTeams";
import Button from "../../buttons/Button";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Flex from "../../container/Flex";
import WithPermissions from "../../permissions/WithPermissions";
import { TicketProductivityTab } from "../ProductivityOverview";
import TicketStatsCardGrid from "../TicketStatsCardGrid";

export interface ITeamProductivityOverviewProps {
  teamId?: string,
  setTeamId: (teamId?: string) => void
}

export default function TeamProductivityOverview({ teamId, setTeamId }: ITeamProductivityOverviewProps) {

  const { teams } = useTeams();
  const { loadingTeamUserStatistics, teamUserStatistics } = useAnyTeamUserStatistics(teamId || "");
  const { allTeamsStatistics, loadingAllTeamsStatistics } = useAllTeamsStatistics();

  React.useEffect(() => {
    if (!teams || !teams.length) return;
    if (teamId) return;

    setTeamId(teams[0]._id);
  }, [teams]);


  const resetTeam = () => {
    if (teams && teams.length) return setTeamId(teams[0]._id);
    setTeamId(undefined);
  }

  return (
    <WithPermissions permissions={["statistics.team.read"]}>
      <TicketStatsCardGrid
        currentTab={TicketProductivityTab.Team}
        resetTarget={teamId ?? ""}
        allStatistics={allTeamsStatistics}
        hasTeam
        title="Performance deines Teams"
        cardColor="background"
        isLoading={loadingTeamUserStatistics}
        statistics={teamUserStatistics?.statistics || undefined}
        filterComponent={(
          <Flex row>
            <SearchableComboBox
              values={teams || []}
              itemToId={t => t._id}
              itemToString={t => t.name}
              value={teamId}
              onItemClick={(team) => setTeamId(team?._id)}

            />
            {teamId && <Button onClick={async () => resetTeam()} variant="icon" icon="arrow-clockwise" />}
          </Flex>

        )}
      />
    </WithPermissions>
  )
}