import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useClients } from "../../../state/swr/clients/useClients";
import { useEmployees } from "../../../state/swr/employees/useEmployees";
import { useTicketMessages } from "../../../state/swr/tickets/messages/useTicketMessages";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { TicketState } from "../../../types/ApiTypes";
import IElementProps from "../../../types/element.types";
import { IHydratedTicket } from "../../../types/ticket.schema";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import TeamBanner from "../../teams/TeamBanner";
import UserRoleBanner from "../../user/RoleBanner";
import TicketStateBanner from "../banner/TicketStateBanner";
import TicketNotes from "../notes/TicketNotes";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";
import TicketChatMessage from "./messages/TicketChatMessage";
import "./TicketChat.css";
import TicketChatForm from "./TicketChatForm";
import TicketMentionsBanner from "./TicketMentionsBanner";

interface ITicketChatProps extends IElementProps {
  hydratedTicket: IHydratedTicket
}

interface ITicketColorMap {
  ticketId: string,
  colorMap: { [userId: string]: string }
}

export default function TicketChat({ hydratedTicket }: ITicketChatProps) {

  const {
    ticket,
    assignee,
    client,
    deputy
  } = hydratedTicket;

  const permissions = useTicketPermission("read", ticket);

  const { employeesById } = useEmployees();
  const { clientsById } = useClients();

  const { loadingTicket } = useTicket(ticket._id);
  const { messages, loadingMessages } = useTicketMessages(ticket._id);

  const navigate = useNavigate();
  const chatScrollRef = React.useRef<HTMLDivElement>(null);
  const currentColorMap = React.useRef<ITicketColorMap>({ colorMap: {}, ticketId: "" });

  const { role } = useCurrentTenantRole();

  useEffect(() => {
    setTimeout(() => {
      if (!chatScrollRef || !chatScrollRef.current) return;
      chatScrollRef.current.scrollIntoView();
    }, 50);
  }, [ticket, messages]);

  if (!ticket) return null;

  const isClient = role && role.isClient;

  const ticketAssignee = assignee ? employeesById[assignee] : null;
  const ticketDeputy = deputy ? employeesById[deputy] : null;
  const ticketClient = client ? clientsById[client] : null;

  return (
    <WithPermissions permissions={permissions}>
      <div className="h-100 w-100 d-flex ticket-chat gap-3 flex-column">
        <Flex row justify="between" align="start" fullWidth>
          <Flex fullWidth>
            <Flex wrap row>
              <TicketStateBanner size="big" hydratedTicket={hydratedTicket} align="left" />
              {
                loadingTicket && <LoadingSpinner />
              }
              {
                !isClient && ticketClient && <ClientTypeBanner isLink client={ticketClient} showClientName textOnEmpty="Kein Mandant" />
              }

            </Flex>
            <Flex row>
              {
                !isClient && <TeamBanner team={ticket.team} textOnEmpty="Kein Team" />
              }
              {
                !isClient && ticketAssignee && <UserRoleBanner user={ticketAssignee} displayNameAsValue showOnEmptyText="Kein Bearbeiter" />
              }
              {
                !!ticketDeputy && <Pill text="Bearbeitung in Vertretung" color="#FF4500" />
              }
              <TicketMentionsBanner ticket={ticket} />
            </Flex>
          </Flex>
          <Flex row gap={0} reverse className="ms-auto">
            <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Open} variant="text" />
            <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Resubmission} variant="text" />
            <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Solved} variant="text" />
            <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket isDelete afterAction={() => navigate(-1)} variant="text" />
          </Flex>
        </Flex>
        <Flex className="ticket-chat-container h-100">
          <div className="d-flex flex-column gap-2 w-100" >
            {
              loadingMessages
                ? <LoadingSpinner />
                : (
                  messages && !!messages.length
                    ? messages.map((m: any) => <TicketChatMessage key={m._id} colorMap={currentColorMap.current.colorMap} ticket={ticket} message={m} />)
                    : <strong>Noch keine Nachrichten.</strong>
                )

            }
            {
              ticket && ticket.typingUser && <div className="ticket-chat-form-user-typing-notice"><strong>{ticket.typingUser.mailAddress}</strong> schreibt...</div>
            }
            <div ref={chatScrollRef} />
          </div>
        </Flex>
        <TicketChatForm ticket={ticket} />
        <TicketNotes ticket={ticket} />
      </div>
    </WithPermissions>
  )
}
