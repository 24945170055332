import React from "react";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import { IWithClient } from "../../types/client.element.schema";
import AddresseeBanner from "../addressee/AddresseeBanner";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";
import ClientEmployeeResponsibilityList from "./ClientEmployeeResponsibilityList";
import { useUsers } from "../../state/swr/user/useUsers";
import UpdateClientManagerDialog from "./UpdateClientManagerDialog";
import UpdateClientAliasDialog from "./UpdateClientAliasDialog";
import UpdateClientEmployeeResponsibilityDialog from "./UpdateClientEmployeeResponsibilityDialog";

export default function ClientDetail(props: IWithClient) {

  const {
    client
  } = props;

  const {
    usersById,
    loadingUsers
  } = useUsers();

  const {
    loadingAddressees
  } = useAddressees();

  const {
    clientAlias,
    loadingClientAlias
  } = useAnyClientAlias(client._id);

  const clientManager = usersById[client.clientManager ?? ""];

  return (
    <Flex gap="5" fullWidth>
      <Flex fullWidth>
        <Typography size="18" bold color="primary">Metadaten</Typography>
        <Flex fullWidth gap="0">
          <Typography color="primary" bold>Nummer</Typography>
          <Typography>{client.number}</Typography>
        </Flex>
        <Flex fullWidth gap="1">
          <Flex row fullWidth justify="between">
            <Typography color="primary" bold>Alias</Typography>
            <UpdateClientAliasDialog client={client} />
          </Flex>
          <Flex row fullWidth wrap>
            {
              loadingClientAlias
                ? <LoadingSpinner />
                : (
                  clientAlias?.length
                    ? clientAlias.map((alias, index) => (
                      <UpdateClientAliasDialog
                        button={
                          <Pill
                            hoverIcon="trash"
                            clickable
                            icon="at"
                            key={index}
                            text={alias.aliasMailAddress}
                            withDivider
                            color="primary"
                          />
                        }
                        client={client}
                      />
                    ))
                    : <Typography color="primary">Keine Alias hinterlegt</Typography>
                )
            }
          </Flex>
        </Flex>
      </Flex>
      <Flex fullWidth>
        <Typography size="18" bold color="primary">Zuständigkeiten</Typography>
        <Flex fullWidth gap="4">
          <Flex fullWidth>
            <Flex row fullWidth justify="between">
              <Typography color="primary" bold>Mandatsverantwortung</Typography>
              <UpdateClientManagerDialog client={client} />
            </Flex>
            {
              loadingUsers
                ? <LoadingSpinner />
                : (
                  clientManager
                    ? <RoleBanner user={clientManager} displayNameAsValue showMail />
                    : <Typography color="primary">Keine Mandatsverantwortung hinterlegt</Typography>
                )
            }
          </Flex>
          <Flex fullWidth>
            <Flex fullWidth row justify="between">
              <Typography color="primary" bold>Bereiche</Typography>
              <UpdateClientEmployeeResponsibilityDialog client={client} />
            </Flex>
            <ClientEmployeeResponsibilityList
              row
              client={client}
              labelStyle={{
                color: "primary",
                bold: true,
                size: "12"
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {
        !!(client.legalPerson || client.naturalPerson) && (
          <Flex fullWidth>
            <Typography size="18" bold color="primary">Adressaten</Typography>
            {
              client.legalPerson && (
                <Flex fullWidth gap="2">
                  <Typography color="primary" bold>Juristische Person</Typography>
                  {
                    loadingAddressees
                      ? <LoadingSpinner />
                      : <AddresseeBanner isLink addressee={client.legalPerson} showName showAddress />
                  }
                </Flex>
              )
            }
            {
              client.naturalPerson && (
                <Flex fullWidth gap="2">
                  <Typography color="primary" bold>Natürliche Person</Typography>
                  {
                    loadingAddressees
                      ? <LoadingSpinner />
                      : <AddresseeBanner isLink addressee={client.naturalPerson} showName showAddress />
                  }
                </Flex>
              )
            }
          </Flex>
        )
      }
    </Flex>
  )
}