import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import TemplatePlaceholderChip, { ITemplatePlaceholderActions } from "./TemplatePlaceholderChip";
import { AllPlaceholders, TemplatePlaceholder } from "../../types/templatePlaceholder.schema";

export interface MailTemplateEditElementPlaceholdersProps extends ITemplatePlaceholderActions {
    selectedPlaceholder?: TemplatePlaceholder,
    currentText?: string,
    readOnly?: boolean,
    saveText: (newText: string) => void,
    availablePlaceholders?: TemplatePlaceholder[]
}

export default function MailTemplateEditElementPlaceholders({selectedPlaceholder, readOnly, currentText, saveText, availablePlaceholders, onAdd, onRemove}: MailTemplateEditElementPlaceholdersProps) {
    const selectablePlaceholders = availablePlaceholders ?? AllPlaceholders; 

    if (!selectablePlaceholders || !selectablePlaceholders.length) return null;
    if (!selectedPlaceholder && readOnly) return null;

    return (
        <Flex className="w-100">
            {
                !selectedPlaceholder && <Typography color="primary">Verfügbare Platzhalter</Typography>
            }
            <Flex row wrap className="w-100">
                {
                    selectablePlaceholders.map(p => {
                        if (selectedPlaceholder && selectedPlaceholder !== p) return null;

                        return (
                            <TemplatePlaceholderChip 
                                readOnly={readOnly}
                                key={p} 
                                placeholder={p} 
                                currentText={currentText} 
                                onAdd={onAdd}
                                onRemove={onRemove}
                                saveText={(newText: string) => saveText(newText)}
                            />

                        )
                    })
                }
            </Flex>
        </Flex>
    )
}