import React from "react"
import { sessionSetVerificationMethod } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { SessionVerificationMethod } from "../../types/ApiTypes";
import { Cookies, deleteCookie } from "../../util/cookies";
import LabelButton from "../buttons/LabelButton";
import axios from "axios";
import Button from "../buttons/Button";
import { useSession } from "../../state/swr/session/useSession";

export default function LogInWithOtherUserButton() {

    const { reloadSession } = useSession();

    return (
        <Button 
            text="Mit anderem Nutzer anmelden" 
            icon="person-x"
            variant="subtle"
            color="secondary" 
            onClick={async () => {
                deleteCookie(Cookies.Session);
                await reloadSession(undefined, { revalidate: true });
            }} 
        />
    );
}