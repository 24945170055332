import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ICloudGatewayData } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useCloudGateway = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ICloudGatewayData>(ApiRoutes.CloudGateway.GetCurrentTenantSettings);

    return {
        cloudGatewaySettings: data,
        loadingCloudGatewaySettings: isLoading,
        reloadCloudGatewaySettings: mutate
    }
}