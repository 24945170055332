export const SocketEvents = {
    Users: {
        Updated: "user:updated",
        All: "user:all"
    },
    Domains: {
        All: "domain:all"
    },
    Tenants: {
        All: "tenant:all"
    },
    Clients: {
        All: "client:all",
        ImportProgress: "client:importprogress"
    },
    Teams: {
        All: "team:all"
    },
    Tickets: {
        Escalated: "ticket:escalated",
        All: "ticket:all",
        New: "ticket:new",
        Updated: "ticket:updated",
        RemoveOrDelete: "ticket:remove",
        UserTyping: "ticket:typing",
        UserStoppedTyping: "ticket:stoppedtyping",
        UserOnline: "user:online",
        MessageNew: "ticket:message",
        MessageRead: "ticket:messageread",
    },
    System: {
        Message: "system:message",
        Ping: "system:ping",
        Connect: "connection",
        Disconnect: "disconnect",
        Error: "connect_error"
    }
}