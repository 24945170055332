import { FieldArray } from "formik";
import React from "react";
import { ticketsMerge } from "../../../api/Api";
import { ITicketMergeRequest } from "../../../api/ApiRequests";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import useApi from "../../../hooks/useApi";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Flex from "../../container/Flex";
import CheckBox from "../../formik/CheckBox";
import ModalForm from "../../modal/ModalForm";
import TeamBanner from "../../teams/TeamBanner";
import TicketAssigneeBanner from "../banner/TicketAssigneeBanner";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import TicketCardHeader from "../lists/TicketCardHeader";
import { ITicketActionProps } from "./UpdateTicketStatusButton";

export interface IMergeTicketButtonProps extends ITicketActionProps {
  selectedTickets: Array<ITicket>
}

export interface IMergeTicketFormValues extends ITicketMergeRequest {
  tickets: Array<ITicket>
}

export default function MergeTicketButton2({ selectedTickets, afterAction, variant }: IMergeTicketButtonProps) {

  const { role } = useCurrentTenantRole();

  const dispatch = useAppDispatch();
  const callApi = useApi();

  if (role && role.isClient) return null;

  const hasEnoughTickets = selectedTickets && selectedTickets.length > 1;

  return (
    <ModalForm
      disabled={!hasEnoughTickets}
      button={(
        <Button 
          variant={variant} 
          iconSize={variant === "text" ? 30 : undefined} 
          text={hasEnoughTickets ? `${selectedTickets.length} Tickets zusammenführen` : "Bitte mehr Tickets auswählen"} 
          icon={hasEnoughTickets ? "subtract" : "plus"} 
        />
      )}
      title="Tickets zusammenführen"
      initialValues={{
        mergeIntoTicket: "",
        mergeMessages: false,
        ticketIds: selectedTickets.map(t => t._id),
        tickets: selectedTickets
      } as IMergeTicketFormValues}
      onSubmit={async (values) => {
        const res = await callApi(ticketsMerge(values));
        if (!res || !res.success) return false;
        if (afterAction) afterAction();
        return true;
      }}
      sidebar={formik => <Button type="submit" loading={formik.isSubmitting} text="Zusammenführen und Tickets schließen" color="success" icon="save" />}
    >
      {
        formik => (
          <Flex className="w-100">
            <SearchableComboBox
              className="w-100"
              label="In Ticket zusammenführen"
              value={formik.values.mergeIntoTicket}
              values={selectedTickets}
              itemToId={t => t._id}
              bold
              itemToString={t => t.subject}
              onItemClick={t => formik.setFieldValue("mergeIntoTicket", t?._id)}
            />
            <CheckBox name="mergeMessages" label="Auch Nachrichten zusammenführen" />
            <FieldArray name="tickets">
              {
                arrayHelpers => (
                  <Flex className="w-100">
                    <strong>Tickets</strong>
                    {
                      !!formik.values.tickets && !!formik.values.tickets.length
                        ? (
                          formik.values.tickets.map((t: ITicket, index: number) => (
                            <Flex row align="start" className="w-100">
                              <Card className="p-2 w-100" header={<TicketCardHeader ticket={t} />} style={{ backgroundColor: "var(--backgroundLighter)" }}>
                                <Flex row className="w-100" justify="between" align="start">
                                  <Flex>
                                    <span>{t.subject}</span>
                                    <Flex row>
                                      <TicketTypeBanner ticket={t} />
                                      {t.team && <TeamBanner team={t.team} />}
                                      <TicketAssigneeBanner ticket={t} />
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Card>
                              <Button color="error" variant="text" text="" icon="x" onClick={async () => arrayHelpers.remove(index)} />
                            </Flex>
                          ))
                        )
                        : <span>Keine Tickets ausgewählt</span>
                    }
                  </Flex>
                )
              }
            </FieldArray>
          </Flex>
        )
      }
    </ModalForm>
  )

}