import React from "react";
import Flex from "../container/Flex";
import Clickable from "../buttons/Clickable";
import Icon from "../icons/Icon";
import { IAppRoute } from "../../config/AppRoutes";
import { useNavigate } from "react-router-dom";
import "./ExpandableItem.css";
import { generateClassName } from "../../hooks/useAttributes";
import Typography from "../text/Typography";

export interface IExpandableItemProps {
  text: string,
  icon?: string,
  noMargin?: boolean,
  onClick?: () => (any | Promise<any>),
  navigateTo?: IAppRoute
}

export default function ExpandableItem({ text, icon, noMargin, onClick, navigateTo }: IExpandableItemProps) {

  const navigate = useNavigate();

  const clickHandler = async () => {
    if (onClick) return await onClick();
    if (navigateTo) return navigate(navigateTo.path);
  }

  const className = generateClassName("expandable-item w-100", {
    value: !!onClick || !!navigateTo,
    onTrue: "expandable-item-clickable"
  }, {
    value: noMargin,
    standard: "pt-2 pb-2 ps-2 pe-2"
  })

  return (
    <Clickable onClick={clickHandler} className="w-100">
      {
        loading => (
          <Flex row className={className}>
            <Flex style={{minWidth: "30px"}}>
              {

                (icon || loading) && <Icon icon={icon} loading={loading} size={20} hexColor="#B2C0D9" className="expandable-item-icon" />
              }
            </Flex>
            <Typography bold truncate={false} wrap upper className="expandable-item-text">{text}</Typography>
          </Flex>
        )
      }
    </Clickable>
  )
}