import React from "react";
import TicketChatMessageAuthor from "./TicketChatMessageAuthor";
import TicketMessageText from "./TicketMessageText";
import { ITicket } from "../../../../types/ApiTypes";
import TicketChatHtmlMessageContent from "./TicketChatHtmlMessageContent";

export default function TicketChatMessagePreview({ ticket }: { ticket: ITicket }) {
  // if (!ticket || !ticket.messages || !ticket.messages.length) return <em>Noch keine Nachrichten</em>

  // const message = ticket.messages[ticket.messages.length - 1];

  // return (
  //     <div className="d-flex flex-column gap-1">
  //         <TicketChatMessageAuthor message={message} isOwn={false} colorMap={{}} />
  //         <TicketChatHtmlMessageContent isEditForm={false} isPreview message={message.content[0].text} />
  //     </div>
  // ) 

  return null;
}
