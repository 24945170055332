import { IApiResponse, isErrorApiResponse } from "../api/Api"
import { ModalType } from "../config/ModalTypes";
import { Locale } from "../locale/Locale";
import { useNotifications } from "../state/swr/user/useNotifications";
import useModal from "./useModal";
import useModalNotifications from "./useModalNotifications";

export default function useApi() {

    const showNotification = useModalNotifications();

    return async<T = any> (apiCall: Promise<IApiResponse<T>>, showMessageOnSuccess: boolean = false, message: string = "Ihre Anfrage wurde erfolgreich verarbeitet."): Promise<IApiResponse<T> | null> => {
        const result = await apiCall;

        if (result && result.canceled) return null;

        if (!result) {
            showNotification({
                title: "Fehler",
                text: Locale.errors.generic,
                type: ModalType.Error 
            });

            return null;
        }

        if (isErrorApiResponse(result)) {
            showNotification({
                title: "Fehler",
                text: result.message || Locale.errors.generic,
                type: ModalType.Error
            });

            return null;
        }
        
        if (showMessageOnSuccess) showNotification({
            text: message,
            title: "Erfolg",
            type: ModalType.Success
        });

        return result;
    }
}