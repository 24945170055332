import { Form, FormikProps } from "formik";
import React from "react";
import { ITenantOnboardingUpdateBillingDataRequest, ITenantUpdateBillingDataRequest } from "../../api/ApiRequests";
import useCountryCodes, { IIsoCountryCode } from "../../hooks/useCountryCodes";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import FieldWithLabel from "../formik/FormikField";
import WithPermissions from "../permissions/WithPermissions";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import Flex from "../container/Flex";

export default function TenantBillingDetailsForm({formik, fromOnboarding}: {formik: FormikProps<ITenantUpdateBillingDataRequest> | FormikProps<ITenantOnboardingUpdateBillingDataRequest>, fromOnboarding?: boolean}) {
    const countryCodes = useCountryCodes();

    const saveCountry = (i?: IIsoCountryCode) => {
        formik.setFieldValue("address.country", i?.country);
        formik.setFieldValue("address.countryCode", i?.code);
    }

    const getCurrentCountryCode = (): IIsoCountryCode => ({
        code: formik.values.address?.countryCode,
        codeToUpper: formik.values.address?.countryCode?.toUpperCase(),
        country: formik.values.address?.country
    });

    const form = (

        <Form className="w-100 h-100 d-flex flex-column justify-content-center gap-3">
            <FieldWithLabel bold name="company" label="Firma" noMargin placeholder="Die Firmierung Ihrer Kanzlei inklusive Rechtsform" /> 
            <div className="d-flex flex-column align-items-start gap-3 w-100">
                <strong>Adresse</strong>
                <FieldWithLabel name="address.firstLine" noMargin label="Straße" className="w-100" placeholder="Straße oder Postfach" />
                <FieldWithLabel name="address.secondLine" noMargin label="Optional: Zweite Adresszeile" placeholder="Stockwerk, Anbau, Wohnung" className="w-100" />
                <div className="d-flex flex-row align-items-center w-100 gap-3">
                    <FieldWithLabel name="address.postalCode" noMargin label="Postleitzahl" placeholder="Postleitzahl" className="w-15" /> 
                    <FieldWithLabel name="address.city" noMargin label="Stadt" className="w-100" />
                </div>
                <div className="d-flex flex-row align-items-center w-100 gap-3">
                    <FieldWithLabel name="address.state" noMargin label="Bundesland" placeholder="Bundesland" className="w-50" /> 
                    <SearchableComboBox 
                        label="Land"
                        className="w-100"
                        value={getCurrentCountryCode()}
                        values={countryCodes}
                        itemToId={i => i.code}
                        itemToString={i => i.country}
                        renderItem={i => (
                            <Flex row>
                                <img src={`https://flagsapi.com/${i.codeToUpper}/flat/32.png`} style={{height: "20px", width: "auto"}} />
                                <Typography size={14}>{i.country} ({i.codeToUpper})</Typography>
                            </Flex>
                        )}
                        renderValue={i => (
                            <Pill className="d-flex flex-row align-items-center w-100 gap-2">
                                <img src={`https://flagsapi.com/${i.codeToUpper}/flat/32.png`} style={{height: "20px", width: "auto"}} />
                                <span>{i.country} ({i.codeToUpper})</span>
                            </Pill>
                        )}
                        onItemClick={saveCountry}
                    />
                </div>
            </div>
            <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
        </Form>
    );

    if (fromOnboarding) return form;

    return (
        <WithPermissions permissions={["billing.tenant.update"]}>
            {form}
        </WithPermissions>
    );
}