import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicketEscalationWorkflow } from "../../../types/ApiTypes";

export const useTicketEscalationWorkflow = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketEscalationWorkflow>>(ApiRoutes.Tickets.EscalationWorkflow.All);

    return {
        ticketEscalationWorkflow: data,
        loadingTicketEscalationWorkflow: isLoading,
        reloadTicketEscalationWorkflow: mutate
    }
}