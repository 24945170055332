import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ISalutation } from "../../../types/salutation.schema";

export const useSalutations = (global?: boolean) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ISalutation>>(
        global
        ? ApiRoutes.Salutations.Global.All
        : ApiRoutes.Salutations.All
    );

    return {
        salutations: data,
        loadingSalutations: isLoading,
        reloadSalutations: mutate
    }
}