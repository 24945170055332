import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IAddressee } from "../../../types/ApiTypes";
import { useAddressees } from "../../swr/addressees/useAddressees";

export const useAddressee = (id?: string) => {

  const { addresseesById } = useAddressees({ enabled: false });

  const { data, isLoading, mutate } = useTiggiQuery<IAddressee>(
    ApiRoutes.Addressees.ById.getValue({ param: RouteParam.Addressee, value: id ?? "" }),
    { enabled: !!id }
  );

  return {
    addressee: data ?? addresseesById?.[id ?? ""],
    loadingAddressee: isLoading,
    reloadAddressee: mutate
  }
} 