import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRouteParams, AppRoutes, getLink } from "../../config/AppRoutes";
import { IAddressee } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import RoleBanner from "../user/RoleBanner";
import AddresseeAddress from "./AddresseeAddress";
import AddresseeDuplicateTypePill from "./AddresseeDuplicateTypePill";
import AddresseeBanner from "./AddresseeBanner";

export default function AddresseeListItem({ addressee }: { addressee: IAddressee }) {

  const navigate = useNavigate();
  const link = getLink(AppRouteParams.Addressee, AppRoutes.Addressee, addressee._id);

  return (
    <TableRow onClick={() => navigate(link)}>
      <TableCell content={addressee.actualName} />
      <TableCell>
        <AddresseeAddress addressee={addressee} />
      </TableCell>
      <TableCell>
        <AddresseeBanner addressee={addressee} />
      </TableCell>
      <TableCell>
        <Flex gap="1">
          {
            addressee.isMissingMail
              ? <Pill icon="question" withDivider color="#FEDD00">Keine Email</Pill>
              : addressee.originalMail && (
                <Pill
                  color="#FFF5FF"
                  className="gap-2"
                  icon="at"
                  iconColor="error"
                  text={addressee.originalMail}
                />
              )
          }
          <AddresseeDuplicateTypePill addressee={addressee} />
        </Flex>
      </TableCell>
      <TableCell>
        {
          addressee.user
            ? <RoleBanner user={addressee.user} displayNameAsValue showMail />
            : <span>-</span>
        }
      </TableCell>
    </TableRow>
  )
}