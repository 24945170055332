import { Gender } from "../types/salutation.schema";
import useGenderUtil from "./useGenderUtil";

export type SalutationTemplateKey = "templateForAllGenders" | Gender;

export default function useSalutationUtil() {
    
    const {
        getLabelForGender
    } = useGenderUtil();

    const getUsedTemplateKeys = (useSameTemplateForAllGenders: boolean): Array<SalutationTemplateKey> => {
        if (useSameTemplateForAllGenders) return ["templateForAllGenders", Gender.Unknown];
        return Object.values(Gender);
    }

    const getTemplateKeyLabel = (k: SalutationTemplateKey): string => {
        switch (k) {
            case "templateForAllGenders": return "Alle";
            default: return getLabelForGender(k);
        }
    }

    return {
        getUsedTemplateKeys,
        getTemplateKeyLabel
    }
}