import { ErrorMessage, Field } from "formik";
import { generateClassName } from "../../hooks/useAttributes";
import React from "react";
import IElementProps from "../../types/element.types";
import FieldWithLabel from "./FormikField";
import Icon from "../icons/Icon";
import "./PasswordField.css";
import { AppColor } from "../../app/AppStyles";

interface IFieldWithLabelProps extends IElementProps {
    label?: string,
    placeholder?: string,
    name?: string,    
    labelColor?: AppColor,
    labelColorBasedOnBackground?: AppColor,
    rounded?: boolean,
    readOnly?: boolean,
    bold?: boolean,
    type?: string
}

export default function PasswordField({label,className, rounded, labelColor, labelColorBasedOnBackground, readOnly, placeholder, name, bold}: IFieldWithLabelProps) {
    const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

    return (
        <FieldWithLabel 
            noHover
            label={label || "Passwort"} 
            rounded={rounded} 
            className={className}
            readOnly={readOnly} 
            type={passwordVisible ? "text" : "password"} 
            bold 
            placeholder={placeholder || "Passwort"} 
            name={name || "password"}
            labelColor={labelColor}
            labelColorBasedOnBackground={labelColorBasedOnBackground}
        >
            <Icon 
                icon={passwordVisible ? "eye-slash" : "eye-fill"} 
                wrapperClass="toggle-password-btn position-absolute"
                color="muted"
                onClick={() => setPasswordVisible(!passwordVisible)} />
        </FieldWithLabel>
    )
}