import React from "react";
import { StringMappingType } from "typescript";
import { MirrorTicketStatusToMailBehavior } from "../../../types/ApiTypes";
import Typography from "../../text/Typography";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";

export interface ITicketMailBehaviorSelectProps {
  label: string,
  onChange: (value: MirrorTicketStatusToMailBehavior) => void,
  value: MirrorTicketStatusToMailBehavior
}

export default function TicketMailBehaviorSelect(props: ITicketMailBehaviorSelectProps) {

  const getLabel = (v: MirrorTicketStatusToMailBehavior) => {
    switch (v) {
      case MirrorTicketStatusToMailBehavior.AfterClose: return "Nach Schließen";
      case MirrorTicketStatusToMailBehavior.Immediately: return "Bei Mailerhalt";
      case MirrorTicketStatusToMailBehavior.OnFirstMessage: return "Bei erster Nachricht eines Mitarbeiters";
      case MirrorTicketStatusToMailBehavior.Never: return "Nie";
    }
  }

  return (
    <Select
      label={props.label}
      getLabel={getLabel}
      value={props.value}
      onChange={(value) => props.onChange(value as MirrorTicketStatusToMailBehavior)}
      values={Object.values(MirrorTicketStatusToMailBehavior)}
    />
  )
}