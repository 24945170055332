import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useOfficeMailRepliedToStatus = (accountId: string, mailId: string) => {
  const { data, isLoading, mutate } = useTiggiQuery<{ hasBeenRepliedTo: boolean }>(ApiRoutes.OfficeAccounts.CheckMailRepliedToStatus.getValue({
    param: RouteParam.OfficeAccountId,
    value: accountId,
  }, {
    param: RouteParam.MailId,
    value: mailId
  }), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true
  });

  return {
    officeMailRepliedToStatus: data,
    loadingOfficeMailRepliedToStatus: isLoading,
    reloadOfficeMailRepliedToStatus: mutate
  }
}