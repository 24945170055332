import React from "react";
import { IAddressee } from "../../types/ApiTypes";
import Typography, { ITypographyProps } from "../text/Typography";
import Flex from "../container/Flex";

export interface IAddresseeAddressProps extends ITypographyProps {
    addressee: IAddressee
}
export default function AddresseeAddress(props: IAddresseeAddressProps) {

    const {
        addressee     
    } = props;

    if (!addressee?.addresses?.length) return null;

    const a = addressee.addresses[0];

    if (!a) return null;

    return (
        <Flex gap="0">
            {a.post_office_box && <Typography {...props}>{a.post_office_box}</Typography>}
            <Typography {...props}>{a.street}</Typography>
            <Typography {...props}>{a.postal_code} {a.city}</Typography>
        </Flex>
    )
}