import React from "react";
import { useAllUserStatistics } from "../../../state/swr/statistics/useAllUserStatistics";
import { useTenantUserStatistics } from "../../../state/swr/statistics/useTenantUserStatistics";
import Flex from "../../container/Flex";
import WithPermissions from "../../permissions/WithPermissions";
import { TicketProductivityTab } from "../ProductivityOverview";
import TicketStatsCardGrid from "../TicketStatsCardGrid";
import useLabels from "../../../hooks/useLabels";

export default function TenantProductivityOverview() {

  const { loadingTenantUserStatistics, reloadTenantUserStatistics, tenantUserStatistics } = useTenantUserStatistics();
  const { allUserStatistics, loadingAllUserStatistics } = useAllUserStatistics();

  return (
    <WithPermissions permissions={["statistics.tenant.read"]}>
      <TicketStatsCardGrid
        currentTab={TicketProductivityTab.Tenant}
        allStatistics={allUserStatistics}
        resetTarget="tenant"
        title="Gesamtübersicht"
        cardColor="backgroundDarker"
        isLoading={loadingTenantUserStatistics}
        statistics={tenantUserStatistics}
      />
    </WithPermissions>
  )
}