import React from "react";
import Flex from "../../container/Flex";
import { ITicket } from "../../../types/ApiTypes";
import { useTicketMails } from "../../../state/swr/tickets/useTicketMails";
import LoadingSpinner from "../../loader/LoadingSpinner";
import MailLogCard from "../../log/mail/MailLogCard";
import SidebarHeader from "../../sidebar/SidebarHeader";

export default function TicketMails({ ticket }: { ticket: ITicket }) {

  const [matchingItems, setMatchingItems] = React.useState<Array<any>>([]);
  const [filter, setFilter] = React.useState<string>("");

  const { loadingTicketMails, reloadTicketMails, ticketMails } = useTicketMails(ticket._id);


  React.useEffect(() => {
    if (loadingTicketMails) return;
    if (!ticketMails || !ticketMails.length) {
      setMatchingItems([]);
      return;
    }

    if (!filter || !filter.length) {
      setMatchingItems(ticketMails);
      return;
    }

    const upperFilter = filter.toUpperCase();

    const matchingItems = ticketMails.filter(m => {
      if (m.subject && m.subject.toUpperCase().includes(upperFilter)) return true;
    });

    setMatchingItems(matchingItems);
  }, [loadingTicketMails, ticketMails, filter]);

  if (loadingTicketMails) return <LoadingSpinner />

  return (
    <Flex>
      <SidebarHeader text="Mails für Ticket" />
      {
        ticketMails && !!ticketMails.length && <input className="form-control" placeholder="Dateien oder Nachrichten suchen..." onChange={(e) => setFilter(e.target.value)}></input>
      }
      {
        matchingItems && matchingItems.length
          ? matchingItems.map(d => <MailLogCard key={d._id} item={d} />)
          : <span>Keine {ticketMails && !!ticketMails.length ? "Ergebnisse" : "Mails"}</span>
      }
    </Flex>
  )
}