
const LetterColors: {[key: string]: string} = {
    "a": "#F25CA2",
    "b": "#5AA9E6",
    "c": "#A8D54C",
    "d": "#FFB24D",
    "e": "#8E65E3",
    "f": "#F56C47",
    "g": "#59C4A3",
    "h": "#EA85D0",
    "i": "#52B36F",
    "j": "#FFA447",
    "k": "#6EB3E1",
    "l": "#ED6F7A",
    "m": "#75C86B",
    "n": "#E35F93",
    "o": "#7FAF5D",
    "p": "#D34FC4",
    "q": "#6E87D1",
    "r": "#F28457",
    "s": "#53BDAE",
    "t": "#F99273",
    "u": "#5B9CD1",
    "v": "#C37D5E",
    "w": "#58CF80",
    "x": "#F591AC",
    "y": "#54B773",
    "z": "#EC5A81"
}

export const useLetterColors = () => {
    const getFirstLetterColor = (word?: string) => {
        if (!word?.length) return "#FFF";
        return getLetterColor(word[0]);
    }
    
    const getLetterColor = (letter: string) => {
        return LetterColors[letter.toLowerCase()] || "#FFF";
    }

    return {
        getFirstLetterColor,
        getLetterColor
    }
}