import { Message } from "@microsoft/microsoft-graph-types";
import useSWR from "swr";
import { IOfficeGraphResponse } from "../../../api/ApiRequests";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IOfficeMailFilter } from "../../../hooks/useFilteredOfficeMails";
import useFilterQuery from "../../../hooks/useFilterQuery";

export const useOfficeAcountFolderMails = (accountId: string, folderId: string, filter?: IOfficeMailFilter) => {

    const { getUrlWithFilter } = useFilterQuery();

    const url = getUrlWithFilter(ApiRoutes.OfficeAccounts.GetMailsForFolder.getValue({ 
        param: RouteParam.OfficeAccountId, 
        value: accountId 
    }, { 
        param: RouteParam.FolderId, 
        value: folderId
    }), filter);

    const { data, isLoading, mutate } = useSWR<IOfficeGraphResponse<Message>>(url);

    return {
        folderMails: data,
        loadingFolderMails: isLoading,
        reloadFolderMails: mutate
    }
}