import { FileAttachment } from "@microsoft/microsoft-graph-types";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export const useMailAttachmentPreview = (account: IOfficeAccount, mailId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<FileAttachment>>(ApiRoutes.OfficeAccounts.GetAttachmentPreview.getValue({
        param: RouteParam.OfficeAccountId,
        value: account._id
    }, {
        param: RouteParam.MailId,
        value: mailId
    }));

    return {
        mailAttachmentPreview: data,
        loadingMailAttachmentPreview: isLoading,
        reloadMailAttachmentPreview: mutate
    }
}