import React from "react";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import { useTicketAssignments } from "../../../state/swr/tickets/assignments/useTicketAssignments";
import useApi from "../../../hooks/useApi";
import { ticketAssignmentsUpdate } from "../../../api/Api";
import Button from "../../buttons/Button";

export interface IToggleAssignmentStatusButtonProps {
    assignment: ITicketAssignment,
    ticket: ITicket
}

export default function ToggleAssignmentStatusButton({assignment, ticket}: IToggleAssignmentStatusButtonProps) {
    
    const [active, setActive] = React.useState<boolean>(!!assignment.isActive);

    const {
        reloadTicketAssignments
    } = useTicketAssignments(ticket)

    React.useEffect(() => setActive(!!assignment.isActive), [assignment]);

    const callApi = useApi();

    const toggleAssignmentStatus = async () => {
        const res = await callApi(ticketAssignmentsUpdate({
            id: assignment._id,
            isActive: !assignment.isActive,
            ticketId: ticket._id
        }));

        if (!res || !res.success) return;

        setActive(!active);
        await reloadTicketAssignments();
    }

    if (assignment.isMainAssignee || assignment.isMainClientContact) return null;
    
    return (
        <Button
            icon={active ? "x" : "check"}
            text={active ? "Deaktivieren" : "Aktivieren"}
            color={active ? "error" : "success"}
            onClick={toggleAssignmentStatus}
        />
    )
}