import { Form, Formik, FormikBag } from "formik";
import React, { useState } from "react";
import Logo from "../../components/logo/Logo";
import Page from "../../layout/Page";
import { usersRequestNewVerificationCode, usersVerifyLogIn } from "../../api/Api";
import Button from "../../components/buttons/Button";
import "./VerifyLogIn.css";
import { ModalType } from "../../config/ModalTypes";
import useModal from "../../hooks/useModal";
import { Locale } from "../../locale/Locale";
import useApi from "../../hooks/useApi";
import { ISessionVerificationRequest } from "../../api/ApiRequests";
import CheckBox from "../../components/formik/CheckBox";
import OtpInput from "../../components/formik/OtpInput";
import ResetSelectedVerificationMethodButton from "../../components/session/ResetSelectedVerificationMethodButton";
import Flex from "../../components/container/Flex";
import { useSession } from "../../state/swr/session/useSession";
import Typography from "../../components/text/Typography";

export default function VerifyLogIn() {
    const [canRequestNewCode, setCanRequestNewCode] = React.useState<boolean>(true);
    const [canRequestNewCodeTimer, setCanRequestNewCodeTimer] = React.useState<any>();
    const [fetchingNewAccessCode, setFetchingNewAccessCode] = useState(false);

    const showModal = useModal();
    const callApi = useApi();

    const { reloadSession } = useSession();

    const fetchNewAccessCode = async (abortController?: AbortController) => {
        clearTimeout(canRequestNewCodeTimer);
        setFetchingNewAccessCode(true);
        setCanRequestNewCode(false);
        await callApi(usersRequestNewVerificationCode(abortController));
        const newCodeTimeout = setTimeout(() => setCanRequestNewCode(true), 2000);
        setCanRequestNewCodeTimer(newCodeTimeout);
        setFetchingNewAccessCode(false);
    }

    return ( 
        <Page fullscreen centered colored>
            <Logo />
            <Formik 
                initialValues={{
                    stayLoggedIn: false,
                    otp: ""
                } as ISessionVerificationRequest}
                onSubmit={async (values, actions) => {
                    if (!values) return;

                    if (!values.otp) {
                        showModal({
                            type: ModalType.Error,
                            text: Locale.errors.logInVerification.noOtp
                        });
                        return;
                    }

                    const res = await usersVerifyLogIn(values);

                    if (!res || !res.success) {
                        showModal({
                            type: ModalType.Error,
                            text: Locale.errors.logInVerification.otpVerificationFailed
                        });
                        return;
                    }

                    await reloadSession();
                }}
                >
                {
                    formik => (
                        <Form className="d-flex flex-column gap-2 align-items-center" style={{width: "fit-content", maxWidth: "500px"}}>
                            <Flex gap="0">
                                <Typography basedOnThisBackground="primary" size={24} bold >Willkommen zurück!</Typography>
                                <Typography basedOnThisBackground="primary">Geben Sie den erhaltenen Code ein.</Typography>
                            </Flex>
                            <Flex row justify="between" fullWidth>
                                <OtpInput 
                                    saveOtp={(otp) => {
                                        formik.setFieldValue("otp", otp);
                                        formik.submitForm();
                                    }} 
                                    readOnly={formik.isSubmitting} 
                                    text="Bitte geben Sie den Code ein, welchen wir Ihnen zugesendet haben." 
                                />
                            </Flex>
                            <Flex row justify="between" fullWidth>
                                <CheckBox name="stayLoggedIn" textColor="bright" label="Für 10 Tage nicht fragen" />
                                <Button type="submit" icon="door-open" preventFloatEndOnSubmit loading={formik.isSubmitting} color="bright" text="Einloggen" loadingText="Code wird überprüft" />
                            </Flex>
                            <Flex row justify="between" fullWidth>
                                <ResetSelectedVerificationMethodButton />
                                <Button 
                                    color="secondary"
                                    variant="subtle"
                                    disabled={!canRequestNewCode} 
                                    disabledText="Bitte warten..." 
                                    icon="arrow-clockwise"
                                    onClick={async () => await fetchNewAccessCode()} 
                                    loading={fetchingNewAccessCode} 
                                    loadingText="Code wird gesendet" 
                                    text="Neuer Code" 
                                />
                            </Flex>
                        </Form>
                    )
                }
            </Formik>
        </Page>
    )
}