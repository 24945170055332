import React, { useEffect, useState } from "react";
import { generateClassName } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import Button, { IButtonProps } from "../buttons/Button";
import Flex from "../container/Flex";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import Typography from "../text/Typography";
import ComboBoxItem from "./ComboBoxItem";
import Expandable from "./Expandable";
import "./SearchableComboBox.css";

interface ISearchableComboBoxProps<T> extends IElementProps {
  values: T[] | { [key: string]: T },
  expander?: React.ReactElement,
  expanderButtonProps?: IButtonProps,
  bold?: boolean,
  label?: string,
  value?: T | string,
  resetValueAfterClick?: boolean,
  useDefaultValue?: boolean,
  loading?: boolean,
  resetValueOnClick?: boolean,
  dontCloseAfterClick?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  renderItemLikeValue?: boolean,
  noValuesPlaceholder?: string,
  shouldItemRender?: (item: T) => boolean,
  renderValue?: (item: T) => React.ReactElement,
  renderItem?: (item: T) => React.ReactElement,
  filterFunction?: (item: T, filter: string) => boolean,
  clearValue?: () => void,
  itemToId: (value: T) => string,
  itemToString: (value: T) => string,
  onItemClick: (value?: T) => void
}

export default function SearchableComboBox<T>({
  className,
  useDefaultValue = false,
  renderItemLikeValue = false,
  values,
  label,
  bold,
  expanderButtonProps,
  readOnly,
  value,
  placeholder = "",
  filterFunction,
  disabled,
  shouldItemRender,
  loading,
  itemToId,
  clearValue,
  renderItem,
  renderValue,
  noValuesPlaceholder,
  expander,
  resetValueAfterClick,
  resetValueOnClick = true,
  dontCloseAfterClick,
  itemToString,
  onItemClick
}: ISearchableComboBoxProps<T>) {

  const [filterHasFocus, setFilterHasFocus] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [valuesById, setValuesById] = useState<{ [key: string]: T }>({});
  const [availableValues, setAvailableValues] = useState<Array<T>>([]);
  const [filteredValues, setFilteredValues] = useState<Array<T>>([]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const convertArrayToValues = (values: T[] | { [key: string]: T }) => {
    if (Array.isArray(values)) return Object.fromEntries(values.map(v => [itemToId(v), v]));
    else return values;
  }

  const convertValuesToArray = (values: T[] | { [key: string]: T }) => {
    if (Array.isArray(values)) return values;
    else return Object.values(values);
  }

  useEffect(() => {
    const v = convertArrayToValues(values);
    const a = convertValuesToArray(values);
    setValuesById(v);
    setAvailableValues(a);
    if (!useDefaultValue) return;
    if (value) return;
    onItemClick(a[0]);
  }, [values]);

  const filterToUpper = (filter ?? "").toUpperCase().trim();

  const checkItemFilterMatch = (v: T) => {
    if (filterFunction) return filterFunction(v, filterToUpper);
    const itemString = itemToString(v);
    if (!itemString) return false;
    const itemStringUpper = itemString.toUpperCase().trim();
    return itemStringUpper.includes(filterToUpper);
  };

  useEffect(() => {

    if (!availableValues || !availableValues.length) return;

    if (!filter) {
      setFilteredValues(availableValues);
      return;
    }

    const filteredItems = availableValues.filter(checkItemFilterMatch);
    setFilteredValues(filteredItems);

  }, [filter, availableValues]);

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFilter(e.target.value);
  }

  const onFilterFocus = () => {
    setFilter("");
    setFilterHasFocus(true);
  }

  const onFilterBlur = () => {
    setFilterHasFocus(false);
  }


  const isEmpty = !values || !values.length;
  const noResults = !filteredValues || !filteredValues.length;

  const realValue = value ? (typeof value === "string" ? valuesById?.[value] : value) : undefined;

  const showRenderedValue = !!realValue && itemToId(realValue) && !!renderValue;

  const displayNameClass = "searchable-combo-box-value-container form-control";

  const inputClass = generateClassName(displayNameClass, "searchable-combo-box-input", {
    base: "searchable-combo-box-input-",
    value: filteredValues && !!filteredValues.length,
    onTrue: "expanded",
    standard: "collapsed"
  }, {
    value: !isEmpty && noResults,
    onTrue: "searchable-combo-box-no-results"
  }, {
    value: showRenderedValue,
    base: "searchable-combo-box-input-",
    onTrue: "hidden",
    standard: "visible"
  });

  const itemsContainerClass = generateClassName("searchable-combo-box-item-container w-100 h-100", {
    value: filteredValues && !!filteredValues.length,
    onTrue: "searchable-combo-box-item-container-with-values"
  });

  const inputContainerClass = generateClassName("d-flex flex-row align-items-center p-2 searchable-combo-box-input-container", {
    base: "searchable-combo-box-input-container-input-",
    value: showRenderedValue,
    onTrue: "hidden",
    standard: "visible"
  })

  const comboBoxContainerClass = generateClassName("position-relative gap-1 d-flex flex-column", className);

  const getRenderedValue = (t: T) => {
    if (!renderValue) {
      if (!renderItem) return <Typography className="ps-1 pe-1">{itemToString(t)}</Typography>;
      return renderItem(t);
    }

    return renderValue(t);
  }

  return (
    <div className={comboBoxContainerClass}>
      {
        label && <Typography noLinePadding bold={bold} color="primary">{label}</Typography>
      }
      <Expandable
        keepOpen={filterHasFocus}
        disabled={disabled || isEmpty || readOnly || loading}
        expander={expander ?? (
          <Button
            icon={value ? "check" : "search"}
            iconPosition="start"
            iconSize={14}
            variant="subtle"
            color="primary"
            loading={loading}
            {...expanderButtonProps}
          >
            {realValue && itemToString(realValue) || (placeholder || "Auswählen...")}
          </Button>
        )}
      >
        {
          close => (
            <Flex gap={3} className="w-100 h-100">
              <div className={inputContainerClass}>
                <input
                  ref={inputRef}
                  disabled={disabled || isEmpty || readOnly}
                  readOnly={readOnly}
                  className={inputClass}
                  onBlur={onFilterBlur}
                  onFocus={onFilterFocus}
                  onChange={onFilterChange}
                  value={filter}
                  placeholder={isEmpty ? (noValuesPlaceholder || "Keine Elemente") : (placeholder || "Suchen...")}
                />
                {value && (
                  <Button
                    onClick={async () => {
                      if (clearValue) clearValue();
                      else onItemClick(undefined);
                      setFilter("");
                    }}
                    icon="x"
                    color="error"
                    variant="text"
                  />
                )}
              </div>
              <div className={itemsContainerClass}>
                <Flex className="searchable-combo-box-items w-100">
                  <InfiniteScroll>
                    {
                      filteredValues && !!filteredValues.length
                        ? filteredValues.map((v: T) => {
                          if (shouldItemRender && !shouldItemRender(v)) return null;
                          const value = getRenderedValue(v);
                          if (!value) return null;
                          return (
                            <ComboBoxItem
                              key={itemToId(v)}
                              content={value}
                              item={v}
                              onClick={() => {
                                onItemClick(v);
                                setFilter("");
                                close();
                              }}
                            />
                          )
                        })
                        : <div className="ps-3">Keine Ergebnisse</div>
                    }
                  </InfiniteScroll>
                </Flex>
              </div>
            </Flex>
          )
        }
      </Expandable>
    </div>
  )
}