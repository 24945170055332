import React from "react";

export interface ITableCheckboxProps {
  as?: "th" | "td",
  isSelected: boolean,
  onChange: (selected: boolean) => void
}

export default function TableCheckbox(props: ITableCheckboxProps) {

  const {
    isSelected,
    onChange,
    as = "td"
  } = props;

  const checkbox = (
    <input
      type="checkbox"
      onChange={e => onChange(e.target.checked)}
      style={{ height: "16px", width: "16px" }}
      checked={isSelected}
      className="form-check-input m-0 p-0"
    />
  )

  return React.createElement(as, {}, checkbox);
}