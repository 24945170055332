import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IDomain } from "../../../types/ApiTypes";

export const useDomains = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IDomain>>(ApiRoutes.Domains.All);

    return {
        domains: data,
        loadingDomains: isLoading,
        reloadDomains: mutate
    }
}