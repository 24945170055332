import React from "react";
import { blacklistCreateForOfficeAccount, blacklistCreateForTenant, blacklistDeleteForOfficeAccount, officeAccountsUpdate } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useOfficeAccounts } from "../../../state/swr/office/useOfficeAccounts";
import { useTeams } from "../../../state/swr/teams/useTeams";
import { IUser } from "../../../types/ApiTypes";
import { IOfficeAccount, IOfficeAccountDocument, NewTicketMailBehaviour } from "../../../types/officeAccount.types";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";
import CheckBox from "../../formik/CheckBox";
import LoadingSpinner from "../../loader/LoadingSpinner";
import ModalForm from "../../modal/ModalForm";
import WithPermissions from "../../permissions/WithPermissions";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserPicker from "../../user/UserPicker";
import UserSelect from "../../user/UserSelect";
import Icon from "../../icons/Icon";
import { ITeam } from "../../../types/team.schema";
import ModalDialog from "../../modal/ModalDialog";
import { Form, Formik } from "formik";
import { useOfficeAccountBlacklist } from "../../../state/api/blacklist/useOfficeAccountBlacklist";
import BlacklistOverview from "../../blacklist/BlacklistOverview";

export interface IUpdateOfficeAccountFormProps {
  account: IOfficeAccount
}

export enum UpdateOfficeAccountTab {
  Settings = "settings",
  Blacklist = "blacklist",
}

export enum UpdateOfficeAccountAssignmentTab {
  Personal = "personal",
  Teams = "teams",
}

export default function UpdateOfficeAccountForm({ account }: IUpdateOfficeAccountFormProps) {

  const [assignmentTab, setAssignmentTab] = React.useState<UpdateOfficeAccountAssignmentTab>(UpdateOfficeAccountAssignmentTab.Personal);
  const [tab, setTab] = React.useState<UpdateOfficeAccountTab>(UpdateOfficeAccountTab.Settings);

  const {
    getAccountPermissions
  } = useInboxPermissions();

  const { loadingTeams, teams } = useTeams();
  const { reloadOfficeAccounts } = useOfficeAccounts();

  const {
    loadingOfficeAccountBlacklist,
    reloadOfficeAccountBlacklist,
    officeAccountBlacklistByType
  } = useOfficeAccountBlacklist(account._id, { enabled: tab === UpdateOfficeAccountTab.Blacklist });

  const callApi = useApi();
  const permissions = getAccountPermissions("update", account);

  return (
    <WithPermissions permissions={permissions}>
      <ModalDialog
        title="Konto ändern"
        button={
          <Button icon="pen">Ändern</Button>
        }
      >
        <Flex fullWidth gap="3">
          <TabSwitcher
            size="small"
            tabQueryParamKey="view"
            tabs={[
              { data: UpdateOfficeAccountTab.Settings, label: "Einstellungen" },
              { data: UpdateOfficeAccountTab.Blacklist, label: "Blacklist" }
            ]}
            saveActiveTab={t => setTab(t as UpdateOfficeAccountTab)}

          />

          {
            tab === UpdateOfficeAccountTab.Settings
              ? (

                <Formik
                  enableReinitialize
                  initialValues={{
                    assignments: {
                      team: account.assignments?.team,
                      personalUser: account.assignments?.personalUser
                    },
                    isAvailableForAllUsers: !!account.isAvailableForAllUsers,
                    isMainTenantAccount: !!account.isMainTenantAccount,
                    newTicketMailBehaviour: account.newTicketMailBehaviour ?? NewTicketMailBehaviour.CreateFromKnownMailAddresses,
                    availableForUsers: [],
                    autoAssignTicketMailsToEmployees: !!account.autoAssignTicketMailsToEmployees
                  } as IOfficeAccountDocument}
                  onSubmit={async (values) => {
                    const res = await callApi(officeAccountsUpdate(values, account._id));

                    if (!res) return false;

                    await reloadOfficeAccounts();
                    return true;
                  }}
                >
                  {
                    formik => {
                      const setAssignments = (user?: IUser, team?: ITeam) => {
                        formik.setFieldValue("assignments.team", team);
                        formik.setFieldValue("assignments.personalUser", user);
                      }

                      const toggleAssignmentsBasedOnMainAccount = () => {
                        if (formik.values.isMainTenantAccount) return;
                        setAssignments();
                      }

                      const getContent = () => {
                        switch (assignmentTab) {
                          case UpdateOfficeAccountAssignmentTab.Personal:
                            return (
                              <UserSelect
                                className="w-100"
                                value={formik.values.assignments.personalUser}
                                onChange={u => setAssignments(u)}
                                displayed="employees"
                              />
                            );
                          case UpdateOfficeAccountAssignmentTab.Teams:

                            if (loadingTeams) return <LoadingSpinner />
                            if (!teams) return <div>Keine Teams gefunden</div>

                            return (
                              <SearchableComboBox
                                className="w-100"
                                values={teams}
                                value={formik.values.assignments.team}
                                itemToId={t => t._id}
                                itemToString={t => t.name}
                                onItemClick={t => setAssignments(undefined, t)}
                                renderItem={t => <TeamBanner team={t} />}
                              />
                            );
                        }
                      }

                      return (
                        <Form className="w-100 h-100">
                          <Flex gap={3} fullWidth className={(!formik.values.isMainTenantAccount ? "mb-5 pb-5" : "")}>
                            <Select
                              onChange={v => formik.setFieldValue("newTicketMailBehaviour", v)}
                              value={formik.values.newTicketMailBehaviour}
                              label="Verhalten bei Mailerhalt"
                              bold
                              values={[
                                {
                                  label: "Erstellen von Tickets aus bekannten Mailadressen",
                                  data: NewTicketMailBehaviour.CreateFromKnownMailAddresses
                                },
                                {
                                  label: "Erstellen von Tickets aus allen Mails",
                                  data: NewTicketMailBehaviour.CreateFromAllMailAddresses
                                },
                                {
                                  label: "Keine Tickets erstellen",
                                  data: NewTicketMailBehaviour.DoNotCreateTicket
                                }
                              ]}
                            />
                            <Flex gap="2">
                              <CheckBox
                                name="autoAssignTicketMailsToEmployees"
                                label="Tickets automatisch an verantwortlichen Mitarbeiter zuweisen"
                              />
                              {
                                !formik.values.autoAssignTicketMailsToEmployees && (
                                  <Flex row gap="2">
                                    <Icon icon="exclamation-triangle" color="error" />
                                    <Typography size="12">Jedes Ticket muss bei Eingang einmal manuell zugewiesen werden.</Typography>
                                  </Flex>
                                )
                              }
                            </Flex>
                            <Flex>
                              <Typography bold color="primary">Kanzleikonto / Hauptkonto</Typography>
                              <CheckBox name="isMainTenantAccount" onChange={toggleAssignmentsBasedOnMainAccount} label="Zum Versenden von Kanzlei-Mails und Systemmails für Ihre Kanzlei verwenden" />
                            </Flex>
                            {
                              !formik.values.isMainTenantAccount && (
                                <Flex className="w-100" >
                                  <Typography bold color="primary">Zuweisungen</Typography>
                                  <Card
                                    header={
                                      <TabSwitcher
                                        saveActiveTab={t => setAssignmentTab(t as any)}
                                        size="tiny"
                                        tabs={[
                                          {
                                            data: UpdateOfficeAccountAssignmentTab.Personal,
                                            label: "Persönlich",
                                            first: !!formik.values.assignments.personalUser ? true : undefined
                                          },
                                          {
                                            data: UpdateOfficeAccountAssignmentTab.Teams,
                                            label: "Teams",
                                            first: !!formik.values.assignments.team ? true : undefined
                                          }
                                        ]}
                                        tabQueryParamKey="assign"
                                      />
                                    }
                                  >
                                    {getContent()}
                                  </Card>
                                </Flex>
                              )
                            }
                            {
                              (formik.values.isMainTenantAccount || formik.values.assignments?.team) && (
                                <Flex fullWidth>
                                  <Typography bold color="primary">Zugriff</Typography>
                                  <CheckBox
                                    name="isAvailableForAllUsers"
                                    label="Für alle Benutzer mit allgemeinem Postfachzugriff verfügbar"
                                  />
                                  {
                                    !formik.values.isAvailableForAllUsers && (
                                      <UserPicker
                                        description="Die ausgewählten Nutzer erhalten Zugriff auf das Postfach, sofern die Rolle der Nutzer dies erlaubt."
                                        bold
                                        label="Nutzer mit Zugriff"
                                        displayed="employees"
                                        values={formik.values.availableForUsers ?? []}
                                        saveValues={u => formik.setFieldValue("availableForUsers", u)}
                                      />
                                    )
                                  }
                                </Flex>
                              )
                            }
                            <Button type="submit" disabled={formik.isSubmitting} icon="save" text="Speichern" loading={formik.isSubmitting} />
                          </Flex>
                        </Form>
                      )
                    }
                  }
                </Formik>
              )
              : (
                <Flex fullWidth>
                  <Typography bold size="16" color="primary">Blacklist</Typography>
                  <BlacklistOverview
                    loading={loadingOfficeAccountBlacklist}
                    reloadEntries={reloadOfficeAccountBlacklist}
                    createBlacklistEntry={async (entry) => await blacklistCreateForOfficeAccount(account._id, entry)}
                    deleteBlacklistEntry={async (id) => await blacklistDeleteForOfficeAccount(account._id, id)}
                    entries={officeAccountBlacklistByType}
                  />
                </Flex>
              )
          }
        </Flex>
      </ModalDialog>
    </WithPermissions>
  )
}