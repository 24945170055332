import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from "./app/App";
import ErrorBoundary from "./app/ErrorBoundary";
import QueryProvider from "./app/QueryProvider";
import ModalBanner from "./components/modal/ModalBanner";
import ModalNotifications from "./components/modal/ModalNotifications";
import TenantThemeProvider from "./components/tenant/TenantThemeProvider";
import ScrollToTopOnRouteChange from "./components/util/UpdateStatesOnRouteChange";
import './index.css';
import store from "./state/store";
import "./style/root.css";

const element = document.getElementById("root");

if (!!element) {
  const root = ReactDOM.createRoot(element);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <QueryProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <ScrollToTopOnRouteChange>
                <TenantThemeProvider />
                <App />
                <ModalBanner />
                <ModalNotifications />
              </ScrollToTopOnRouteChange>
            </ErrorBoundary>
          </BrowserRouter>
        </QueryProvider>
      </Provider>
    </React.StrictMode>
  );
}

