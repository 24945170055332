import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { IAddressee } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";

export const useAddressees = (opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IAddressee>>(ApiRoutes.Addressees.All, opts);

  return {
    addressees: data?.items ?? [],
    addresseesById: data?.byId ?? {},
    addresseesByDatevId: data?.id ?? {},
    loadingAddressees: isLoading,
    reloadAddressees: mutate
  }
} 