import Stripe from "stripe";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const usePaymentMethods = (tenantId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<Stripe.PaymentMethod>>(ApiRoutes.Tenant.GetPaymentMethods.getValue({
        param: RouteParam.TenantId,
        value: tenantId
    }));

    return {
        paymentMethods: data,
        loadingPaymentMethods: isLoading,
        reloadPaymentMethods: mutate
    }
}