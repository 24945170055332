import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export const useTicketMessage = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketMessage>(ApiRoutes.TicketMessages.ById.getValue({param: RouteParam.TicketMessageId, value: id }));

    return {
        message: data,
        loadingMessage: isLoading,
        reloadMessage: mutate
    }
}