import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IRole } from "../../../types/ApiTypes";

export const useRoles = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IRole>>(ApiRoutes.Roles.All);

    return {
        roles: data,
        loadingRoles: isLoading,
        reloadRoles: mutate
    }
}