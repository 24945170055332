import React from "react";
import Pill from "../pills/Pill";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import { MailTemplate } from "../../types/globalMailTemplates.schema";

export interface IMailTemplateTypeBannerProps {
    template: MailTemplate,
    clickable?: boolean,
    disabled?: boolean
}

export default function MailTemplateTypeBanner({template, clickable, disabled}: IMailTemplateTypeBannerProps) {
    
    const { getDisplayNameForMailTemplate } = useTemplateUtil();

    const name = getDisplayNameForMailTemplate(template);

    if (!name) return null;

    return (
        <Pill 
            onClick={clickable ? () => {} : undefined} 
            text={`${name}${disabled ? " (nicht anpassbar)" : ""}`} 
            color={disabled ? "background" : "#432ECD"} 
            textColor={disabled ? "backgroundDarkest" : undefined}
        />
    );
}