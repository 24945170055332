import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { BlacklistType, IBlacklist } from "../../../types/blacklist.schema";
import { Sorted } from "../../../types/collection.types";

export const useOfficeAccountBlacklist = (id: string, opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IBlacklist, BlacklistType, IBlacklist[]>>(ApiRoutes.Blacklist.OfficeAccount.All.getRoute({
    [RouteParam.OfficeAccountId]: id
  }), opts);

  return {
    officeAccountBlacklist: data?.items ?? [],
    officeAccountBlacklistByType: data?.type,
    loadingOfficeAccountBlacklist: isLoading,
    reloadOfficeAccountBlacklist: mutate
  }
} 