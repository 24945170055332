import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicketActivity } from "../../../types/ticketActivity.schema";

export const useNotifications = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketActivity>>(ApiRoutes.User.Notifications.All, {
        refetchOnWindowFocus: false
    });

    return {
        notifications: data,
        loadingNotifications: isLoading,
        reloadNotifications: mutate
    }
}