import { IMongooseDocument, TiggiMode } from "./ApiTypes";

export enum LogEntryType {
    Error = "error",
    Event = "event",
    General = "general",
    Schedule = "schedule",
}

export enum LogEntryEventType {
    None = "none",
    TicketEscalation = "ticketEscalation",
    MsOfficeLifecycle = "msOfficeLifecycle",
    MsOfficeEvent = "msOfficeEvent",
    Imap = "imap",
    Stripe = "stripe",
    GCloud = "gCloud",
    SendGrid = "sendGrid",
}

export enum ExternalService {
    TiggiWebClient = "tiggiWebClient",
    TiggiDatevDesktopClient = "tiggiDatevDesktopClient",
    None = "none",
    Azure = "azure",
    SendGrid = "sendGrid",
    Stripe = "stripe",
    PointChamp = "pointChamp",
    CloudGateway = "cloudGateway",
}

export interface IAffectedLogEntryEntities {
    officeAccount?: string,
    ticket?: string,
    user?: string,
    mailAccount?: string,
    ticketMessage?: string,
    tenant?: string,
    client?: string,
}

export interface ILogEntryData {
    type?: LogEntryType,
    affectedEntities?: IAffectedLogEntryEntities,
    event?: LogEntryEventType,
    tenant?: string,
    additionalInfo?: string,
    eventId?: string,
    service?: ExternalService,
    content: string,
}

export interface ILogEntryDocument extends ILogEntryData {
    system?: TiggiMode,
    methodName?: string,
    disappears?: Date
}

export interface ILogEntry extends IMongooseDocument, ILogEntryDocument {
}