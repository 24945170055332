import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { Permission } from "../../types/ApiTypes";
import IElementProps from "../../types/element.types";
import { hexWithOpacity } from "../../util/util";
import LabelButton from "../buttons/LabelButton";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./Table.css";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import TableCheckbox from "./TableCheckbox";

type TableHeader<T> = string | ITableHeader<T>;

interface ITableHeader<T> {
  label: string,
  hidden?: boolean,
  filterItem?: (item: T, filter: string) => boolean,
  permissions?: Array<Permission>,
  valueKey?: string
}

interface IActiveFilter<T> {
  header: ITableHeader<T>,
  index: number,
  filterValue: string
}

interface ITableProps<T> extends IElementProps {
  searchBoxAlwaysVisible?: boolean,
  canSelect?: boolean,
  selectedItems?: Map<string, T>,
  onSelectionChange?: (ids: Map<string, T>) => void,
  getItemId?: (item: T) => string,
  renderItem: (item: T, checkbox?: React.ReactNode) => React.ReactNode,
  itemShouldRender?: (item: T) => boolean,
  items: Array<T>,
  border?: boolean,
  smallHeader?: boolean,
  headers: TableHeader<T>[]
}

export default function Table<T>(props: ITableProps<T>) {

  const {
    children,
    selectedItems,
    itemShouldRender,
    searchBoxAlwaysVisible,
    renderItem,
    headers,
    className,
    items,
    onSelectionChange,
    smallHeader,
    canSelect,
    getItemId,
    border = true
  } = props;

  const itemsPerPage = 15;

  const [currentItems, setCurrentItems] = React.useState<Array<T>>([]);
  const [filteredItems, setFilteredItems] = React.useState<Array<T>>([]);
  const [activeFilters, setActiveFilters] = React.useState<Array<IActiveFilter<T>>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [currentStart, setCurrentStart] = React.useState<number>(0);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filtering, setFiltering] = React.useState<boolean>(false);

  const filteringTimeoutRef = React.useRef<any>(null);
  const loadMoreRef = React.useRef<HTMLTableRowElement>(null);

  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        if (loading) return;
        if (entries[0].isIntersecting) setPage(n => n + 1);
      })
  );

  const resetItems = () => {
    setHasMore(true);
    setCurrentItems([]);
    setCurrentStart(0);
    setPage(0);
  }

  React.useEffect(() => {
    if (!loadMoreRef || !loadMoreRef.current) return;
    if (!observer || !observer.current) return;

    observer.current.observe(loadMoreRef.current);
  }, [loadMoreRef]);

  React.useEffect(() => {
    setFilteredItems(items);
    resetItems();
  }, [items]);

  React.useEffect(() => {
    resetItems();
  }, [activeFilters])

  React.useEffect(() => {
    if (!hasMore) return;
    if (!filteredItems || !filteredItems.length) return;

    setLoading(true);

    const remaining = filteredItems.length - currentStart;
    const nextStep = remaining > itemsPerPage ? itemsPerPage : remaining;
    const nextItems = filteredItems.slice(currentStart, currentStart + nextStep);

    setHasMore(nextStep === itemsPerPage);
    setCurrentStart(s => s + nextStep);

    const newItems = [...currentItems, ...nextItems];

    setCurrentItems(newItems);

    setLoading(false);
  }, [page, filteredItems]);

  React.useEffect(() => {
    let filterableItems = [...items];

    for (const filter of activeFilters) {
      if (!filter.filterValue) continue;

      filterableItems = filterableItems.filter(i => {
        try {
          if (!i) return false;
          if (!filter.header) return false;

          if (filter.header.valueKey) {
            const itemValue = ((i as any)[filter.header.valueKey] as any).toString().toUpperCase();
            return (itemValue as string).includes(filter.filterValue);
          }

          if (filter.header.filterItem) return filter.header.filterItem(i, filter.filterValue);
        }
        catch {
          return false;
        }
      })
    }

    setFilteredItems(filterableItems);

  }, [activeFilters]);

  const wrapperClass = generateClassName("table-wrapper w-100", className, {
    value: border,
    onTrue: "table-wrapper-border"
  });

  const handleFilter = (header: TableHeader<T>, value: string, filterId: number) => {
    if (filteringTimeoutRef.current) clearTimeout(filteringTimeoutRef.current);
    if (typeof header === "string") return;

    setFiltering(true);

    filteringTimeoutRef.current = setTimeout(() => {
      try {
        const currentFilters = [...activeFilters];

        const existingIndex = currentFilters.findIndex(c => c.index === filterId);

        if (existingIndex < 0) {
          if (!value) return;

          currentFilters.push({ filterValue: value, header: header, index: filterId });
          setActiveFilters(currentFilters);
          return;
        }

        if (!value) {
          setActiveFilters(currentFilters.filter(x => x.index !== filterId));
          return;
        }

        currentFilters[existingIndex].filterValue = value.toUpperCase().trim();
        setActiveFilters(currentFilters);
      }
      finally {
        setFiltering(false);
      }
    }, 350);
  }

  const getIdFromItem = (item: T) => getItemId ? getItemId(item) : `${item}`;

  const handleSelect = (item: T) => {
    const id = getIdFromItem(item);
    const newSelection = new Map(selectedItems);

    if (newSelection.has(id)) newSelection.delete(id);
    else newSelection.set(id, item);

    onSelectionChange?.(newSelection);
  }

  if (!items?.length) return <span>Keine Elemente</span>

  return (
    <div className={wrapperClass}>
      <table className="table w-100 table-responsive">
        <thead style={generateStyle({ name: "backgroundColor", value: hexWithOpacity("muted", 0.3, true) })} >
          <tr>
            {
              canSelect && (
                <TableCheckbox
                  as="th"
                  isSelected={selectedItems?.size === filteredItems?.length}
                  onChange={val => {
                    const ids = new Map<string, T>();
                    if (val) filteredItems.forEach(i => {
                      const id = getIdFromItem(i);
                      ids.set(id, i);
                    });
                    onSelectionChange?.(ids);
                  }}
                />
              )
            }
            {
              headers && !!headers.length && (
                headers.map((h: TableHeader<T>, index: number) => {

                  const headerValue = typeof h === "string" ? h : h.label;

                  return (
                    <TableHeader
                      key={`table-header-${headerValue || index}`}
                      searchBoxAlwaysVisible={typeof h !== "string" && searchBoxAlwaysVisible}
                      hidden={typeof h !== "string" && h.hidden}
                      onFilter={(v: string) => handleFilter(h, v, index)}
                      canFilter={typeof h !== "string" && (!!h.filterItem || !!h.valueKey)}
                      value={headerValue}
                    />
                  )
                })
              )
            }
          </tr>
        </thead>
        <tbody className="w-100 table-content">
          {
            filtering
              ? <LoadingSpinner asTableRow text="Lädt Ergebnisse..." />
              : (
                <>
                  {
                    currentItems?.length
                      ? currentItems.map(c => {
                        if (itemShouldRender && !itemShouldRender(c)) return null;
                        return renderItem(c, canSelect && (
                          <TableCheckbox
                            isSelected={!!selectedItems?.has(getIdFromItem(c))}
                            onChange={() => handleSelect(c)}
                          />
                        ))
                      })
                      : <TableRow><TableCell>Keine Einträge</TableCell></TableRow>
                  }
                  <tr id="table-infinite-scroll-observer" ref={loadMoreRef} style={{ height: "1px", width: "100%" }} />
                  <tr>
                    <td colSpan={headers.length}>
                      <div className="d-flex flex-row align-items-center" style={{ color: "#A0A0A0" }}>
                        {
                          loading
                            ? <LoadingSpinner />
                            : (
                              hasMore
                                ? <LabelButton onClick={async () => setPage(n => n + 1)} text="Mehr laden" />
                                : <span>Keine weiteren Einträge</span>
                            )
                        }
                      </div>
                    </td>
                  </tr>
                </>
              )
          }
        </tbody>
      </table>
    </div>
  )
}