import React from "react";
import { IAlias } from "../../types/ApiTypes";
import Card from "../card/Card";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import DeleteAliasButton from "./DeleteAliasButton";

export interface IAliasFormProps {
  isLoading: boolean,
  data: Array<IAlias> | undefined,
  mutate: () => Promise<any>
}

export default function AliasForm({ data, isLoading, mutate }: IAliasFormProps) {

  return (
    <WithPermissions permissions={["alias.all.read"]}>
      <Flex className="w-100" gap="3">
        <WithPermissions permissions={["alias.all.create", "alias.own.create"]}>
          <FormikField className="w-100" label="Neues Alias speichern" name="aliasMailAddress" placeholder="Alias-Mailadresse eingeben..." />
        </WithPermissions>
        <Typography bold color="primary">Existierende Alias</Typography>
        <Flex row fullWidth wrap>
          {
            isLoading
              ? <LoadingSpinner />
              : (
                data?.length
                  ? data.map(a => (
                    <Card key={a._id} fitContent>
                      <Flex row justify="between" className="w-100" gap="5">
                        <Typography color="primary" bold size={14}>{a.aliasMailAddress}</Typography>
                        <DeleteAliasButton aliasId={a._id} mutate={mutate} />
                      </Flex>
                    </Card>
                  ))
                  : <Typography>Keine Alias vorhanden.</Typography>
              )
          }
        </Flex>
      </Flex>
    </WithPermissions>
  )
}