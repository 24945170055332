import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import Button from "../../components/buttons/Button";
import SearchableComboBox from "../../components/comboBox/SearchableComboBox";
import Flex from "../../components/container/Flex";
import PageHeader from "../../components/label/PageHeader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import OfficeInbox from "../../components/mail/inbox/OfficeInbox";
import OfficeInboxMessageView from "../../components/mail/inbox/OfficeInboxMessageView";
import OfficeMailForm from "../../components/mail/inbox/OfficeMailForm";
import WithPermissions from "../../components/permissions/WithPermissions";
import SearchBox from "../../components/search/SearchBox";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useFilteredOfficeMails, { OfficeMailboxTab } from "../../hooks/useFilteredOfficeMails";
import useLabels, { Label } from "../../hooks/useLabels";
import Page from "../../layout/Page";
import { setRightSidebarExpanded } from "../../state/slices/sidebarSlice";
import { useCurrentUserOfficeAccount } from "../../state/swr/office/useCurrentUserOfficeAccount";
import { useMainTenantOfficeAccount } from "../../state/swr/office/useMainTenantOfficeAccount";
import { useOfficeAccounts } from "../../state/swr/office/useOfficeAccounts";
import { useUser } from "../../state/swr/user/useUser";
import { IOfficeAccount } from "../../types/officeAccount.types";
import "./OfficeInboxPage.css";


export default function OfficeInboxPage() {

  const [editorVisible, setEditorVisible] = React.useState<boolean>(false);
  const [isResponding, setIsResponding] = React.useState<boolean>(false);
  const [isRespondingAll, setIsRespondingAll] = React.useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = React.useState<string>("inbox");
  const [selectedFolderTitle, setSelectedFolderTitle] = React.useState<string>("Posteingang");
  const [selectedMail, setSelectedMail] = React.useState<Message>();
  const [account, setAccount] = React.useState<IOfficeAccount>();

  const { view, updateFilter, filterText } = useFilteredOfficeMails();

  const getLabel = useLabels();

  const dispatch = useAppDispatch();

  const {
    user
  } = useUser();

  const { currentUserOfficeAccount, reloadCurrentUserOfficeAccount, loadingCurrentUserOfficeAccount } = useCurrentUserOfficeAccount();
  const { loadingOfficeAccounts, officeAccounts } = useOfficeAccounts();
  const { loadingTenantOfficeAccount, tenantOfficeAccount } = useMainTenantOfficeAccount();

  const openMail = (m: Message | undefined) => {
    dispatch(setRightSidebarExpanded(true));
    setSelectedMail(m);
  }
  const respondToMail = (respondAll?: boolean) => {
    setEditorVisible(true);
    setIsResponding(true);
    setIsRespondingAll(!!respondAll);
  }

  const discardMail = () => {
    setEditorVisible(false);
    setIsResponding(false);
  }

  React.useEffect(() => {
    reloadCurrentUserOfficeAccount();
  }, [user]);

  React.useEffect(() => {
    setSelectedFolder("inbox");
  }, [account]);

  React.useEffect(() => {
    const realAccount = (
      view === OfficeMailboxTab.Personal
        ? currentUserOfficeAccount
        : (view === OfficeMailboxTab.Tenant ? tenantOfficeAccount : account)
    );
    setAccount(realAccount);
  }, [view, user, currentUserOfficeAccount, loadingCurrentUserOfficeAccount, tenantOfficeAccount, loadingTenantOfficeAccount]);

  return (
    <Page
      widerSidebar
      rightSidebar={(selectedMail && account) && (
        <OfficeInboxMessageView
          item={selectedMail}
          respond={respondToMail}
          folderId={selectedFolder}
          account={account}
          closeMessage={() => setSelectedMail(undefined)}
        />
      )}
    >
      <Flex className="w-100 h-100" >
        <Flex row gap={5} justify="between" className="w-100">
          <Flex className="w-100" row gap={3}>
            <PageHeader text={selectedFolderTitle ? selectedFolderTitle : "Posteingang"} />
            {
              account && <Button iconSize={30} onClick={async () => setEditorVisible(true)} icon="plus-circle-fill" variant="icon" />
            }
          </Flex>
          <SearchBox
            initialValue={filterText}
            onChange={val => updateFilter({ search: val })}
            delay={500}
          />
        </Flex>
        <WithPermissions permissions={["inboxes.teams.read", "inboxes.tenant.read", "inboxes.all.read"]}>
          <Flex className="w-100" row wrap justify="between">
            <TabSwitcher
              tabs={[
                {
                  data: OfficeMailboxTab.Personal,
                  label: "Ihr Postfach",
                  icon: "person-fill"
                },
                {
                  data: OfficeMailboxTab.Teams,
                  label: getLabel(Label.TeamMailAccounts),
                  permissions: ["inboxes.teams.read"],
                  icon: "people-fill"
                },
                {
                  data: OfficeMailboxTab.Tenant,
                  label: getLabel(Label.TenantName),
                  permissions: ["inboxes.tenant.read"],
                  icon: "building"
                },
                {
                  data: OfficeMailboxTab.All,
                  label: "Alle",
                  permissions: ["inboxes.all.read"],
                  backColor: "#314AF0",
                  icon: "inboxes-fill"
                }
              ]}
              tabQueryParamKey="view"
            />
            <WithPermissions permissions={["inboxes.all.read"]}>
              {
                view === OfficeMailboxTab.All && (
                  loadingOfficeAccounts
                    ? <LoadingSpinner />
                    : (
                      officeAccounts && officeAccounts.length
                        ? (
                          <SearchableComboBox
                            value={account}
                            values={officeAccounts}
                            itemToId={item => item._id}
                            itemToString={item => item.accountMail}
                            onItemClick={item => setAccount(item)}
                            placeholder="Bitte Postfach auswählen..."
                          />
                        )
                        : <span>Keine Mailkonten verknüpft</span>
                    )
                )
              }
            </WithPermissions>
          </Flex>
        </WithPermissions>
        <Flex className="h-100" style={{ overflowY: "auto" }}>
          {
            (loadingOfficeAccounts || loadingTenantOfficeAccount || loadingCurrentUserOfficeAccount)
              ? <LoadingSpinner />
              : (
                <OfficeInbox
                  setEditorVisible={setEditorVisible}
                  setSelectedFolder={(val?: string) => setSelectedFolder(val || "")}
                  setSelectedFolderTitle={(val?: string) => setSelectedFolderTitle(val || "")}
                  setSelectedMail={openMail}
                  account={account}
                  isEditorVisible={editorVisible}
                  isResponding={isResponding}
                  selectedFolder={selectedFolder}
                  selectedFolderTitle={selectedFolderTitle}
                  selectedMail={selectedMail}
                  showAll={view === OfficeMailboxTab.All}
                />
              )
          }
        </Flex>
        {
          editorVisible && account && (
            <OfficeMailForm
              respondAll={isRespondingAll}
              item={isResponding && selectedMail ? selectedMail : undefined}
              account={account}
              discardMail={discardMail}
            />
          )
        }
      </Flex>
    </Page>
  )
}