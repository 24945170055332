import React from "react";
import { useSearchParams } from "react-router-dom";
import { SortDirection } from "../components/buttons/SortDirectionToggle";
import { useNotifications } from "../state/swr/user/useNotifications";
import { ITicketActivity } from "../types/ticketActivity.schema";
import { TicketSort } from "./useFilteredTickets";
import useFilterQuery from "./useFilterQuery";

export enum FilteredNotificationsQueryKey {
  FilterText = "notificationsFilterText",
  Sort = "notificationsSort",
  Direction = "notificationsDirection",
}

export interface INotificationsFilterUpdate {
  notificationsFilterText?: string,
  notificationsSort?: TicketSort,
  notificationsDirection?: SortDirection
}

export default function useFilteredNotifications() {

  const [filterText, setFilterText] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<TicketSort | undefined>(TicketSort.UpdatedAt)
  const [direction, setDirection] = React.useState<SortDirection>(SortDirection.Descending);
  const [matchingNotifications, setMatchingNotifications] = React.useState<ITicketActivity[]>([]);

  const { updateQueryFromFilter } = useFilterQuery();
  const [query, setQuery] = useSearchParams();

  const { notifications, loadingNotifications } = useNotifications();

  React.useEffect(() => {
    if (!query) return;

    const f = query.get(FilteredNotificationsQueryKey.FilterText);

    if (!f) setFilterText("");
    else setFilterText(f);

    const s = query.get(FilteredNotificationsQueryKey.Sort);

    if (!s) setSort(TicketSort.UpdatedAt);
    else setSort(s as TicketSort);

    const d = query.get(FilteredNotificationsQueryKey.Direction);

    if (!d) setDirection(SortDirection.Descending);
    else setDirection(d as SortDirection);

  }, [query]);

  React.useEffect(() => setLoading(loadingNotifications), [loadingNotifications]);

  // put this on the server
  React.useEffect(() => {
    if (loadingNotifications) return;
    if (loading) return;

    if (!notifications || !notifications.length) {
      setMatchingNotifications([]);
      return;
    }

    let result: Array<ITicketActivity> = notifications;

    // if (filterText) result = result.filter(t => {
    //     const upperFilter = filterText.toUpperCase(); 
    //     if (!t.ticket?.subject) return false;
    //     return t.ticket?.subject.toUpperCase().includes(upperFilter);
    // });

    // result.sort((a, b) => {
    //     if (!a) return 1;
    //     if (!b) return -1;

    //     switch (sort) {
    //         case TicketSort.CreatedAt: 

    //             if (!a.createdAt) return 1;
    //             if (!b.createdAt) return -1;
    //             return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

    //         case TicketSort.UpdatedAt: 

    //             if (!a.ticket?.lastUpdate) return 1;
    //             if (!b.ticket?.lastUpdate) return -1;
    //             return new Date(b.ticket?.lastUpdate).getTime() - new Date(a.ticket?.lastUpdate).getTime();

    //         case TicketSort.Status: 

    //             if (!a.ticket?.state) return 1;
    //             if (!b.ticket?.state) return -1;

    //             return b.ticket?.state.localeCompare(a.ticket?.state);

    //         case TicketSort.Subject: 

    //             if (!a.ticket?.subject) return 1;
    //             if (!b.ticket?.subject) return -1;

    //             return b.ticket?.subject.localeCompare(a.ticket?.subject);

    //         case TicketSort.Type: 

    //             if (a.ticket?.type === undefined) return 1;
    //             if (b.ticket?.type === undefined) return -1;

    //             return b.ticket?.type - a.ticket?.type;

    //     }

    //     return 1;
    // });

    if (direction === SortDirection.Ascending) result.reverse();

    setMatchingNotifications(result);

  }, [filterText, direction, sort, notifications, loading, loadingNotifications]);

  const updateFilter = (update: INotificationsFilterUpdate) => {
    const result: INotificationsFilterUpdate = {
      notificationsDirection: update.notificationsDirection || SortDirection.Descending,
      notificationsSort: update.notificationsSort || sort || TicketSort.UpdatedAt,
      notificationsFilterText: update.notificationsFilterText || "",
    };

    updateQueryFromFilter(result);
  }

  return {
    loading,
    sort,
    filterText,
    direction,
    matchingNotifications,
    updateFilter,
  }
}