import React from "react";
import Flex from "../../../container/Flex";
import Icon from "../../../icons/Icon";
import Typography from "../../../text/Typography";
import { useOfficeAcountFolders } from "../../../../state/swr/office/useOfficeAccountFolders";
import { MailFolder } from "@microsoft/microsoft-graph-types";
import OfficeFolder from "./OfficeFolder";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import SearchBox from "../../../search/SearchBox";
import "./OfficeInboxFolders.css";
import { generateClassName } from "../../../../hooks/useAttributes";
import useInboxPermissions from "../../../../hooks/useInboxPermissions";
import WithPermissions from "../../../permissions/WithPermissions";
import { IOfficeAccount } from "../../../../types/officeAccount.types";

export interface IOfficeInboxFoldersProps {
    selectedFolder?: string,
    setSelectedFolder: (id?: string) => void,
    setSelectedFolderTitle: (title?: string) => void,
    account: IOfficeAccount,
}

export default function OfficeInboxFolders({account, setSelectedFolder, setSelectedFolderTitle, selectedFolder}: IOfficeInboxFoldersProps) {
    
    const [folderSelectVisible, setFolderSelectVisible] = React.useState<boolean>(false);
    const [availableFolders, setAvailableFolders] = React.useState<Array<MailFolder>>([]);
    const [filterValue, setFilterValue] = React.useState<string>("");
    const [sortedFolders, setSortedFolders] = React.useState<Array<MailFolder>>([]);

    const { accountFolders, loadingAccountFolders } = useOfficeAcountFolders(account._id);
    
    const {
        getAccountPermissions
    } = useInboxPermissions();

    const updateSelectedFolder = (folder?: MailFolder) => {
        setFolderSelectVisible(false);

        if (!folder || !folder.id) return;

        setSelectedFolder(folder.id);
        setSelectedFolderTitle(folder.displayName || "Mails");
    }

    React.useEffect(() => {
        if (loadingAccountFolders) return;

        if (!accountFolders || !accountFolders.length) {
            setSortedFolders([]);
            return;
        }

        const result = [...accountFolders];

        result.sort((a: MailFolder, b: MailFolder) => {
            switch (a.displayName) {
                case "Posteingang":
                case "Inbox":
                    return -1;
            }

            switch (b.displayName) {
                case "Posteingang":
                case "Inbox":

                    return 1;
            }

            switch (a.displayName) {
                case "Gesendete Elemente":	
                case "Sent Items":
                case "Outbox":

                    return -1;
            }

            switch (b.displayName) {
                case "Gesendete Elemente":	
                case "Sent Items":
                case "Outbox":

                    return 1;
            }
            
            switch (a.displayName) {
                case "Drafts":
                case "Entwürfe":

                    return -1;
            }

            switch (b.displayName) {
                case "Drafts":
                case "Entwürfe":

                    return 1;
            }

            switch (a.displayName) {
                case "Gelöschte Elemente":
                case "Deleted Items":

                    return -1;
            }

            switch (b.displayName) {
                case "Gelöschte Elemente":
                case "Deleted Items":

                    return 1;
            }

            return -1;
        });

        setSortedFolders(result);

        const firstId = result.find(folder => folder.id)?.id;
        if (!!firstId) setSelectedFolder(firstId);

    }, [loadingAccountFolders, accountFolders]);

    React.useEffect(() => {
        if (!sortedFolders || !sortedFolders.length) return;
        if (!filterValue) {
            setAvailableFolders(sortedFolders);
            return;
        }

        const result = sortedFolders.filter(folder => folder.displayName?.toLowerCase().includes(filterValue.toLowerCase()));
        setAvailableFolders(result);

    }, [filterValue, sortedFolders]);

    const contentClass = generateClassName("office-inbox-folders-content w-100 h-100", {
        value: folderSelectVisible,
        base: "office-inbox-folders-content-",
        onTrue: "visible",
        standard: "hidden"
    });

    const permissions = getAccountPermissions("read", account);

    return (
        <WithPermissions permissions={permissions}>
            <Flex className="h-100 position-relative">
                <Flex row >
                    <Icon className="office-inbox-folders-expander-icon" size={24} icon={folderSelectVisible ? "x" : "filter"} onClick={() => setFolderSelectVisible(!folderSelectVisible)} />
                </Flex>
                <Flex className={contentClass}>
                    <Flex row>
                        <Icon className="office-inbox-folders-expander-icon" size={24} icon={"x"} onClick={() => setFolderSelectVisible(!folderSelectVisible)} />
                        <Typography bold>Favoriten</Typography>
                    </Flex>
                    <SearchBox
                        noShadow
                        initialValue={filterValue}
                        onChange={setFilterValue}
                        placeholder="Favoriten durchsuchen..."
                        className="w-100"
                    />
                    <Flex className="h-100 w-100" style={{overflowY: "auto"}}>
                        <Flex className="w-100" gap={0} style={{backgroundColor: "var(--grey)"}}>
                            {
                                loadingAccountFolders 
                                ? <LoadingSpinner text="Lädt Favoriten..." className="p-3" />
                                : (
                                    availableFolders && availableFolders.length 
                                    ? availableFolders.map(folder => (
                                    <OfficeFolder
                                        hierarchyLevel={0}
                                        account={account}
                                        checkIsActive={f => f.id === selectedFolder}
                                        onClick={updateSelectedFolder}
                                        folder={folder} 
                                        key={folder.id}
                                    />))
                                    : <span className="p-3">Keine Ordner</span>
                                )
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </WithPermissions>
    )
}