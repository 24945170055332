import React from "react";
import { useTeams } from "../../state/swr/teams/useTeams";
import Pill from "../pills/Pill";
import { ITeamBase } from "../../types/team.schema";

export interface ITeamBanner {
  team: ITeamBase | string,
  onClick?: () => void,
  isActive?: boolean,
  textOnEmpty?: string
}

export default function TeamBanner({ team, textOnEmpty, isActive = true, onClick }: ITeamBanner) {

  const {
    teamsById,
    loadingTeams
  } = useTeams();

  if (loadingTeams) return <Pill loading loadingText="Lädt..." />

  const usedTeam = typeof team === "string" ? teamsById[team] : team;

  if (!usedTeam || !usedTeam.name) {
    if (textOnEmpty) return <Pill icon="people" color="bright" withDivider text={textOnEmpty} />
    return null;
  }

  const inactiveColor = "#F0F0FF";
  const color = isActive && usedTeam.color || inactiveColor;
  const icon = usedTeam.iconName || "people";

  return (
    <Pill icon={icon} color={color} iconImage={usedTeam.iconBase64} onClick={onClick} withDivider text={usedTeam.name} />
  )
}