import { useSearchParams } from "react-router-dom"
import { AppQueryParams } from "../config/QueryParams";

export enum TicketChatSidebarTab {
    General = "general",
    Assignments = "assignments",
    Attachments = "attachments",
    Mails = "mails",
    Tasks = "tasks",
    Activity = "activity",
    AccessHistory = "access",
    Escalation = "escalation",
    History = "history"
}

export default function useTicketSidebar() {

    const [q, setQ] = useSearchParams();

    const switchTab = (t: TicketChatSidebarTab) => {
        const currentQuery = q.get(AppQueryParams.SidebarTab);
        if (currentQuery === t) return;

        const newQuery = new URLSearchParams(q);
        newQuery.set(AppQueryParams.SidebarTab, t);
        
        setQ(newQuery, { replace: true });
    }
 
    return {
        switchTab
    }
}