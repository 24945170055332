import React from "react";
import useFilteredUsers from "../../hooks/useFilteredUsers";
import useUserUtil from "../../hooks/useUserUtil";
import { IMailTemplate } from "../../types/ApiTypes";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import { useMailTemplates } from "../../state/swr/templates/useMailTemplates";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";

export interface IMailTemplateSelectProps {
  onChange: (template: IMailTemplate | undefined) => void,
  value?: string,
  bold?: boolean,
  label?: string,
  placeholder?: string
}

export default function MailTemplateSelect({ onChange, placeholder, label, bold, value }: IMailTemplateSelectProps) {

  const {
    loadingMailTemplates,
    mailTemplates
  } = useMailTemplates();

  const {
    getStringified
  } = useTemplateUtil();

  return (
    <SearchableComboBox
      bold={bold}
      values={mailTemplates ?? []}
      loading={loadingMailTemplates}
      itemToId={m => m._id}
      itemToString={m => `${getStringified(m.subject)}`}
      clearValue={() => onChange(undefined)}
      onItemClick={onChange}
      label={label}
      placeholder={placeholder || "Mailvorlage auswählen..."}
      value={value}
    />
  )

}