import React from "react";
import { AppColor } from "../../../../app/AppStyles";
import { generateClassName } from "../../../../hooks/useAttributes";
import useUserUtil from "../../../../hooks/useUserUtil";
import { formatDate } from "../../../../util/formatter";
import Icon from "../../../icons/Icon";
import "./TicketMessageReadIndicator.css";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export default function TicketMessageReadIndicator({ message }: { message: ITicketMessage }) {
  const [hover, setHover] = React.useState<boolean>(false);

  const {
    getName
  } = useUserUtil();

  const toggleHover = (val: boolean) => {
    if (!message.isRead) return;
    setHover(val);
  }

  const containerClass = generateClassName("position-relative ticket-message-read-indicator d-flex align-items-center p-0 m-0", {
    value: message && message.isRead,
    onTrue: "ticket-message-read-indicator-read"
  });

  return (
    <div className={containerClass} onMouseEnter={() => toggleHover(true)} onMouseOver={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
      {
        hover && (
          <div className="position-absolute gap-1 shadow ps-2 ticket-message-read-metadata-container d-flex flex-row align-items-center">
            <div className="d-flex flex-column" style={{ fontSize: "0.9em" }}>
              <span className="text-nowrap">
                {
                  getName(message.isReadBy)
                }
              </span>
              <span style={{ fontSize: "0.8em" }}>
                {
                  formatDate(message.isReadAt)
                }
              </span>
            </div>
            <Icon icon={message.isRead ? "check-all" : "check"} color="primary" />
          </div>
        )
      }
      <Icon icon={message.isRead ? "check-all" : "check"} size={20} color={message.isRead ? "success" : undefined} />
    </div>
  )
}