import React, { useEffect } from "react";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useRoles } from "../../state/swr/roles/useRoles";
import { IAddressee, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientContactListItem from "./ClientContactListItem";

export interface ISortedClientContact {
    user?: IUser,
    addressees: IAddressee[]
}

export default function ClientContactList() {

    const [sortedClientContacts, setSortedClientContacts] = React.useState<ISortedClientContact[]>([]);

    const {
        getSortedClientContacts
    } = useAddresseeUtil();

    const {
        getName
    } = useUserUtil();

    const { addressees, loadingAddressees } = useAddressees();

    useEffect(() => {
        if (loadingAddressees) return;
        if (!addressees || !addressees.length) return;
        const result = getSortedClientContacts();
        setSortedClientContacts(result);
    }, [addressees, loadingAddressees]);

    return (
        <WithPermissions permissions={[ "users.clientContacts.read" ]}>
            {
                loadingAddressees
                ? <LoadingSpinner centered={false} text="Mandantenkontakte werden geladen..." />
                : (

                    <Table 
                        border 
                        className="w-100" 
                        headers={[
                            { 
                                label: "Name",
                                filterItem: (c, filter) => {
                                    if (!c.user) return false;
                                    const name = getName(c.user);
            
                                    if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                                    if (c.user.mailAddress && c.user.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;
            
                                    return false;
                                }
                            }, {
                                label: "Addressaten",
                                filterItem: (c, filter) => {
                                    if (!c.addressees || !c.addressees.length) return false;
                                    const values = c.addressees.map(a => a.actualName);
                                    return values.some(v => v.toLowerCase().includes(filter.toLowerCase()));
                                }
                            }, {
                                label: "Mandanten"
                            }, {
                                label: "Alias"
                            },
                            { label: "" }
                        ]}
                        items={sortedClientContacts}
                        renderItem={s => <ClientContactListItem key={s.user ? s.user._id : "no-user-addressees"} clientContact={s} />}
                    />
                )
            }
        </WithPermissions>
    )
}