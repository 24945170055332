import { IDatevAreaOfResponsibility } from "../types/ApiTypes";
import { IClient } from "../types/client.schema";
import { IDatevEmployee } from "../types/employee.schema";
import { IDatevEmployeeResponsibility } from "../types/employeeResponsibility.schema";
import { ISortedClientResponsibilities, ISortedClientResponsibility } from "./useClientResponsibility";


export default function useClientResponsibilityUtil() {
    const sortClientResponsibility = (
        clientsToSearchIn: Array<IClient> | null, 
        areasOfResponsibility: Array<IDatevAreaOfResponsibility> | null, 
        employees: Array<IDatevEmployee> | null, 
        employeeResponsibility: Array<IDatevEmployeeResponsibility> | null
    ): Array<ISortedClientResponsibilities> => {

        if ((!employeeResponsibility || !employeeResponsibility.length)
        || (!clientsToSearchIn || !clientsToSearchIn.length)
        || (!areasOfResponsibility || !areasOfResponsibility.length)
        || (!employees || !employees.length)) return [];
    
        const result: Array<ISortedClientResponsibilities> = [];
    
        for (const responsibility of employeeResponsibility) {
            
            const clientIndex = result.findIndex(s => s.client.id === responsibility.client_id);
    
            if (clientIndex < 0) {
                const client = clientsToSearchIn.find(c => c.id === responsibility.client_id);
                
                if (!client) continue;
    
                result.push({
                    client: client,
                    responsibilities: []
                });
            }
    
            const realClientIndex = clientIndex < 0 ? result.length - 1 : clientIndex;
    
            const responsibilityIndex = result[realClientIndex].responsibilities.findIndex(r => r.areaOfResponsibility.id === responsibility.area_of_responsibility_id);
    
            if (responsibilityIndex < 0) {
                const value: ISortedClientResponsibility = {} as ISortedClientResponsibility;
                
                const areaOfResponsibility = areasOfResponsibility.find(a => a.id === responsibility.area_of_responsibility_id);
                
                if (!areaOfResponsibility) continue;
    
                value.areaOfResponsibility = areaOfResponsibility;
                value.employees = [];
    
                result[realClientIndex].responsibilities.push(value);
            }
    
            const realResponsibilityIndex = responsibilityIndex < 0 ? result[realClientIndex].responsibilities.length - 1 : responsibilityIndex;
    
            const employee = employees.find((e: any) => e.id === responsibility.employee_id);
    
            if (!employee) continue;
    
            result[realClientIndex].responsibilities[realResponsibilityIndex].employees.push(employee);
        }
    
        return result;
    }
    
    
    const getResponsibilitiesForClient = (client: IClient, sortedClientResponsibility: Array<ISortedClientResponsibilities>): ISortedClientResponsibilities | undefined => {
        if (!client) return undefined;
        if (!sortedClientResponsibility || !sortedClientResponsibility.length) return;
        return sortedClientResponsibility.find(c => c.client._id === client._id);
    }

    return {
        sortClientResponsibility,
        getResponsibilitiesForClient
    }
}