import React from "react";
import { useAnyUserStatistics } from "../../../state/swr/statistics/useAnyUserStatistics";
import { useUser } from "../../../state/swr/user/useUser";
import WithPermissions from "../../permissions/WithPermissions";
import UserSelect from "../../user/UserSelect";
import TicketStatsCardGrid from "../TicketStatsCardGrid";
import { TicketProductivityTab } from "../ProductivityOverview";

export interface IUserProductivityOverviewProps {
  userId?: string,
  setUserId: (userId?: string) => void
}

export default function UserProductivityOverview({ userId, setUserId }: IUserProductivityOverviewProps) {
  const { user } = useUser();

  const id = userId || user?._id || "";

  const { loadingUserStatistics, userStatistics } = useAnyUserStatistics(id);

  return (
    <WithPermissions permissions={["statistics.own.read"]}>
      <TicketStatsCardGrid
        isPersonal
        currentTab={TicketProductivityTab.Personal}
        resetTarget={id}
        title="Deine Tickets in Zahlen"
        isLoading={loadingUserStatistics}
        statistics={userStatistics}
        cardColor="background"
        filterComponent={(
          <WithPermissions permissions={["statistics.tenant.read"]}>
            <UserSelect
              useDefaultValue={false}
              placeholder="Mitarbeiter suchen..."
              className="text-right"
              onChange={user => setUserId(user?._id)}
              displayed="employees"
              value={userId}
            />
          </WithPermissions>
        )}
      />
    </WithPermissions>
  )
}