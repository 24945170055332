import { IRole, UserRole } from "../types/ApiTypes";

export default function useAnyRoleUtil() {

    const getRoleId = (role: UserRole, roles: Array<IRole> | undefined | null): string | null => {
        if (!roles || !roles.length) return null;

        const foundRole = roles.find(r => r.displayName === role);

        if (!foundRole) return null;
        return foundRole._id;
    }

    const getFilteredRoles = (roles: Array<IRole> | undefined | null, filter: (r: IRole) => boolean): Array<IRole> | null => {
        if (!roles || !roles.length) return null;

        return roles.filter(r => filter(r));
    }

    const convertToUserRole = (role?: IRole): UserRole => {
        if (!role) return UserRole.Clerk;
        return role.displayName as UserRole;
    }

    const isRole = (role: IRole | undefined | null, requiredRole?: UserRole): boolean => {
        if (!role || !requiredRole) return false;
        return role.displayName === requiredRole;
    }

    return {
        isRole,
        getRoleId,
        convertToUserRole,
        getFilteredRoles
    }
}