import React from "react";
import Accordion from "../accordion/Accordion";
import Card from "../card/Card";
import DocumentClearanceMonthOverview from "./DocumentClearanceMonthOverview";
import { IDocumentClearanceYear } from "./DocumentClearanceOverview";

export default function DocumentClearanceYearOverview({year, isExpandedInitially}: {isExpandedInitially: boolean, year: IDocumentClearanceYear}) {
    return (
        <Accordion text={year.year} icon="chevron-down" isExpandedInitially={isExpandedInitially} iconExpanded="chevron-up" iconFirst expanderClass="fs-6 fw-bold">
            {
                year.months && year.months.length 
                ? (
                    <Card className="d-flex flex-column gap-2">
                        {
                            year.months.map((m, index) => <DocumentClearanceMonthOverview month={m} isExpandedInitially={index === 0} key={`${year.year}-${m.month}`} />)
                        }
                    </Card>
                )
                : <span>Keine Einträge</span>
            }

        </Accordion>
    )
}