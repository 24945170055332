import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";
import React from "react";
import { IButtonPropsBase } from "../../components/buttons/Button";

export interface IModalActionPayload {
    text: string, 
    type: ModalType
}

interface INotificationSlice {
    notifications: INotification[]
}

export interface INotification {
    type: ModalType,
    title?: string,
    text: string,
    id?: number | string,
    action?: IButtonPropsBase
}

export const NotificationSlice = createSlice({
    name: "notification",
    initialState: {
        notifications: []
    } as INotificationSlice,
    reducers: {
        addNotification: (state, action: PayloadAction<INotification>) => {
            
            if (!action || !action.payload) return;
            
            const existing = state.notifications ?? [];

            const {
                id
            } = action.payload;

            const hasExisting = existing.find(n => n.id === id);

            if (hasExisting) return;

            state.notifications = [
                ...existing,
                action.payload
            ];
        },
        removeNotification: (state, action: PayloadAction<number | string>) => {
            if (!action || action.payload === undefined) return;
            if (!state.notifications || !state.notifications.length) return;

            const {
                payload
            } = action;

            state.notifications = state.notifications.filter(n => n.id !== payload);
        }
    }
});

export const { addNotification, removeNotification } = NotificationSlice.actions;

export default NotificationSlice.reducer;