import React from "react";
import { IWithAddressee } from "../../../types/addressee.schema";
import { DatevAddresseeAddressType, IDatevAddresseeAddress, IDatevAddresseeBankAccount, IDatevAddresseeTaxOffice } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import CopyToClipboardButton from "../../buttons/CopyToClipboardButton";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import DatevWithExpiredData from "../../datev/data/DatevWithExpiredData";
import Icon from "../../icons/Icon";
import Typography from "../../text/Typography";
import Pill from "../../pills/Pill";

export default function AddresseeBankAccounts(props: IWithAddressee) {

  const {
    addressee
  } = props;

  const renderCopyable = (id: string, label: string) => {
    if (!id) return null;

    return (
      <Flex row fullWidth justify="between" align="start">
        <Flex gap="0">
          <Typography size="11" bold>{label}</Typography>
          <Typography>{id}</Typography> 
        </Flex>
        <CopyToClipboardButton value={id} variant="text" />
      </Flex>
    )
  }

  return (
    <DatevWithExpiredData
      data={addressee.bank_accounts}
    >
      {
        (checkExpired, getValidityRange, showExpired, toggleExpired, data, hasData, hasActiveData, hasInactiveData, createDefaultToggle) => {

          const renderItem = (c: IDatevAddresseeBankAccount, i: number) => {

            if (!c.iban) return null;

            const color = c.is_main_bank_account && c.currently_valid ? "background" : "backgroundLighter";

            return (
              <Card
                key={`${c._id}-${i}`}
                strongColor={c.is_main_bank_account}
                fitContent
                color={color}
                header={(
                  <Flex row justify="between" fullWidth gap="5">
                    <Flex row align="start">
                      <Icon icon="bank" />
                      <Flex gap="0">
                        <Typography bold={c.is_main_bank_account}>{c.bank_name}</Typography>
                        {
                          c.currently_valid
                            ? c.is_main_bank_account && <Typography size="11">Hauptkonto</Typography>
                            : <Typography size="11">Nicht mehr gültig</Typography>
                        }
                      </Flex>
                    </Flex>
                    {
                      !c.currently_valid ? <Pill color="warning" text="Nicht mehr gültig" /> : null
                    }
                  </Flex>
                )}
              >
                <Flex fullWidth gap="3" style={{ borderLeft: "1px solid #03030322", paddingLeft: "10px" }}>
                  {renderCopyable(c.iban, "IBAN")}
                  {renderCopyable(c.bic, "BLZ")}
                </Flex>
              </Card>
            )
          }

          return (
            <Flex fullWidth gap="3">
              <Flex row fullWidth justify="between">
                <Typography bold color="primary">Bankverbindungen</Typography>
                {createDefaultToggle()}
              </Flex>
              {
                !hasData
                  ? <Typography>Keine Bankkonten hinterlegt.</Typography>
                  : (
                    <Flex row wrap align="start" fullWidth>
                      {
                        data.valid.map(renderItem)
                      }
                    </Flex>
                  )
              }
              {
                hasInactiveData && showExpired && (
                  <Flex fullWidth>
                    <Typography bold color="primary" size="12">Ältere Bankkonten</Typography>
                    <Flex row wrap align="start" fullWidth>
                      {
                        data.expired.map(renderItem)
                      }
                    </Flex>
                  </Flex>
                )
              }
            </Flex>
          )
        }
      }
    </DatevWithExpiredData>
  )
}