import React from "react";
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Card from "../card/Card";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import LoadingBar from "../loader/LoadingBar";
import LoadingSpinner from "../loader/LoadingSpinner";
import { AppColor } from "../../app/AppStyles";

export interface IChartData {
  value: number,
  name: string,
  fill?: string
}

export interface ITicketTeamStatisticProps {
  statistics: Array<IChartData>
}

export default function TicketStatisticPie({ statistics }: ITicketTeamStatisticProps) {

  const total = statistics?.length ? statistics.reduce((acc, curr) => acc + curr.value, 0) : 0;

  if (!total) return null;

  return (
    <ResponsiveContainer className="d-flex flex-column" height={250} >
      <PieChart width={1000} height={1000} >
        <Pie isAnimationActive={false} data={statistics} dataKey="value" legendType="line" nameKey="name" cx="50%" cy="50%" innerRadius={40} outerRadius={80} fill="#8884d8" />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}