import React from "react";
import { ticketEscalationWorkflowDelete } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import useTicketEscalation from "../../hooks/useTicketEscalation";
import { useTicketEscalationWorkflow } from "../../state/swr/escalation/useTicketEscalationWorkflows";
import { ITicketEscalationWorkflow } from "../../types/ApiTypes";
import { formatDuration } from "../../util/formatter";
import Button from "../buttons/Button";
import Card from "../card/Card";
import Flex from "../container/Flex";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import WithPermissions from "../permissions/WithPermissions";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import TicketEscalationActionChip from "./TicketEscalationActionChip";
import TicketEscalationWorkflowForm from "./TicketEscalationWorkflowForm";

export interface ITicketEscalationWorkflowItemProps {
    workflow: ITicketEscalationWorkflow,
    lastItem?: ITicketEscalationWorkflow
}

export default function TicketEscalationWorkflowItem({workflow, lastItem}: ITicketEscalationWorkflowItemProps) {

    const { getNameForTrigger } = useTicketEscalation();
    const { getStringified } = useTemplateUtil();
    const { reloadTicketEscalationWorkflow } = useTicketEscalationWorkflow();

    const callApi = useApi();
    
    const deleteTicketEscalationFlow = async () => {
        const res = await callApi(ticketEscalationWorkflowDelete(workflow._id));
        if (!res) return;
        await reloadTicketEscalationWorkflow();
    }   

    return (
        <WithPermissions permissions={["tenant.own.update"]}>
            <Card 
                color="muted"
                header={
                    <Flex row justify="between" className="w-100">
                        <Flex gap={0}>
                            <Flex row>
                                <Typography color="primary">Trigger: </Typography>
                                <Typography bold color="primary">{getNameForTrigger(workflow.trigger)}</Typography>
                            </Flex>
                            <Flex row>
                                <Typography color="primary">Nach : </Typography>
                                <Typography bold color="primary">
                                    {formatDuration(workflow.threshhold)} 
                                </Typography>
                            </Flex>
                        </Flex>
                        <TicketEscalationWorkflowForm workflowItem={workflow} lastItem={lastItem} />
                    </Flex>
                }
            >
                <Flex className="w-100">
                    <Flex>
                        <Typography bold>Aktionen</Typography>
                        <Flex row>
                            {
                                workflow.actions.map(a => <TicketEscalationActionChip canBeSelected action={a.action} readOnly isActive />)
                            }
                        </Flex>
                    </Flex>
                    {
                        workflow.mailTemplate && (
                            <Flex>
                                <Typography bold>Mail-Vorlage:</Typography>
                                <Pill color="muted">
                                    <Typography bold color="primary">{getStringified(workflow.mailTemplate.subject)}</Typography>
                                </Pill>
                            </Flex>
                                    
                        )
                    }
                </Flex>
                <Flex justify="between" fullWidth row>
                    <CheckmarkIcon 
                        value={workflow.marksTicketAsEscalated} 
                        trueLabel="Ticket wird als eskaliert markiert" 
                        falseLabel="Ticket wird nicht als eskaliert markiert" 
                    />
                    <Button 
                        onClick={deleteTicketEscalationFlow} 
                        type="button" 
                        text="Löschen" 
                        icon="trash" 
                        color="error"
                    />
                </Flex>
            </Card>
        </WithPermissions>

    )   
}