import { useUser } from "../state/swr/user/useUser";
import { IPermission, Permission } from "../types/ApiTypes";
import { IOfficeAccount, OfficeAccountType } from "../types/officeAccount.types";
import { getId } from "../util/mongoUtil";

export default function useInboxPermissions() {
    const {
        user
    } = useUser();

    const getInboxPermissions = (key: keyof IPermission, type: OfficeAccountType, matchThisUser?: string) => {
        const result: Array<Permission> = [
            `inboxes.all.${key}`
        ];

        if (matchThisUser && user && getId(user) === getId(matchThisUser)) result.push(`inboxes.own.${key}`);
        else if (type === OfficeAccountType.Tenant) result.push(`inboxes.tenant.${key}`);
        else if (type === OfficeAccountType.Team) result.push(`inboxes.teams.${key}`);

        return result;
    }

    const getAccountPermissions = (key: keyof IPermission, account: IOfficeAccount) => getInboxPermissions(key, account.type, account.assignments?.personalUser);

    return {
        getAccountPermissions,
        getInboxPermissions
    }
}