import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IDomain } from "../../../types/ApiTypes";

export const useTenantDomains = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IDomain>>(ApiRoutes.Tenant.CurrentTenantDomains);

    return {
        tenantDomains: data,
        loadingTenantDomains: isLoading,
        reloadTenantDomains: mutate
    }
}