import React from "react";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import SidebarHeader from "../../sidebar/SidebarHeader";
import Card from "../../card/Card";
import useUserUtil from "../../../hooks/useUserUtil";
import Typography from "../../text/Typography";
import UserRoleBanner from "../../user/RoleBanner";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import SidebarDivider from "../../sidebar/Divider";
import Divider from "../../sidebar/Divider";
import Button from "../../buttons/Button";
import useApi from "../../../hooks/useApi";
import { ticketAssignmentsRemove } from "../../../api/Api";
import { useTicketAssignee } from "../../../state/swr/tickets/assignments/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/assignments/useTicketClientContact";
import { useTicketAssignments } from "../../../state/swr/tickets/assignments/useTicketAssignments";
import UpdateTicketAssignmentButton from "./UpdateTicketAssignmentButton";
import UpdateTicketAssignmentForm from "./UpdateTicketAssignmentForm";
import ToggleAssignmentStatusButton from "./ToggleAssignmentStatusButton";
import DeleteTicketAssignmentButton from "./DeleteTicketAssignmentButton";

export interface ITicketAssignmentItemProps {
    assignment: ITicketAssignment,
    isNotMainAssignment: boolean,
    ticket: ITicket
}

export default function TicketAssignmentItem({assignment, isNotMainAssignment, ticket}: ITicketAssignmentItemProps) {

    const {
        getName,
        getCurrentTenantRole
    } = useUserUtil();

    const getTypeName = () => {
        if (assignment.isCc) return "Beobachter";
        if (assignment.isMainAssignee) return "Bearbeiter";
        if (assignment.isMainClientContact) return "Mandant";
    }

    const getColor = () => {
        if (assignment.isMainAssignee) return "primary";
        if (assignment.isMainClientContact) return "secondary";
        return "muted";
    }

    const role = getCurrentTenantRole(assignment.user);

    return (
        <Card
            color={getColor()}
            header={
                <Flex fullWidth justify="between" row >
                    <Typography basedOnThisBackground={getColor()}>
                        { 
                            assignment.user 
                            ? getName(assignment.user)                        
                            : assignment.mailAddress 
                        }     
                    </Typography>
                    <Typography basedOnThisBackground={getColor()}>{getTypeName()}</Typography>
                </Flex>
            }
        >
            <Flex fullWidth>
                <Flex row justify="between" fullWidth>
                    {
                        !assignment.isMainClientContact && <UserRoleBanner user={assignment.user} />
                    }
                    { assignment.client && <ClientTypeBanner showClientName client={assignment.client} />}
                    <DeleteTicketAssignmentButton 
                        canDelete={!!isNotMainAssignment || (!assignment.isMainAssignee && !assignment.isMainClientContact)} 
                        ticket={ticket} 
                        assignment={assignment} 
                    />
                </Flex>
                {
                    !assignment.isMainAssignee && !assignment.isMainClientContact && !!role && (
                        <>
                            <Divider height={1} opacity={0.45} />
                            <Typography basedOnThisBackground={getColor()} bold>Aktionen</Typography>
                            <Flex row wrap fullWidth justify="between">
                                <Flex row>
                                    <UpdateTicketAssignmentButton 
                                        assignment={assignment}
                                        icon="check"
                                        ticket={ticket}
                                        clientId={assignment.client ? assignment.client._id : undefined}
                                        userId={assignment.user!._id} 
                                        isMainAssignee={!role.isClient} 
                                        isMainClientContact={role.isClient} 
                                        color={role.isClient ? "bright" : "success"} 
                                        text={role.isClient ? "Als Mandant übernehmen" : "Als Bearbeiter übernehmen"} 
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )
                }
                
                {
                    assignment && (
                        <>
                            <Divider height={1} opacity={0.45} />
                            <Flex row justify="between" fullWidth>
                                <UpdateTicketAssignmentForm ticket={ticket} assignment={assignment} />
                                <ToggleAssignmentStatusButton ticket={ticket} assignment={assignment} />
                            </Flex>
                        </>
                    )
                }
            </Flex>
        </Card>
    )
}