import { Link, useNavigate } from "react-router-dom";
import "./Hyperlink.css";
import React, {PropsWithChildren} from "react";
import IElementProps from "../../types/element.types";
import { generateClassName } from "../../hooks/useAttributes";

interface IHyperlinkProps extends IElementProps {
    to: string,
    externalLink?: boolean,
    openInNewTab?: boolean
}

export default function Hyperlink({to, children, className, externalLink = false, openInNewTab = false}: IHyperlinkProps) {
    
    const navigate = useNavigate();

    if (!to) return null;

    const clickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!to) return;

        if (!externalLink) {
            navigate(to);
            e.preventDefault();
            return;
        }

        if (openInNewTab) {
            window.open(to, "_blank");
            e.preventDefault();
            return;
        }
        
        window.location.href = to;
        e.preventDefault();
    }

    const linkClass = generateClassName("hyperlink p-1", className);
    
    return <Link to={to} onClick={clickHandler} className={linkClass}>{children}</Link>
}
