import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IPointChampApiKey } from "../../../types/ApiTypes";

export const usePointChampApiKey = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IPointChampApiKey>(ApiRoutes.Integrations.PointChamp.GetPointChampApiKey);

    return {
        pointChampApiKey: data,
        loadingPointChampApiKey: isLoading,
        reloadPointChampApiKey: mutate
    }
}