import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { IClientImportLogEntry } from "../../../types/ApiTypes";

export const useLatestClientImportLog = (opts?: TiggiQueryOpts) => {
    const { data, isLoading, mutate } = useTiggiQuery<IClientImportLogEntry>(ApiRoutes.ClientsImportLog.Latest, opts);

    return {
        latestClientImportLog: data,
        loadingLatestClientImportLog: isLoading,
        reloadLatestClientImportLog: mutate
    }
}