import React from "react";
import { Permission } from "../types/ApiTypes";
import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import usePermissions from "./usePermissions";
import { useSession } from "../state/swr/session/useSession";
import { useUser } from "../state/swr/user/useUser";
import useUserUtil from "./useUserUtil";

export type PermissionCheckMode = "all" | "any";

export interface IAuthorizationOptions {
    permissions?: Array<Permission>,
    permissionMode?: PermissionCheckMode,
    superAdminOnly?: boolean
}

export default function useAuthorized(opts: IAuthorizationOptions = {
    permissionMode: "any"
}) {

    const {
        permissions,
        superAdminOnly,
        permissionMode
    } = opts;
    
    const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);

    const {
        hasPermissions
    } = usePermissions();

    const {
        user,
        loadingUser
    } = useUser();

    const {
        getAuthority
    } = useUserUtil();

    const {
        loading,
        session
    } = useSession();

    const {
        role,
        loadingRole
    } = useCurrentTenantRole();

    const checkAuthorized = () => {
        if (superAdminOnly) return !!user?.isSuperAdmin;
        if (!permissions) return true;
        if (!!user?.isSuperAdmin) return true;

        const auth = getAuthority(user);

        if (!!auth?.userAuthority?.isTenantAdmin) return true;

        if (!role || !role.permissions) return false;
        return hasPermissions(role.permissions, permissions, permissionMode ?? "any");   
    }

    React.useEffect(() => {
        const auth = checkAuthorized();
        setIsAuthorized(auth);
    }, [permissions, role, session, user, superAdminOnly])

    return {
        isAuthorized,
        isSuperAdmin: user?.isSuperAdmin,
        loading: loadingRole || loading || loadingUser
    }
}