import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { BlacklistType, IBlacklist } from "../../../types/blacklist.schema";
import { Sorted } from "../../../types/collection.types";

export const useTenantBlacklist = (opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IBlacklist, BlacklistType, IBlacklist[]>>(ApiRoutes.Blacklist.Tenant.All, opts);

  return {
    tenantBlacklist: data?.items ?? [],
    tenantBlacklistByType: data?.type,
    loadingTenantBlacklist: isLoading,
    reloadTenantBlacklist: mutate
  }
} 