import React from "react";
import "./ComboBoxItem.css";

export interface IComboBoxItemProps<T> {
  item: T,
  content: React.ReactNode,
  onHover?: () => void,
  onBlur?: () => void,
  onClick: () => void
}

export default function ComboBoxItem<T>({ item, onHover, content, onBlur, onClick }: IComboBoxItemProps<T>) {
  if (!content) return null;

  return (
    <div
      className="combo-box-item p-2 w-100 text-start"
      onMouseEnter={onHover}
      onMouseOver={onHover}
      onMouseLeave={onBlur}
      onClick={onClick}
    >
      <span className="w-100 h-100">
        {
          content
        }
      </span>
      <div className="combo-box-item-background" />
    </div>
  )
}