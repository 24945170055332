import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { IEmployeeResponsibility } from "../../../types/employeeResponsibility.schema";

export const useEmployeeResponsibilities = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IEmployeeResponsibility>>(ApiRoutes.Clients.Responsibilities.All);

    return {
        employeeResponsibilities: data?.items ?? [],
        employeeResponsibilitiesById: data?.byId ?? {},
        loadingEmployeeResponsibilities: isLoading,
        reloadEmployeeResponsibilities: mutate
    }
}