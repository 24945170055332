import React from "react";
import { useNavigate } from "react-router-dom";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import useTicketUtil from "../../../hooks/useTicketUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { useClientContacts } from "../../../state/swr/clientContacts/useClientContacts";
import { useClients } from "../../../state/swr/clients/useClients";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { IHydratedTicket } from "../../../types/ticket.schema";
import { formatDateTiggiStyle } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserRoleIndicator from "../../user/RoleBanner";
import TicketStateBanner from "../banner/TicketStateBanner";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import TicketMessagesCount from "../TicketMessagesCount";
import TicketCardHeader from "./TicketCardHeader";
import "./TicketOverviewItem.css";

export interface ITicketOverviewItem {
  hydratedTicket: IHydratedTicket,
  renderAsTableRow?: boolean,
  select?: React.ReactNode
}

export default function TicketOverviewItem({ hydratedTicket, select, renderAsTableRow }: ITicketOverviewItem) {

  const {
    ticket,
    assignee,
    client,
    clientContact
  } = hydratedTicket;

  const [openOnClick, setOpenOnClick] = React.useState<boolean>(true);
  const [hover, setHover] = React.useState<boolean>(false);

  const {
    query
  } = useFilteredTickets();

  const {
    clientsById
  } = useClients();

  const {
    clientContactsById
  } = useClientContacts();

  const {
    getName
  } = useUserUtil();

  const {
    getSubject,
    getDisplayId
  } = useTicketUtil();

  const { role } = useCurrentTenantRole();
  const { user } = useUser();

  const navigate = useNavigate();

  if (!ticket) return null;

  const isClient = !(role && !role.isClient) && !(!!user && user.isSuperAdmin);

  const isNew = () => {
    if (!ticket) return false;
    if (!ticket.hasMessageFromAnyEmployee) return true;
    return false;
  }

  const openTicket = async () => {
    if (!openOnClick) return;
    const url = `/tickets/${ticket._id}?${query.toString()}`;
    navigate(url);
  }

  const ticketClient = client && clientsById[client];
  const ticketClientContact = clientContact && clientContactsById[clientContact];

  const openedByClientName = ticketClient ? ticketClient.name : ticket.openedByMail;
  const openedByClientContactName = getName(ticketClientContact) || ticket.openedByName || ticket.openedByMail;
  const openedByName = openedByClientName !== openedByClientContactName ? openedByClientContactName : ticket.openedByMail !== openedByClientContactName ? undefined : "";

  if (renderAsTableRow) return (
    <TableRow color={isNew() ? "error" : undefined}>
      {select}
      <TableCell>
        <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
      </TableCell>
      <TableCell>
        <Flex gap="0">
          <Typography bold size="12" color="primary">{getDisplayId(ticket)}</Typography>
          {
            !isClient && <TicketTypeBanner ticket={ticket} />
          }
        </Flex>
      </TableCell>
      <TableCell>
        <Typography style={{ maxWidth: "300px" }} wrap className="w-100">{getSubject(ticket, !isClient)}</Typography>
      </TableCell>
      <TableCell>
        <Flex gap="0">
          {
            openedByClientName && <Typography bold >{openedByClientName}</Typography>
          }
          {
            openedByName && <Typography size={12}>{openedByName}</Typography>
          }
        </Flex>
      </TableCell>
      {
        !isClient && (
          <TableCell>
            <Flex gap={2} align="start" justify="end" >
              {
                assignee && <UserRoleIndicator user={assignee} displayNameAsValue showOnEmptyText="Kein Bearbeiter" />
              }
              {
                client && <ClientTypeBanner client={client} showClientName textOnEmpty="Kein Mandant" />
              }
              {
                ticket.team && <TeamBanner team={ticket.team} textOnEmpty="Kein Team" />
              }
            </Flex>
          </TableCell>
        )
      }
      <TableCell>
        <Flex align="center">
          <TicketMessagesCount ticket={ticket} />
        </Flex>
      </TableCell>
      <TableCell>
        <TicketStateBanner hydratedTicket={hydratedTicket} textOnly />
      </TableCell>

      <TableCell>
        <Button variant="subtle" onClick={openTicket}>Öffnen</Button>
      </TableCell>
    </TableRow>
  );

  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      color={isNew() ? "error" : "backgroundDarker"}
      noPadding
      strongColor={hover}
      header={(
        <TicketCardHeader
          ticket={ticket}
          hover={hover}
          onHoverOverAction={() => setOpenOnClick(false)}
          onStopHoverOverAction={() => setOpenOnClick(true)}
        />
      )}
      wrapperClass="gap-2 w-100 h-100"
      disableHoverStyles
      onClick={openTicket}
    >
      <Flex fullWidth fullHeight className="p-0" gap={4} >
        <Flex gap={1} fullWidth className="p-3 overflow-hidden">
          <Typography size={20} color="primary" bold>{getSubject(ticket, !isClient, 40)}</Typography>
          <Flex row justify="between" className="w-100">
            <Flex row wrap className="w-100" gap="2">
              <Typography size={14} bold color="primary" >{openedByClientName ?? "Kein Mandant"}</Typography>
              <Typography size={14} color="primary">{openedByName ?? "Kein Kontakt"}</Typography>
            </Flex>
            <Icon size={20} icon="chevron-right" color="primary" />
          </Flex>
        </Flex>
        <Flex row wrap fullWidth fullHeight className="ps-3 pb-3" style={{ height: "fit-content" }} align="start" justify="between" >
          {
            !isClient && (
              <Flex align="start" justify="end" >
                {
                  assignee && <UserRoleIndicator user={assignee} displayNameAsValue showOnEmptyText="Kein Bearbeiter" />
                }
                {
                  client && <ClientTypeBanner client={client} showClientName textOnEmpty="Kein Mandant" />
                }
                {
                  ticket.team && <TeamBanner team={ticket.team} textOnEmpty="Kein Team" />
                }
              </Flex>

            )
          }
          <Flex justify="between" className="ms-auto" align="end">
            <TicketMessagesCount ticket={ticket} wrapperClass="pe-3" />
            <TicketStateBanner hydratedTicket={hydratedTicket} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
