import React from "react";
import { DatevDuplicateReason, DatevDuplicateType, IAddressee } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export interface IAddresseeDuplicateTypePillProps {
    addressee: IAddressee
}

export default function AddresseeDuplicateTypePill(props: IAddresseeDuplicateTypePillProps) {
    
    const {
        addressee
    } = props;

    const getColor = () => {
        const isProvenDuplicate = addressee.duplicateType === DatevDuplicateType.Duplicate;
        return isProvenDuplicate ? "#FB0" : "#FEB";
    }

    const getText = () => {
        const prefix = addressee.duplicateType === DatevDuplicateType.PossibleDuplicate ? "Mögliches " : "";
        
        switch (addressee.duplicateReason) {
            case DatevDuplicateReason.Mail: return `${prefix}Duplikat (E-Mail)`;
            case DatevDuplicateReason.Name: return `${prefix}Duplikat (Name)`;
        }
    }

    const getIcon = () => {
        switch (addressee.duplicateReason) {
            case DatevDuplicateReason.Mail: return "at";
            case DatevDuplicateReason.Name: return "person";
        }
    }

    if (!addressee.isDuplicate) return null;

    return (
        <Pill 
            color={getColor()} 
            className="gap-1" 
            icon={getIcon()}
            withDivider
            style={{fontSize: "0.9em"}}
            text={getText()}
        />
    )
}