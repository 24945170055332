import React from "react";
import { useUsers } from "../../state/swr/user/useUsers";
import { IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import UserListItem from "./UserListItem";

export default function UserList() {
    const { users, loadingUsers } = useUsers();

    if (loadingUsers) return <LoadingSpinner centered={false} />

    console.log(users);
    
    if (!users || !users.length) return <span>Keine Nutzer gefunden.</span>

    return (
        <Table 
            border 
            className="w-100" 
            headers={[{label: "Nutzer"}, {label: "Status"}, {label: "Globaler Administrator"}, {label: "Entwickler-Zugriff"}, {label: "Aktiv seit"}, {label: "Zuletzt aktualisiert"}, {label: ""}]}
            items={users}
            renderItem={(u: IUser) => <UserListItem key={u._id} user={u} />}
        />
    )
}