import React from "react";
import { AppColor } from "../../app/AppStyles";
import Pill from "./Pill";
import { hexWithOpacity } from "../../util/util";

export interface IBannerProps {
    text: string,
    color?: AppColor
}

export default function Banner({text, color = "primary"}: IBannerProps) {
    return (
        <Pill color={hexWithOpacity(color, 1, true)} text={text} textColor={color === "bright" ? "primary" : undefined} />
    )
}