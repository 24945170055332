import React from "react";
import { officeAccountDelete } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export default function RemoveOfficeAccountButton({account}: {account: IOfficeAccount}) {

    const {
        getAccountPermissions
    } = useInboxPermissions();

    const callApi = useApi();

    const permissions = getAccountPermissions("delete", account);

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                secondStepQuestion="Konto wirklich entfernen?"
                text="Konto entfernen" 
                color="error"
                onClick={async () => {
                    const res = await callApi(officeAccountDelete({id: account._id}));
    
                    if (!res || !res.success) return;
    
                    window.location.href = res.data.url;
                }}
                icon="trash"
            />
        </WithPermissions>
    )
}