import React, { ReactElement } from "react";
import Dialog from "./Dialog";

interface IModalDialogProps {
    button: ReactElement,
    sidebar?: React.ReactNode | ((closeForm: () => void) => React.ReactNode),
    onOpen?: (abortController: AbortController) => Promise<any>,
    title: string,
    children: React.ReactNode | ((closeForm: () => void) => React.ReactNode)
}

export default function ModalDialog({title, children, sidebar, onOpen, button}: IModalDialogProps) {
    
    const [visible, setVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!visible) return;
        if (!onOpen) return;

        const abortController = new AbortController();

        onOpen(abortController);

        return () => abortController.abort();
    }, [visible]);
    
    return (
        <>
            {
                React.cloneElement(button, { onClick: async () => setVisible(true) })
            }
            {
                visible && <Dialog setVisible={setVisible} title={title} children={children} sidebar={sidebar} />
            }
        </>
    )
}