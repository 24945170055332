import React from "react";
import Flex from "../container/Flex";
import "./SideNavElement.css"
import Icon from "../icons/Icon";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { IAppRoute } from "../../config/AppRoutes";

export interface ISideNavElementProps extends Omit<IAppRoute, "path"> {
  isInsideGroup?: boolean,
  isActive?: boolean
}

export default function SideNavElement(props: ISideNavElementProps) {

  const {
    icon,
    iconActive,
    isInsideGroup,
    title,
    isActive
  } = props;

  const elementClass = generateClassName("p-2 sidenav-element", {
    value: isInsideGroup,
    onTrue: "sidenav-element-inside-group"
  });

  const containerClass = generateClassName("sidenav-element-icon-container", {
    value: isInsideGroup,
    onTrue: "sidenav-element-icon-container-inside-group"
  })

  const usedIcon = isActive && iconActive ? iconActive : icon;

  return (
    <Flex row className={elementClass}>
      <Flex className={containerClass} align="center" >
        {
          usedIcon && <Icon icon={usedIcon} size={isInsideGroup ? 22 : 27} className="sidenav-element-icon" />
        }
      </Flex>
      {
        title && (
          <span
            className="sidenav-element-label"
            style={generateStyle({
              name: "fontSize",
              value: isInsideGroup ? 14 : 18,
              unit: "px"
            })}
          >
            {title}
          </span>
        )
      }
    </Flex>
  )

}