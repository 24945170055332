import { NoInfer, UndefinedInitialDataOptions, useMutation, useMutationState, useQuery, useQueryClient } from "@tanstack/react-query";

export interface IMutateOpts {
    revalidate?: boolean
}

export type TiggiQueryOpts<T = any> = Omit<UndefinedInitialDataOptions<T>, "queryKey">;

export default function useTiggiQuery<ResultT = any>(key: string | null, options: TiggiQueryOpts<ResultT> = {}) {
    
    const queryClient = useQueryClient();

    const queryKey = [ key ?? "" ];
    
    const queryOpts: UndefinedInitialDataOptions<ResultT> = {
        ...options,
        queryKey: queryKey
    };

    const {
        data,
        isLoading,
        isRefetching,
        refetch
    } = useQuery<ResultT>(queryOpts);

    const mutate = async (updateFn?: (prev: NoInfer<ResultT> | undefined) => ResultT | undefined | null, opts: IMutateOpts = { revalidate: false }) => {

        const {
            revalidate
        } = opts;

        if (updateFn) {
            queryClient.setQueryData<ResultT>(queryKey, old => (updateFn(old) ?? undefined));
            if (!revalidate) return;
            return;
        }
        
        queryClient.invalidateQueries({ queryKey: queryKey });
        await refetch();
    }

    return {
        data,
        isLoading: isLoading,
        isValidating: isLoading,
        mutate: mutate,
    }
}