import React from "react";
import { formatDate } from "../../../util/formatter";
import Button from "../../buttons/Button";

export interface IWithExpiryDate {
  valid_to: string,
  valid_from: string
}

export interface IDatevWithExpiredDataProps<T extends IWithExpiryDate> {
  data: T[],
  children: (
      checkExpired: (x: T) => boolean, 
      getValidityRange: (x: T) => string, 
      expiredVisible: boolean, 
      toggleExpired: () => void,
      sortedData: { expired: T[], valid: T[] },
      hasData: boolean,
      hasActiveData: boolean,
      hasInactiveData: boolean,
      createDefaultToggle: () => React.ReactElement | null
    ) => React.ReactElement
}

export default function DatevWithExpiredData<T extends IWithExpiryDate>(props: IDatevWithExpiredDataProps<T>) {

  const {
    data,
    children
  } = props;

  const [expiredVisible, setExpiredVisible] = React.useState<boolean>(false);

  const isExpired = (x: IWithExpiryDate) => {
    const now = new Date();
    const validTo = new Date(x.valid_to);
    return validTo < now;
  }

  const getValidityRange = (x: IWithExpiryDate) => {
    let result = "";
    
    if (x.valid_from) result += `${formatDate(x.valid_from)} - `;
    else result += "Gültig bis ";

    if (x.valid_to) result += formatDate(x.valid_to);

    return result;
  }

  const sorted = data?.reduce((acc, x) => {
    if (isExpired(x)) acc.expired.push(x);
    else acc.valid.push(x);
    return acc;
  }, { expired: [] as T[], valid: [] as T[] });

  const hasActiveData = sorted.valid.length > 0;
  const hasInactiveData = sorted.expired.length > 0;
  const hasData = hasActiveData || hasInactiveData;

  const createDefaultToggle = () => {
    if (!hasInactiveData) return null;

    return (
      <Button
        icon={expiredVisible ? "dash" : "plus"}
        size="small"
        variant="subtle"
        text={`${sorted.expired.length} ` + (expiredVisible ? "Ältere verbergen" : "Ältere anzeigen")}
        onClick={() => setExpiredVisible(!expiredVisible)}
      />
    )
  }

  return children(
    isExpired, 
    getValidityRange, 
    expiredVisible, 
    () => setExpiredVisible(!expiredVisible), 
    sorted,
    hasData,
    hasActiveData,
    hasInactiveData,
    createDefaultToggle
  );
}