import { DbId, IMongooseDocument } from "../types/ApiTypes";

export const getModel = <T extends IMongooseDocument>(id: DbId<T>, collection: {[key: string]: T}): T | undefined => {
    if (!id) return undefined;
    if (typeof id === "string") return collection[id];
    return id;
}

export const getId = (id: DbId<IMongooseDocument> | undefined) => {
    if (!id) return "";
    if (typeof id === "string") return id;
    return id._id;
}