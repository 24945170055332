import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IClientImportLog } from "../../../types/clientImportLog.schema";

export const useClientImportLog = (id?: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IClientImportLog>(ApiRoutes.ClientsImportLog.GetById.getValue({
        param: RouteParam.ImportLogId,
        value: id ?? ""
    }), { enabled: !!id });

    return {
        clientImportLog: data,
        loadingClientImportLog: isLoading,
        reloadClientImportLog: mutate
    }
}