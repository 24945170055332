import React from "react";
import { useNavigate } from "react-router-dom";
import { ModalType } from "../../config/ModalTypes";
import useAuthorized, { IAuthorizationOptions } from "../../hooks/useAuthorized";
import useModal from "../../hooks/useModal";
import { Permission } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";

export interface IWithPermissionProps extends IAuthorizationOptions {
    children: any,
    redirect?: boolean,
}

export default function WithPermissions(props: IWithPermissionProps) {

    const {
        redirect = false, 
        children
    } = props;

    const {
        isAuthorized,
        loading
    } = useAuthorized(props);

    const [visible, setVisible] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const showModal = useModal();

    React.useEffect(() => {
        if (loading) return;
        if (isAuthorized) return;
        if (!redirect) return;

        showModal({ text: 'Sie haben keine Berechtigung für diese Seite.', type: ModalType.Error });
        navigate(-1);
    }, [isAuthorized, loading, children]);

    React.useEffect(() => {
        setVisible(isAuthorized);
    }, [isAuthorized]);

    if (loading) return <LoadingSpinner />
    if (!isAuthorized || !visible) return null;

    return children;
}