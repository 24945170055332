import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ILogEntry } from "../../../types/log.schema";

export const useLogs = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ILogEntry>>(ApiRoutes.Logs);

    return {
        logs: data,
        loadingLogs: isLoading,
        reloadLogs: mutate
    }
}