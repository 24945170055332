import React from "react";
import { IWithAddressee } from "../../../types/addressee.schema";
import { DatevAddresseeCommunicationType, IDatevAddresseeCommunication } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import Pill from "../../pills/Pill";
import Typography from "../../text/Typography";
import useModal from "../../../hooks/useModal";
import { ModalType } from "../../../config/ModalTypes";

export default function AddresseeCommunications(props: IWithAddressee) {

  const {
    addressee
  } = props;

  const showModal = useModal();

  const getLabel = (c: DatevAddresseeCommunicationType) => {
    switch (c) {
      case DatevAddresseeCommunicationType.Email: return "E-Mails";
      case DatevAddresseeCommunicationType.Fax: return "Fax";
      case DatevAddresseeCommunicationType.Phone: return "Telefon";
      case DatevAddresseeCommunicationType.Url: return "Webseiten";
      default: return "Sonstige Kommunikation";
    }
  }

  const getIcon = (c: IDatevAddresseeCommunication) => {
    switch (c.type) {
      case DatevAddresseeCommunicationType.Email: return "envelope";
      case DatevAddresseeCommunicationType.Fax: return "voicemail";
      case DatevAddresseeCommunicationType.Phone: return "phone";
      case DatevAddresseeCommunicationType.Url: return "link";
      default: return "postcard";
    }
  }

  const onClick = (c: IDatevAddresseeCommunication) => {
    navigator.clipboard.writeText(c.data_content);

    switch (c.type) {
      case DatevAddresseeCommunicationType.Url:
        const link = c.data_content.startsWith("http") ? c.data_content : `https://${c.data_content}`;
        window.open(link, "_blank");
        break;

      case DatevAddresseeCommunicationType.Email:
        window.open(`mailto:${c.data_content}`, "_blank");
        break;

      default:

        showModal({ text: `${c.data_content} in die Zwischenablage kopiert`, type: ModalType.Information });
    }
  }

  let hasCommunication = false;

  const communication: { [key in DatevAddresseeCommunicationType]: IDatevAddresseeCommunication[] } = addressee.communications?.reduce((p, a) => {
    if (!p[a.type]) p[a.type] = [];
    hasCommunication = true;
    p[a.type].push(a);
    return p;
  }, {} as any);

  return (
    <Flex fullWidth>
      <Typography bold color="primary">Kommunikation</Typography>
      {
        hasCommunication
          ? Object.values(DatevAddresseeCommunicationType).map(k => {

            if (!communication[k]?.length) return null;

            return (
              <Flex fullWidth key={`${k}`}>
                <Typography size="12" bold color="primary">{getLabel(k)}</Typography>
                <Flex row fullWidth wrap>
                  {
                    communication[k].map(c => {
                      const color = c.is_main_communication ? "backgroundDarker" : "backgroundLighter";

                      return (
                        <Pill
                          hoverIcon="clipboard"
                          iconColor={c.is_main_communication ? "primary" : undefined}
                          key={c._id}
                          withDivider
                          dontShowLoading
                          onClick={() => onClick(c)}
                          icon={getIcon(c)}
                          color={color}
                        >
                          <Flex gap="0">
                            {c.note && <Typography bold={c.is_main_communication} >{c.note}</Typography>}
                            <Typography bold={c.is_main_communication && !c.note} size={c.note ? "11" : undefined}>{c.data_content}</Typography>
                          </Flex>
                        </Pill>
                      )
                    })
                  }
                </Flex>
              </Flex>
            )
          })
          : <Typography>Keine Kommunikationswege hinterlegt</Typography>
      }
    </Flex>
  )
}