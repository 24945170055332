import React from "react";
import Flex from "../components/container/Flex";
import Icon from "../components/icons/Icon";
import PageHeader from "../components/label/PageHeader";
import TopNav from "../components/menu/TopNav";
import Typography from "../components/text/Typography";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../hooks/useAttributes";
import { setRightSidebarExpanded } from "../state/slices/sidebarSlice";
import IElementProps from "../types/element.types";
import { getTextColorFromBackground } from "../util/util";
import "./Page.css";

export interface PageProps extends IElementProps {
    fullscreen?: boolean,
    colored?: boolean,
    background?: string,
    logoAsBackground?: boolean,
    widerSidebar?: boolean,
    displayCondition?: boolean,
    header?: React.ReactNode | Array<React.ReactNode>,
    showBackButton?: boolean,
    actionButtons?: React.ReactNode | Array<React.ReactNode>,
    rightSidebar?: React.ReactNode,
    centered?: boolean,
    limitMaxHeight?: boolean
}

export default function Page({fullscreen, limitMaxHeight = true, colored, widerSidebar, centered, showBackButton, actionButtons, rightSidebar, children, className, header}: PageProps) {
    
    const {
        rightSidebarExpanded
    } = useAppSelector(state => state.sidebar);

    const dispatch = useAppDispatch();

    const containerClass = generateClassName({
        value: fullscreen,
        onTrue: "vw-100 vh-100 position-fixed top-0 start-0",
        standard: "page-container w-100 vh-100 position-relative"
    }, {
        value: !rightSidebar && !fullscreen,
        base: "page-container-not-fullscreen-sidebar-",
        onTrue: "none",
        standard: "visible"
    });

    const pageClass = generateClassName("page h-100 d-flex flex-row w-100 position-relative", {
        value: fullscreen, 
        onTrue: "page-fullscreen"
    }, {
        value: colored,
        onTrue: "page-colored"
    });

    const pageStyle = generateStyle({
        name: "color",
        value: colored && fullscreen ? getTextColorFromBackground("primary") : undefined
    });

    const sidebarContainerClass = generateClassName("page-right-sidebar d-flex flex-row align-items-center h-100", {
        base: "page-right-sidebar-",
        value: rightSidebarExpanded,
        onTrue: "expanded",
        standard: "collapsed"
    }, {
        value: widerSidebar,
        base: "page-right-sidebar-",
        onTrue: "wide",
        standard: "narrow"
    });

    const sidebarClass = generateClassName("d-flex flex-column w-100 page-right-sidebar-content", {
        base: "page-right-sidebar-content-",
        value: rightSidebarExpanded,
        onTrue: widerSidebar ? "wide-expanded" : "narrow-expanded",
        standard: "collapsed"
    })

    return (
        <div className={containerClass}>
            {
                !fullscreen && <TopNav />
            }
            <div className={pageClass} style={pageStyle}>
                <div className={generateClassName("page-content gap-2", className, {
                        value: centered,
                        onTrue: "d-flex flex-column justify-content-center align-items-center"
                    }, {
                        value: !!rightSidebar && rightSidebarExpanded,
                        standard: "page-content-sidebar-hidden w-100",
                        onTrue: "page-content-sidebar-visible",
                    }, {
                        value: "fullscreen",
                        standard: "pb-5"
                    }, {
                        value: !!rightSidebar,
                        onTrue: "page-content-with-sidebar"
                    }, {
                        value: limitMaxHeight,
                        onTrue: "page-content-limited"
                    })}
                >
                    {
                        (header || actionButtons) && (
                            <div className="d-flex flex-row align-items-center flex-wrap w-100 justify-content-between mb-4">
                                {
                                    typeof header === "string"
                                    ? (
                                        <>
                                            <PageHeader text={header} showBackButton={showBackButton} />
                                            { 
                                                actionButtons && (
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex flex-row align-items-center gap-2">
                                                            {actionButtons}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                    : header
                                }
                            </div>
                        )
                    }
                    {children}
                </div>
                {
                    rightSidebar && (
                        <div className={sidebarContainerClass}>
                            <div className="w-100 h-100 position-relative d-flex flex-row">
                                <div className="d-flex flex-column page-right-sidebar-toggle h-100" onClick={() => dispatch(setRightSidebarExpanded(!rightSidebarExpanded))}>
                                    <div className="d-flex flex-column align-items-center gap-2" >
                                        <Icon icon={rightSidebarExpanded ? "x" : "list"} size={28} color="primary"/>
                                        <Flex gap={0} align="center">
                                            <Typography bold color="primary">S</Typography>
                                            <Typography bold color="primary">I</Typography>
                                            <Typography bold color="primary">D</Typography>
                                            <Typography bold color="primary">E</Typography>
                                            <Typography bold color="primary">B</Typography>
                                            <Typography bold color="primary">A</Typography>
                                            <Typography bold color="primary">R</Typography>
                                        </Flex>
                                    </div>
                                </div>
                                {
                                    rightSidebarExpanded && (
                                        <div className={sidebarClass} >
                                            {
                                            rightSidebar
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}