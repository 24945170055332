export const PLACEHOLDER_WRAP = "";

export enum TemplatePlaceholder {
    UserFirstName = "%{[UserFirstName]}%",
    UserTitle = "%{[UserTitle]}%",
    UserAdditionalPrefix = "%{[UserAdditionalPrefix]}%",
    UserLastName = "%{[UserLastName]}%",
    TicketLink = "%{[TicketLink]}%",
    ClientName = "%{[ClientName]}%",
    TicketId = "%{[TicketId]}%",
    TicketSubject = "%{[TicketSubject]}%",
    TaskSubject = "%{[TaskSubject]}%",
    TicketMessage = "%{[TicketMessage]}%",
    SenderFirstName = "%{[SenderFirstName]}%",
    SenderLastName = "%{[SenderLastName]}%",
    TenantName = "%{[TenantName]}%",
    DocumentClearanceLink = "%{[DocumentClearanceLink]}%",
    TicketResubmissionAt = "%{[TicketResubmissionAt]}%"
}

export const AllPlaceholders: Array<TemplatePlaceholder> = Object.values(TemplatePlaceholder);
