import { io } from "socket.io-client";
import { ApiUrl } from "../util/envVars";

const socketUrl = ApiUrl.replace("/v1", "");

const TiggiSocket = io(socketUrl, { 
    transports: ["websocket"],
    autoConnect: false
});

export default TiggiSocket;