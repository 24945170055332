import React from "react";
import { IUploadableFile } from "../../types/ApiTypes";
import Button, { IButtonProps } from "../buttons/Button";
import useModalNotifications from "../../hooks/useModalNotifications";
import { formatFileSize } from "../../util/formatter";
import { ModalType } from "../../config/ModalTypes";

export interface IFileSelectButtonPropsBase extends IButtonProps {
    maxBytes?: number
}

export interface IFileSelectButtonProps extends IFileSelectButtonPropsBase {
    values: Array<IUploadableFile>,
    onChange: (files: Array<IUploadableFile>) => void,
    accept: string,
    removeFileTypePrefix?: boolean,
    dragTarget?: React.RefObject<HTMLDivElement>
}

export default function FileSelectButton(props: IFileSelectButtonProps) {

    const {
        accept,
        values,
        maxBytes,
        onChange,
        disabled,
        dragTarget,
        removeFileTypePrefix
    } = props;

    const [canSelect, setCanSelect] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const showNotifications = useModalNotifications();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const resetValue = () => {
        if (!inputRef || !inputRef.current) return;
        inputRef.current.value = "";
    }

    const preventDefaults = (e: any) => {
        if (!e) return;
        if (e.preventDefault) e.preventDefault();
        if (e.stopPropagation) e.stopPropagation();
    }

    const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (!reader.result) return null;
            const r = typeof reader.result === "string" ? reader.result : reader.result.toString();

            if (removeFileTypePrefix) resolve(r.split(",")[1]);
            else resolve(r);
        };
        reader.onerror = reject;
    });
    
    
    const makeFileUploadable = async (f: File): Promise<IUploadableFile | null> => {
        try {
            const content = await toBase64(f);

            if (!content) return null;

            return {
                name: f.name,
                size: f.size,
                type: f.type,
                content: content
            }
        }
        catch {
            return null;
        }
    };

    const inputClick = async (e: any) => {
        setLoading(true);

        try {
            preventDefaults(e);
    
            if (!e || !e.target || !e.target.files || !e.target.files.length) return;
    
            const files = e.target.files;
    
            if (!files || !files.length) return;
            
            const result: Array<IUploadableFile> = [];
            let bytes = 0;
            
            for (const f of files) {
                const uploadable = await makeFileUploadable(f);
                if (!uploadable) continue;
                result.push(uploadable);
                bytes += f.size;
            }

            if (!result || !result.length) return;

            if (!!maxBytes) {
                const existingSize = values.reduce((acc, v) => acc + v.size, 0);
                
                if (existingSize + bytes > maxBytes) {
                    showNotifications({
                        text: `Die Dateien sind zu groß. Die maximal erlaubte Größe beträgt ${formatFileSize(maxBytes)}.`,
                        type: ModalType.Error
                    });
                    return;
                }
            }

            const newAttachments = [...values];
            newAttachments.push(...result);

            onChange(newAttachments);
            resetValue();
        }
        finally {
            setLoading(false);
        }
    }

    const selectFile = async () => {
        if (!inputRef || !inputRef.current) return;
        resetValue();
        inputRef.current.click();
    }

    const handleDragDrop = (e: DragEvent) => {
        preventDefaults(e);

        if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length) return;

        inputClick({target: {files: e.dataTransfer.files }});
    }

    React.useEffect(() => {
        if (!inputRef || !inputRef.current) setCanSelect(false);
        else setCanSelect(true);
    }, []);

    React.useEffect(() => {
        if (!dragTarget || !dragTarget.current) return;

        const target = dragTarget.current;

        target.addEventListener("drop", handleDragDrop);

        return () => {
            target.removeEventListener("drop", handleDragDrop);
        }
    }, [dragTarget]);
    
    return (
        <>
            <Button 
                onClick={selectFile} 
                variant="subtle" 
                disabled={disabled || !canSelect} 
                loading={loading}
                color={props.color ?? "primary"} 
                className={props.className} 
                icon={props.icon} 
                text={props.text ?? "Dateien auswählen"} 
            />
            <input 
                ref={inputRef} 
                name="file-select-button-hiden-input" 
                accept={accept} 
                multiple 
                style={{
                    visibility: "hidden", 
                    display: "none"
                }} 
                type="file" 
                onChange={inputClick} 
            />
        </>
    )
}