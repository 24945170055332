import React from "react";
import Stripe from "stripe";
import Pill from "../pills/Pill";

export default function StripeBillingInterval({interval, textOnly}: {interval: Stripe.Price.Recurring.Interval, textOnly: boolean}) {
    
    const getText = () => {
        switch (interval) {
            case "day": return "Täglich";
            case "month": return "Monatlich";
            case "week": return "Wöchentlich";
            case "year": return "Jährlich";
        }
    }

    const text = getText();

    if (textOnly) return <>{text}</>;

    return (
        <Pill text={text} />
    )
}