import { IMongooseDocument } from "./ApiTypes";
import { ITemplateElement } from "./globalMailTemplates.schema";

export enum Gender {
    Female = "female",
    Male = "male",
    Diverse = "diverse",
    Unknown = "unknown"
}

export type SalutationMap = {[key in Gender]: Array<ITemplateElement>}

export interface ISalutationDocument extends SalutationMap {
    name: string,
    baseTemplate: string,
    useSameTemplateForAllGenders: boolean,
    templateForAllGenders: Array<ITemplateElement>,
    isGlobalDefault: boolean,
    isGlobalTemplate: boolean
}

export interface ISalutation extends ISalutationDocument, IMongooseDocument { }
