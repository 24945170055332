import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IUserStatistics } from "../../../types/ApiTypes";

export const useAllUserStatistics = () => {
  const { data, isLoading, mutate } = useTiggiQuery<Array<IUserStatistics>>(ApiRoutes.Statistics.AllUsers, { refetchOnWindowFocus: false });

  return {
    allUserStatistics: data,
    loadingAllUserStatistics: isLoading,
    reloadAllUserStatistics: mutate
  }
}