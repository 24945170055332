import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IHydratedTicket } from "../../../types/ticket.schema";
import { useTicketMessages } from "./messages/useTicketMessages";
import { useTicketsWithFilter } from "./useTicketsWithFilter";

export const useTicket = (id: string, deferLoading: boolean = false) => {

  const { data, isLoading, mutate, isValidating } = useTiggiQuery<IHydratedTicket>(
    ApiRoutes.Tickets.ById.getValue({ param: RouteParam.TicketId, value: id }),
    {
      refetchOnMount: !deferLoading,
      enabled: !deferLoading
    }
  );

  const { reloadMessages } = useTicketMessages(id, { enabled: false });

  const { reloadTickets } = useTicketsWithFilter(undefined, { enabled: false });

  const reloadTicket = async () => {
    await reloadMessages();
    await mutate(undefined, { revalidate: true });


    try {
      if (!data) return;

      reloadTickets(prev => {
        if (!data) return prev;
        if (!prev || !prev.length) return [data];

        const existingIndex = prev.findIndex(i => i._id === data._id);

        if (existingIndex === -1) return [...prev, data];

        const updatedTickets = [...prev];

        updatedTickets[existingIndex] = data;

        return updatedTickets;
      }, { revalidate: false });
    }
    catch { }
  }

  return {
    ticket: data,
    loadingTicket: isLoading,
    validatingTicket: isValidating,
    reloadTicket: mutate,
    reloadAndUpdateAllTickets: reloadTicket,
  }
}