import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import useAuthorized, { IAuthorizationOptions } from "../../hooks/useAuthorized";
import { Permission } from "../../types/ApiTypes";
import IElementProps from "../../types/element.types";
import { formatDate } from "../../util/formatter";
import Flex from "../container/Flex";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Icon from "../icons/Icon";
import "./TableHeader.css";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";

export enum TableDensity {
  Condensed = "condensed",
  Standard = "standard",
  Comfortable = "comfortable"
}

interface ITableHeaderProps extends IElementProps, IAuthorizationOptions {
  value: string,
  hidden?: boolean,
  searchBoxAlwaysVisible?: boolean,
  maxWidth?: number,
  canFilter?: boolean,
  onFilter?: (val: string) => void,
  fillAvailableSpace?: boolean,
  colSpan?: number,
  rowSpan?: number
}

export default function TableHeader(props: ITableHeaderProps) {

  const {
    searchBoxAlwaysVisible,
    maxWidth,
    superAdminOnly,
    permissions,
    hidden,
    onFilter,
    canFilter,
    className,
    colSpan,
    rowSpan,
    value,
  } = props;

  const [hover, setHover] = React.useState<boolean>(false);
  const [filterVisible, setFilterVisible] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>("");

  const cellClass = generateClassName("table-cell table-cell-density-header table-cell-header", className);

  if (hidden) return null;

  return (
    <WithPermissions permissions={permissions} superAdminOnly={superAdminOnly}>
      <th className={cellClass} colSpan={colSpan} rowSpan={rowSpan} style={{ minWidth: 0 }}>
        <Flex
          row
          gap="3"
          justify="between"
          fullWidth
          onMouseEnter={() => setHover(true)}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Typography bold wrap={false} truncate={false}>{value}</Typography>
          <Flex row style={{ height: "28px" }}>
            {
              (filterVisible || searchBoxAlwaysVisible) && onFilter && canFilter && (
                <input
                  name="table-filter"
                  className="text-input form-control w-100 table-filter"
                  placeholder="Suchen..."
                  onChange={e => {
                    setFilter(e.target.value);
                    onFilter(e.target.value);
                  }}
                  value={filter}
                />
              )
            }
            {
              (filter || (canFilter && onFilter)) && (
                <Icon
                  icon={filterVisible ? "x" : (filter ? "filter" : (hover ? "search" : ""))}
                  onClick={() => setFilterVisible(!filterVisible)}
                />
              )
            }
          </Flex>
        </Flex>
      </th>
    </WithPermissions>
  );
}