import { DbId, ITicket, TicketSource, TicketState, TicketType } from "../types/ApiTypes";
import { ITicketMessage } from "../types/ticketMessage.schema";

export default function useTicketUtil() {

  const getSubject = (ticket?: ITicket, canUseInternalSubject: boolean = false, maxLength: number = -1) => {
    let subject = ticket?.subject?.trim();
    if (canUseInternalSubject && !subject) subject = ticket?.internalSubject?.trim();
    if (!subject) return "[Kein Betreff]";

    if (maxLength > 0 && subject.length > maxLength) {
      return subject.substring(0, maxLength) + "...";
    }

    return subject;
  }

  const getTicketTypeName = (type: TicketType): string => {
    switch (type) {
      case TicketType.InternalTicket: return "Intern";
      case TicketType.TicketByClient: return "Von Mandant";
      case TicketType.TicketToClient: return "An Mandant";
      case TicketType.ExternalTicket: return "Extern";
    }
  }

  const getTicketTypeColor = (type: TicketType): string => {
    switch (type) {
      case TicketType.InternalTicket: return "#F5C5C5";
      case TicketType.TicketByClient: return "#595BD0";
      case TicketType.TicketToClient: return "#BADFF8";
      case TicketType.ExternalTicket: return "#FFA500";
    }
  }

  const isClosed = (ticket: ITicket) => ticket.state === TicketState.Archived || ticket.state === TicketState.Closed || ticket.state === TicketState.Solved;

  const getUnreadMessages = (ticket: ITicket): Array<ITicketMessage> => {
    // const unreadMessages = ticket && ticket.messages && !!ticket.messages.length && ticket.messages.filter(m => {
    //     const isOwn = typeof m.sentBy === "string" ? m.sentBy === user?._id : (m.sentBy?._id === user?._id || m.sentBy?.mailAddress === user?.mailAddress);
    //     if (isOwn) return false;
    //     return m && !m.isRead;
    // });

    // if (!unreadMessages) return [];

    // return unreadMessages;

    return [];
  }

  const getTicketSourceName = (ticket: ITicket) => {
    if (!ticket) return "";
    if (!ticket.source) return "";

    switch (ticket.source) {
      case TicketSource.Android: return "Android-App";
      case TicketSource.ManualFromMail: return "Manuell aus E-Mail";
      case TicketSource.Mail: return "E-Mail";
      case TicketSource.Web: return "Website";
      case TicketSource.iOS: return "iOS-App";
    }
  }

  const getDisplayId = (ticket: DbId<ITicket>) => {
    if (!ticket) return "";
    if (typeof ticket === "string") return "Ticket";
    if (!ticket.friendlyId) return "";
    return `# ${ticket.friendlyId.replace("[", "").replace("]", "").replace("3T-", "")}`;
  }

  return {
    getSubject,
    getDisplayId,
    getTicketSourceName,
    isClosed,
    getTicketTypeColor,
    getTicketTypeName,
    getUnreadMessages
  }
}
