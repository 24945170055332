import React from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useDocumentClearanceReminders } from "../../state/swr/documentClearance/useDocumentClearanceReminders";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";
import { IDocumentClearanceReminder, IDocumentClearanceSettings, IUser } from "../../types/ApiTypes";
import PageLoader from "../loader/PageLoader";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher, { ITabData } from "../tabswitcher/TabSwitcher";
import DocumentClearanceMonthOverview from "./DocumentClearanceMonthOverview";
import "./DocumentClearanceOverview.css";
import DocumentClearanceYearOverview from "./DocumentClearanceYearOverview";
import { useUsers } from "../../state/swr/user/useUsers";
import { getId } from "../../util/mongoUtil";

export interface ISortedDocumentClearanceNode {
    settings: IDocumentClearanceSettings,
    reminder: IDocumentClearanceReminder
}

export interface IDocumentClearanceMonth {
    month: number,
    data: Array<ISortedDocumentClearanceNode>
}

export interface IDocumentClearanceYear {
    year: number,
    months: Array<IDocumentClearanceMonth>
}

export default function DocumentClearanceOverview() {

    const [sortedDocumentClearances, setSortedDocumentClearances] = React.useState<Array<IDocumentClearanceYear>>([]);
    const [matchingData, setMatchingData] = React.useState<Array<IDocumentClearanceYear>>([]);
    const [yearsForTabs, setYearsForTabs] = React.useState<Array<ITabData>>([]);
    const [startOfOlderYears, setStartOfOlderYears] = React.useState<number>(0);
    const [activeYear, setActiveYear] = React.useState<string>();
    const [showClientsFor, setShowClientsFor] = React.useState<IUser>();

    const { user } = useUser();
    const { role } = useCurrentTenantRole();
    const { documentClearanceReminders, loadingDocumentClearanceReminders } = useDocumentClearanceReminders();

    const showOlderDataKey = "older";

    const sortReminders = (data: Array<IDocumentClearanceReminder>): Array<IDocumentClearanceYear> => {

        const result: Array<IDocumentClearanceYear> = [];

        for (const point of data) {
            let existingYearIndex = result.findIndex(r => r.year === point.year);

            if (existingYearIndex < 0) {
                result.push({months: [], year: point.year});
                existingYearIndex = result.length - 1;
            }
            
            let existingMonthIndex = result[existingYearIndex].months.findIndex(m => m.month === point.month);

            if (existingMonthIndex < 0) {
                result[existingYearIndex].months.push({
                    data: [{
                        reminder: point, 
                        settings: point.settings
                    }], 
                    month: point.month
                });
                continue;
            }
            
            result[existingYearIndex].months[existingMonthIndex].data.push({
                reminder: point, 
                settings: point.settings
            })
        }

        result.sort((a, b) => b.year - a.year);
        result.forEach(a => a.months.sort((a, b) => b.month - a.month));

        return result;
        
    }

    React.useEffect(() => {
        if (!documentClearanceReminders || !documentClearanceReminders.length) {
            setSortedDocumentClearances([]);
            return;
        }

        const sortedData = sortReminders(documentClearanceReminders);

        if (!sortedData || !sortedData.length) {
            setSortedDocumentClearances([]);
            return;
        }

        const yearsForTabs = sortedData.slice(0, sortedData.length >= 3 ? 3 : sortedData.length);
        const itemsForTabYears = yearsForTabs.map((x): ITabData => ({data: x.year.toString(), label: x.year.toString()}));

        setYearsForTabs([...itemsForTabYears, {data: "older", label: "Ältere Daten"}]);
        setStartOfOlderYears(yearsForTabs[yearsForTabs.length - 1].year);
        setSortedDocumentClearances(sortedData);

    }, [documentClearanceReminders]);

    React.useEffect(() => {

        if (!sortedDocumentClearances || !sortedDocumentClearances.length) {
            setMatchingData([]);
            return;
        }
        const matchingYears = sortedDocumentClearances.filter(a => activeYear === showOlderDataKey ? a.year < startOfOlderYears : a.year.toString() === activeYear);
        
        if (!matchingYears || !matchingYears.length) {
            setMatchingData([]);
            return;
        }

        if (!showClientsFor) {
            setMatchingData(matchingYears);
            return;
        }

        const matchingResponsibility: Array<IDocumentClearanceReminder> = [];

        for (const year of matchingYears) {
            if (!year || !year.months || !year.months.length) continue;

            for (const month of year.months) {
                if (!month.data || !month.data.length) continue;

                for (const d of month.data) {
                    if (!d || !d.settings || !d.settings.client) continue;
                    if (!d.settings.client.clientManager) continue;
                    if (!user) continue;

                    if (getId(d.settings.client.clientManager) !== getId(user)) continue;
                    matchingResponsibility.push(d.reminder);
                }
            }
        }

        const result = sortReminders(matchingResponsibility);

        if (!result || !result.length) {
            setMatchingData([]);
            return;
        }

        setMatchingData(result);

    }, [activeYear, documentClearanceReminders, sortedDocumentClearances, showClientsFor]);


    if (loadingDocumentClearanceReminders) return <PageLoader />

    if (!sortedDocumentClearances) return <span>Keine Einträge</span>;

    console.log("matchingData", matchingData);  
    console.log("sortedDocumentClearances", sortedDocumentClearances);  

    return (
        <WithPermissions permissions={[ "documentClearance.all.read", "documentClearance.own.read" ]}>
            <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <TabSwitcher 
                        tabQueryParamKey="show"
                        tabs={yearsForTabs}
                        saveActiveTab={val => setActiveYear(val)}
                    />
                    {
                        (!role || !role.isClient) && (
                            <div className="show-only-clients-for-current-user-button d-flex flex-row align-items-center justify-content-center ps-3 pe-3 p-2" onClick={() => setShowClientsFor(!!showClientsFor ? undefined : (user || undefined))}>
                                <strong>
                                    {
                                        showClientsFor ? "Meine Mandate" : "Alle Mandate"
                                    }
                                </strong>
                            </div>
                        )
                    }
                </div>
                <div className="d-flex flex-column gap-3">
                    {
                        matchingData?.length
                        ? (
                            activeYear === showOlderDataKey 
                            ? matchingData.map((b, index) => <DocumentClearanceYearOverview isExpandedInitially={index === 0} year={b} key={b.year} />)
                            : matchingData[0].months.map((a, index) => <DocumentClearanceMonthOverview key={a.month} isExpandedInitially={index === 0} month={a} />)
                        )
                        : <span>Keine Elemente</span>
                    }

                </div>
            </div>
        </WithPermissions>
    )
}