import React from "react";
import { ITicket, TicketSource } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import UserRoleIndicator from "../user/RoleBanner";
import UserAvatar from "../user/profile/UserAvatar";
import useUserUtil from "../../hooks/useUserUtil";
import { formatDateGmailStyle } from "../../util/formatter";
import Card from "../card/Card";
import TicketLink from "./TicketLink";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import TeamBanner from "../teams/TeamBanner";
import TicketCardHeader from "./lists/TicketCardHeader";
import { useNavigate } from "react-router-dom";
import useTicketActivityUtil from "../../hooks/useTicketActivityUtil";
import { ITicketActivity } from "../../types/ticketActivity.schema";
import TicketNotePreview from "./notes/TicketNotePreview";

export interface ITicketActivityItemProps {
  activity: ITicketActivity,
  showTicketLink?: boolean,
  ticket?: ITicket
}

export default function TicketActivityItem({ activity, ticket, showTicketLink = false }: ITicketActivityItemProps) {

  const {
    getName
  } = useUserUtil();

  const {
    getContent,
    getIcon
  } = useTicketActivityUtil();

  const navigate = useNavigate();

  return (
    <Card
      color="muted"
      onClick={showTicketLink ? () => navigate(`/tickets/${activity.ticket}`) : undefined}
      className="p-2 w-100"
      header={showTicketLink ? <TicketLink ticket={activity.ticket} /> : undefined}
    >
      <Flex row justify="between" align="start" className="w-100" gap={2}>
        <div className="position-relative">
          <UserAvatar user={activity.activityBy} mailAddress={activity.isSystemActivity ? "tiggi@tiggi.com" : (activity.activityByMail || "x@y")} size={40} />
          <div style={{ width: "20px", height: "20px", backgroundColor: "var(--backgroundLighter)", overflow: "hidden", border: "1px solid var(--backgroundDarkest)", top: "-5px", right: "-5px", borderRadius: "50px" }} className="position-absolute d-flex justify-content-center align-items-center">
            <Icon hexColor="var(--backgroundDarkest)" size={12} icon={getIcon(activity)} />
          </div>
        </div>
        <Flex gap={0} className="w-100 text-truncate text-break w-100" align="start" justify="start">
          <strong className="text-truncate text-break w-100" style={{ maxWidth: "250px" }}>
            {
              activity.isSystemActivity
                ? "SYSTEM"
                : (
                  activity.activityBy
                    ? getName(activity.activityBy)
                    : (activity.activityByMail || "Keine Mailadresse hinterlegt")
                )
            }
          </strong>
          <span className="text-wrap text-break">{getContent(activity, ticket)}</span>
          <Flex row wrap fullWidth>
            {
              activity.activityAffectsUser && <UserRoleIndicator user={activity.activityAffectsUser} displayNameAsValue />
            }
            {
              activity.activityAffectsClient && <ClientTypeBanner client={activity.activityAffectsClient} showClientName />
            }
            {
              activity.mergedIntoTicket && <TicketLink ticket={activity.mergedIntoTicket} />
            }
            {
              activity.activityAffectsTeam && <TeamBanner team={activity.activityAffectsTeam} />
            }
            {
              (activity.ticketNote && activity.ticket) && <TicketNotePreview noteId={activity.ticketNote} ticketId={activity.ticket} />
            }
          </Flex>
        </Flex>
        <Flex align="end">
          <span className="text-nowrap">{formatDateGmailStyle(activity.createdAt)}</span>
        </Flex>
      </Flex>
    </Card>
  )
}