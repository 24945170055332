import React from "react";
import { IPlan } from "../../types/ApiTypes";
import { formatCurrency } from "../../util/formatter";
import StripeBillingInterval from "../services/StripeBillingInterval";

export default function PlanPrice({plan}: {plan: IPlan}) {
    return (
        <div className="d-flex flex-column">
            <span>{formatCurrency(plan.price / 100)}</span>
            <strong style={{fontSize: "0.9em", marginTop: "-3px"}}>
                <StripeBillingInterval interval={plan.billingInterval} textOnly />   
            </strong>
        </div>
    )
}