import React from "react";
import { ticketsAssign } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { useTicketAssignee } from "../../../state/swr/tickets/assignments/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/assignments/useTicketClientContact";
import { ITicket, IUser, TicketState, TicketType } from "../../../types/ApiTypes";
import ClientEmployeeResponsibilityList from "../../clients/ClientEmployeeResponsibilityList";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import Hyperlink from "../../links/Hyperlink";
import LoadingSpinner from "../../loader/LoadingSpinner";
import SidebarHeader from "../../sidebar/SidebarHeader";
import Typography from "../../text/Typography";
import WithLabel from "../../text/WithLabel";
import UserRoleIndicator from "../../user/RoleBanner";
import AssignTicketToOtherTeam from "../assignment/AssignTicketToOtherTeam";
import AssignTicketToTeamMember from "../assignment/AssignTicketToTeamMember";
import EscalateTicketButton from "../assignment/EscalateTicketButton";
import TicketFriendlyIdBanner from "../banner/TicketFriendlyIdBanner";
import TicketOpeningReasonBanner from "../banner/TicketOpeningReasonBanner";
import TicketOriginBanner from "../banner/TicketOriginBanner";
import TicketSourceBanner from "../banner/TicketSourceBanner";
import CreateAliasFromTicketButton from "../CreateAliasFromTicketButton";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";
import TicketChatInternalSubjectField from "../ticketChat/TicketChatInternalSubjectField";
import { useTicketsWithFilter } from "../../../state/swr/tickets/useTicketsWithFilter";


export default function TicketGeneralInformation({ticket}: {ticket: ITicket}) {

    const { loadingTicketAssignee, reloadTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);
    const { loadingTicketClientContact, reloadTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);

    const callApi = useApi();
    const { reloadTickets } = useTicketsWithFilter();
    const { reloadTicket } = useTicket(ticket._id);


    const quickAssignmentOfTicket = async (user: IUser) => {
        await callApi(ticketsAssign({ ticketId: ticket._id, userId: user._id }));
        await reloadTicket();
        reloadTickets();
    }

    return (
        <Flex gap={3}>
            <TicketFriendlyIdBanner ticket={ticket} />
            <Flex>
                <TicketChatInternalSubjectField ticket={ticket} />
                <TicketSourceBanner ticket={ticket} />
                <TicketOriginBanner ticket={ticket} />
                <TicketOpeningReasonBanner ticket={ticket} />
            </Flex>
            <SidebarHeader text="Aktionen" divider="top" />
            <Flex>
                <AssignTicketToOtherTeam ticket={ticket} />
                <AssignTicketToTeamMember ticket={ticket} />
                <EscalateTicketButton ticket={ticket} />
            </Flex>
            <SidebarHeader text="Zuweisungen" divider="top" />
            <Flex fullWidth>
                <WithLabel gap={0} label="Mailversand an">
                    {
                        loadingTicketClientContact 
                        ? <LoadingSpinner />
                        : (
                            <Flex fullWidth row justify="between">
                                <Typography size={12} >
                                    {
                                        ticketClientContact?.mailAddress || "-"
                                    }
                                </Typography>
                                <CreateAliasFromTicketButton ticket={ticket} />
                            </Flex>
                        )
                    }
                </WithLabel>
                <WithLabel gap={0} label={ticket.type === TicketType.InternalTicket ? "Eröffnet von" : "Bearbeiter"}>
                    {
                        loadingTicketAssignee
                        ? <LoadingSpinner />
                        : (
                            ticketAssignee && ticketAssignee.user  
                            ? <UserRoleIndicator displayNameAsValue showMail user={ticketAssignee.user} />
                            : <span>-</span>
                        )
                    }
                </WithLabel>
                <WithLabel gap={0} label="Mandant">
                    {
                        loadingTicketClientContact
                        ? <LoadingSpinner />
                        : (
                            ticketClientContact && ticketClientContact.client
                            ? <ClientTypeBanner isLink showClientName client={ticketClientContact.client} />
                            : <span>-</span>
                        )
                    }
                </WithLabel>
                <WithLabel gap={0} label={ticket.type === TicketType.InternalTicket ? "An Mitarbeiter" : "Mandantenkontakt"}>
                    {
                        loadingTicketClientContact
                        ? <LoadingSpinner />
                        : (
                            ticketClientContact && ticketClientContact.user
                            ? <UserRoleIndicator showMail user={ticketClientContact.user} displayNameAsValue />
                            : <span>-</span>
                        )
                    }
                </WithLabel>
            </Flex>
            {
                ticketClientContact && ticketClientContact.user && (ticketClientContact.user.phoneNumber || ticketClientContact.user.mailAddress) && (
                    <>
                        <SidebarHeader text="Kontaktinformationen" divider="top" />
                        <Flex gap={1}>
                            {
                                ticketClientContact.user.phoneNumber && (
                                    <WithLabel gap={0} label="Telefonnummer">
                                        <Hyperlink externalLink to={`tel:${ticketClientContact.user.phoneNumber}`} >{ticketClientContact.user.phoneNumber}</Hyperlink>
                                    </WithLabel>
                                )
                            }
                            {
                                ticketClientContact.user.mailAddress && (
                                    <WithLabel gap={0} label="Mailadresse">
                                        <Hyperlink externalLink to={`mailto:${ticketClientContact.user.mailAddress}`}>{ticketClientContact.user.mailAddress}</Hyperlink>
                                    </WithLabel>
                                )
                            }
                        </Flex>
                    </>
                )                    
            }
            {
                ticketClientContact && ticketClientContact.client && (
                    <>
                        <SidebarHeader text="Schnellzuweisung" divider="top" />
                        <ClientEmployeeResponsibilityList onUserClick={quickAssignmentOfTicket} client={ticketClientContact.client} messageOnEmpty="Keine Verantwortungen für diesen Mandanten" />
                    </>
                )
            }
            <SidebarHeader text="Status" divider="top" />
            <Flex gap={0}>
                <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Open} variant="icon" />
                <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Resubmission} variant="icon" />
                <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Solved} variant="icon" />
                <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket isDelete variant="icon" />
            </Flex>
        </Flex>
    )
}