import React from "react";
import Page from "../layout/Page";
import CreateTicketForm from "../components/ticketing/CreateTicketModal";
import PageHeader from "../components/label/PageHeader";
import ProductivityOverview from "../components/dashboard/ProductivityOverview";
import { useUser } from "../state/swr/user/useUser";
import NotificationList from "../components/notifications/NotificationList";

export default function Dashboard() {
  const { user } = useUser();

  const getGreetingBasedOnTimeOfDay = () => {
    const timeOfDay = new Date();

    const hours = timeOfDay.getHours();

    if (hours >= 6 && hours < 12) return "Guten Morgen";
    else if (hours >= 12 && hours < 18) return "Guten Tag";
    else if (hours >= 18 && hours < 22) return "Guten Abend";
    else return "Gute Nacht";
  }


  return (
    <Page rightSidebar={<NotificationList />} limitMaxHeight={false} actionButtons={<CreateTicketForm />}>
      <div style={{ overflowY: "auto" }}>
        <PageHeader text={user && user.firstName ? `${getGreetingBasedOnTimeOfDay()}, ${user.firstName}!` : "Guten Morgen!"} />
        <ProductivityOverview />
      </div>
    </Page>
  )
}