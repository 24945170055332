import React from "react";
import { IWithAddressee } from "../../../types/addressee.schema";
import { DatevAddresseeAddressType, IDatevAddresseeAddress } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import CopyToClipboardButton from "../../buttons/CopyToClipboardButton";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import DatevWithExpiredData from "../../datev/data/DatevWithExpiredData";
import Icon from "../../icons/Icon";
import Typography from "../../text/Typography";

export default function AddresseeAddresses(props: IWithAddressee) {

  const {
    addressee
  } = props;

  const getTypeIcon = (a: IDatevAddresseeAddress) => {
    switch (a.type) {
      case DatevAddresseeAddressType.CorporateClient: return "buildings";
      case DatevAddresseeAddressType.PostOfficeBox: return "mailbox";
      case DatevAddresseeAddressType.Street: return "geo-alt";
    }
  }

  return (
    <DatevWithExpiredData
      data={addressee.addresses}
    >
      {
        (checkExpired, getValidityRange, showExpired, toggleExpired, data, hasData, hasActiveData, hasInactiveData, createDefaultToggle) => {
          const getTitle = (a: IDatevAddresseeAddress) => {
            if (a.is_management_address) return "Sitz der Geschäftsleitung";
            else if (a.is_correspondence_address) return "Korrespondenzadresse";
            else if (checkExpired(a)) return "Ältere Anschrift";
            return "Weitere Anschrift";
          }

          const getFlags = (a: IDatevAddresseeAddress) => {
            const flags = [];
            const isMain = a.is_main_post_office_box_address || a.is_main_street_address;

            if (checkExpired(a)) flags.push(getValidityRange(a));
            else if (a.is_correspondence_address) flags.push("Korrespondenzadresse");
            else if (isMain) flags.push("Primäre Anschrift");
            if (a.is_debitor_address) flags.push("Debitoradresse");

            return flags;
          }


          const renderAddress = (a: IDatevAddresseeAddress, i: number) => {
            const isHighlighted = a.is_correspondence_address || a.is_debitor_address || a.is_management_address;

            return (
              <Card
                key={a._id + i}
                fitContent
                strongColor={isHighlighted}
                color={isHighlighted ? "backgroundDarker" : "background"}
                header={(
                  <Flex row justify="between" fullWidth gap="5">
                    <Flex row>
                      <Icon icon={getTypeIcon(a)} color={isHighlighted ? "primary" : undefined} />
                      <Flex gap="0">
                        <Typography bold={isHighlighted} >{getTitle(a)}</Typography>
                        <Typography size="11">{getFlags(a).join(", ")}</Typography>
                      </Flex>
                    </Flex>
                    <CopyToClipboardButton value={a.address_manually_edited} />
                  </Flex>
                )}
              >
                <Flex row align="end" justify="between" fullWidth>
                  <Flex fullWidth gap="0" style={{ borderLeft: "1px solid #03030322", paddingLeft: "10px" }}>
                    {a.additional_correspondence_title && <Typography>{a.additional_correspondence_title}</Typography>}
                    {a.additional_delivery_text1 && <Typography>{a.additional_delivery_text1}</Typography>}
                    {a.additional_delivery_text2 && <Typography>{a.additional_delivery_text2}</Typography>}
                    {a.post_office_box && <Typography>{a.post_office_box}</Typography>}
                    {a.address_appendix && <Typography>{a.address_appendix}</Typography>}
                    <Typography>{a.street}</Typography>
                    <Typography>{a.country_code !== "DE" ? `${a.country_code}-` : ""}{a.postal_code} {a.city}</Typography>
                  </Flex>
                </Flex>
                {
                  a.note && <Typography>Notiz: {a.note}</Typography>
                }
              </Card>
            )
          }

          return (

            <Flex fullWidth gap="3">
              <Flex row justify="between" fullWidth>
                <Typography bold color="primary">Anschrift</Typography>
                { createDefaultToggle() }
              </Flex>
              {
                !hasData
                  ? <Typography>Keine Adressen hinterlegt.</Typography>
                  : (
                    <Flex row wrap align="start" fullWidth>
                      {
                        data.valid.map(renderAddress)
                      }
                    </Flex>
                  )
              }
              {
                hasInactiveData && showExpired && (
                  <Flex fullWidth>
                    <Typography bold color="primary" size="12">Ältere Adressen</Typography>
                    <Flex row wrap align="start" fullWidth>
                      {
                        data.expired.map(renderAddress)
                      }
                    </Flex>
                  </Flex>
                )
              }
            </Flex>
          )
        }
      }
    </DatevWithExpiredData>
  )
}