import React from "react";
import { aliasDelete } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import Button from "../buttons/Button";
import WithPermissions from "../permissions/WithPermissions";

export interface IDeleteAliasButtonProps {
  mutate: () => Promise<any>,
  aliasId: string
}

export default function DeleteAliasButton(props: IDeleteAliasButtonProps) {

  const {
    aliasId,
    mutate
  } = props;

  const callApi = useApi();

  return (
    <WithPermissions permissions={["alias.all.delete"]}>
      <Button
        size="small"
        icon="trash"
        variant="subtle"
        onClick={async () => {
          await callApi(aliasDelete({ id: aliasId }));
          await mutate();
        }}
        secondStepQuestion="Sicher?"
        text="Löschen"
        color="error"
      />
    </WithPermissions>
  )
}