import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { officeAccountsDeleteMail } from "../../../../api/Api";
import Icon from "../../../icons/Icon";
import { IOfficeAccount } from "../../../../types/officeAccount.types";

export interface IDeleteMailButtonProps {
    mail: Message, 
    account: IOfficeAccount,
    afterDelete: () => void
}

export default function DeleteMailButton({mail, afterDelete, account}: IDeleteMailButtonProps) {
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const deleteMail = async () => {
        setLoading(true);
        const res = await officeAccountsDeleteMail({id: account._id, messageId: mail.id!});
        if (res && res.success) afterDelete();
        setLoading(false);
    }

    if (!mail || !mail.id) return null;

    return <Icon icon="trash" onClick={deleteMail} loading={loading} />;
}