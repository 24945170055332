import React from "react";
import AddresseeList from "../../components/addressee/AddresseeList";
import WithPermissions from "../../components/permissions/WithPermissions";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";

export default function AddresseeManagement() {
    return (
        <WithPermissions permissions={[ "addressees.all.read" ]}>
            <Page header={Locale.titles.addresseeManagement}>
                <AddresseeList />
            </Page>
        </WithPermissions>
    )
}