import React, { ReactNode } from "react";

export interface IClickableProps {
    onClick: () => (any | Promise<any>),
    className?: string,
    children: (loading: boolean) => ReactNode
}

export default function Clickable({onClick, className, children}: IClickableProps) {

    const [loading, setLoading] = React.useState<boolean>(false);

    const clickHandler = async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
    }

    return (
        <div onClick={clickHandler} className={className} >
            {children(loading)}
        </div>
    )
}

