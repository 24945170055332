import React from "react";
import TenantOnboardingWizard from "../../components/tenant/onboarding/TenantOnboardingWizard";
import Page from "../../layout/Page";
import ElementsErrorBoundary from "../../components/tenant/onboarding/elements/ElementsErrorBoundary";

export default function TenantOnboarding() {
    return (
        <Page fullscreen >
            <ElementsErrorBoundary>
                {
                    error => <TenantOnboardingWizard hasPaymentError={error} />
                }
            </ElementsErrorBoundary>
        </Page>
    )
}