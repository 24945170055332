import React from "react";
import { ticketMessagesDownloadAttachments } from "../../../../api/Api";
import { } from "../../../../api/ApiRequests";
import useApi from "../../../../hooks/useApi";
import useDownloads from "../../../../hooks/useDownloads";
import { IAttachment } from "../../../../types/ApiTypes";
import MailAttachment from "../../../mail/MailAttachment";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export interface ITicketChatMessageAttachmentProps {
  attachment: IAttachment,
  message: ITicketMessage,
  fullWidth?: boolean,
  size?: "small" | "regular"
}

export default function TicketChatMessageAttachment({ attachment, message, fullWidth, size = "small" }: ITicketChatMessageAttachmentProps) {

  const {
    downloadFile
  } = useDownloads();

  const callApi = useApi();

  const clickHandler = async () => {
    const res = await callApi(ticketMessagesDownloadAttachments({ attachments: [attachment], id: message._id }));
    if (res && res.success) downloadFile(attachment, res.data.content);
  }

  return (
    <MailAttachment
      hoverIcon="download"
      onClick={clickHandler}
      size="small"
      attachment={attachment}
    />
  )
}