import { ErrorMessage, Field, FieldAttributes, Form } from "formik";
import { generateClassName } from "../../hooks/useAttributes";
import React from "react";
import IElementProps from "../../types/element.types";
import Logo from "../logo/Logo";
import "./FormWithTitle.css";
import Flex from "../container/Flex";

interface IFormWithTitleProps extends IElementProps {
    title?: string,
    description?: string,
    withLogo?: boolean
}

export default function FormWithTitle({description, withLogo = true, title, children, className}: IFormWithTitleProps ) {
    
    const formClass = generateClassName("form-with-title d-flex flex-column card position-relative p-3 w-25 text-center", className, {
        value: withLogo,
        onTrue: "mt-3"
    });

    return (
        <>
            {
                withLogo && <Logo />
            }
            <Form className={formClass}>
                <Flex>
                    {
                        title && <h6 className="fw-bold">{title}</h6>
                    }
                    {
                        description && <p style={{fontSize: "0.9em"}}>{description}</p>
                    }
                    {
                        children
                    }
                </Flex>
            </Form>
        </>
    )
}