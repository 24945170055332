import { IMongooseDocument } from "./ApiTypes";
import { TemplatePlaceholder } from "./templatePlaceholder.schema";

export enum MailTemplate {
    TaskDue = "taskDue",
    TicketCreated = "ticketCreated",
    TicketEscalated = "ticketEscalated",
    TicketAssigned = "ticketAssigned",
    TicketClosed = "ticketClosed",
    TicketSolved = "ticketSolved",
    TicketReopened = "ticketReopened",
    TicketDeleted = "ticketDeleted",
    TicketMessage = "ticketMessage",
    TicketUpdated = "ticketUpdated",
    TicketSetToResubmission = "ticketSetToResubmission",
    TicketPdf = "ticketPdf",
    TicketMention = "ticketMention",
    TicketEscalationMailToSupervisor = "ticketEscalationMailToSupervisor",
    TicketEscalationMailToClient = "ticketEscalationMailToClient",
    DocumentClearanceReminderWithLink = "documentClearanceReminderWithLink",
    NewEmployeeInvitation = "newEmployeeInvitation",
    DocumentClearanceReminderTextOnly = "documentClearanceReminderTextOnly"
}

export const AllMailTemplates: Array<MailTemplate> = Object.values(MailTemplate);

export enum TemplateElementType {
    Text = "text",
    Salutation = "salutation",
    Placeholder = "placeholder",
    Link = "link",
    Image = "image",
    LineBreak = "lineBreak",
    TicketAnswerPrompt = "ticketAnswerPrompt"
}

export interface ITemplateElement {
    placeholder: TemplatePlaceholder | undefined,
    content: string,
    fallbackOnPlaceholderUnavailable: string,
    type: TemplateElementType
}

export interface IMailTemplateContent {
    subject: Array<ITemplateElement>,
    body: Array<ITemplateElement>,
    templateType: MailTemplate
}

export interface IGlobalMailTemplateDocument extends IMailTemplateContent {
    availablePlaceholders: Array<TemplatePlaceholder>,
    canBeCustomized: boolean,
    isPublished: boolean,
}

export interface IGlobalMailTemplate extends IMongooseDocument, IGlobalMailTemplateDocument { }
