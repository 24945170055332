import React from "react";
import Button from "../buttons/Button";
import axios from "axios";
import useApi from "../../hooks/useApi";
import { datevDesktopClientDownload } from "../../api/Api";
import useDownloads from "../../hooks/useDownloads";
import useModal from "../../hooks/useModal";
import { ModalType } from "../../config/ModalTypes";
import WithPermissions from "../permissions/WithPermissions";

export default function DownloadDatevExporter({afterComplete}: {afterComplete?: () => void}) {
    
    const {
        downloadFile
    } = useDownloads();

    const callApi = useApi();
    const showModal = useModal();

    const downloadDatevClient = async () => {

        const content = await callApi(datevDesktopClientDownload());

        if (!content || !content.success) return;

        const file = content.data.data;

        if (!file || !file.length) {
            showModal({
                text: "Die heruntergeladene Datei ist korrupt. Bitte versuchen Sie es erneut.",
                type: ModalType.Error
            });
            return;
        }

        downloadFile({
            name: "tiggi DatevExporter.exe",
            size: file.length,
            type: "application/x-msdownload"
        }, file);

        if (afterComplete) afterComplete();
    }

    return (
        <WithPermissions permissions={["interfaces.datev.read"]}>
            <Button onClick={downloadDatevClient} text="Desktop-Client herunterladen" icon="download" />
        </WithPermissions>
    )
}