import { FileAttachment } from "@microsoft/microsoft-graph-types";
import React from "react";
import { officeAccountsGetAttachmentContent } from "../../../../api/Api";
import { } from "../../../../api/ApiRequests";
import useApi from "../../../../hooks/useApi";
import useDownloads from "../../../../hooks/useDownloads";
import { IFileMetadata } from "../../../../types/ApiTypes";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import MailAttachment, { IMailAttachmentProps } from "../../MailAttachment";

export interface IDownloadableAttachmentProps extends Omit<IMailAttachmentProps, "attachment"> {
    attachment: FileAttachment, 
    mailId: string, 
    account: IOfficeAccount
}

export default function DownloadableAttachment({attachment, account, mailId, size = "small"}: IDownloadableAttachmentProps) {
    
    const convertToFileAttachment = (): IFileMetadata => ({
        name: attachment.name!,
        size: attachment.size!,
        type: attachment.contentType!
    });

    const {
        downloadFile    
    } = useDownloads();

    const callApi = useApi();

    const clickHandler = async () => {
        const content = await callApi(officeAccountsGetAttachmentContent({attachmentId: attachment.id!, id: account._id, mailId: mailId}));
        if (!content || !content.success) return;
        downloadFile(convertToFileAttachment(), content.data.contentBytes);
    }

    return (
        <MailAttachment
            attachment={convertToFileAttachment()}
            hoverIcon="download"
            onClick={clickHandler}
            size={size}
        />
    );
}