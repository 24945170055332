import { IDatevDocument, IMongooseDocument } from "./ApiTypes";

export enum DatevClientType {
  NaturalPerson = "natural_person",
  IndividualEnterprise = "individual_enterprise",
  LegalPerson = "legal_person"
}

export enum DatevStatus {
  Active = "active",
  Inactive = "inactive"
}

export interface IDatevClient {
  client_since: string,
  client_to: string,
  differing_name: string,
  legal_person_id: string,
  name: string,
  natural_person_id: string,
  note: string,
  number: number,
  status: DatevStatus,
  timestamp: number,
  type: DatevClientType,
  organization_id: string,
  organization_name: string,
  organization_number: number,
  establishment_id: string,
  establishment_name: string,
  establishment_number: number,
  establishment_short_name: string,
  functional_area_id: string,
  functional_area_name: string,
  functional_area_short_name: string,
  id: string
}

export interface IClientSettings {
  mails: {
    defaultSalutation: string,
    templateForDocumentClearance: string
  }
}

export interface IClient extends IDatevClient, IMongooseDocument, IDatevDocument {
  clientManager?: string,
  naturalPerson: string,
  legalPerson: string,
  relationships: Array<string>,
  settings: IClientSettings
}
