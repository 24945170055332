import { configureStore } from "@reduxjs/toolkit";
import ModalSlice from "./slices/modalSlice";
import NotificationSlice from "./slices/notificationSlice";
import SidebarSlice from "./slices/sidebarSlice";
import VersionSlice from "./slices/versionSlice";

const store = configureStore({
    reducer: {
        modal: ModalSlice,
        sidebar: SidebarSlice,
        version: VersionSlice,
        notifications: NotificationSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;