import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITicket, ITicketAccessHistory } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketAccessHistory = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketAccessHistory>(ApiRoutes.Tickets.Access.ById.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketAccessHistory: data,
        loadingTicketAccessHistory: isLoading,
        reloadTicketAccessHistory: mutate
    }
}