import React from "react";
import { useSession } from "../../state/swr/session/useSession";
import { useUser } from "../../state/swr/user/useUser";
import useUserUtil from "../../hooks/useUserUtil";
import "./ImposterSessionIndicator.css";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import EndImpostersessionButton from "./EndImposterSessionButton";
import { generateClassName } from "../../hooks/useAttributes";
import Icon from "../icons/Icon";

export default function ImposterSessionIndicator() {

    const { session } = useSession();
    const { user } = useUser();
    const { getName } = useUserUtil();

    if (!session || !session.isImposterSession) return null;

    return (
        <div className="imposter-session-user-indicator d-flex flex-row align-items-center justify-content-between" >
            <Flex row align="center" justify="between" className="imposter-session-user-indicator-content">
                <Typography basedOnThisBackground="error">Angemeldet als <strong>{ getName(user) }</strong></Typography>
                <EndImpostersessionButton />
            </Flex>
        </div>
    )
}