import React, { MouseEventHandler } from "react";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import "./Icon.css";
import { getTextColorFromBackground } from "../../util/util";
import LoadingSpinner from "../loader/LoadingSpinner";
import Typography from "../text/Typography";
import Tooltip, { IWithTooltip } from "../tooltip/Tooltip";
import Flex from "../container/Flex";
import { boolean } from "yup";

interface IIconProps extends IElementProps, IWithTooltip {
  icon?: string,
  iconImage?: string,
  labelClass?: string,
  labelColor?: AppColor,
  label?: string,
  color?: AppColor,
  hexColor?: string,
  size?: number,
  loading?: boolean,
  invertThisColor?: string,
  wrapperClass?: string
}

export interface IIconTooltipOrigin {
  top: number,
  left: number,
}

export default function Icon({ icon, iconImage, labelColor, tooltipDelay, wrapperClass, size = 16, onClick, tooltip, tooltipTitle, label, color, invertThisColor, hexColor, style, className, labelClass, loading, tooltipAction }: IIconProps) {

  const [isLoading, setIsLoading] = React.useState<boolean>(!!loading);

  React.useEffect(() => {
    if (!!loading) {
      setIsLoading(true);
      return;
    }
    else setIsLoading(false);
  }, [loading]);

  const hoverTimeout = React.useRef<any>();

  const iconColor = hexColor ?? color ?? getTextColorFromBackground(invertThisColor);
  const iconColorIsVar = !invertThisColor && (!hexColor || !hexColor.includes("#"));

  const iconStyle = generateStyle({
    name: "fontSize",
    unit: "px",
    value: size
  }, {
    name: "lineHeight",
    unit: "px",
    value: size
  }, {
    name: "width",
    unit: "px",
    value: size
  }, {
    name: "height",
    unit: "px",
    value: size
  }, {
    value: iconColor,
    isVar: iconColorIsVar,
    name: "color"
  });

  const finalIconClass = generateClassName("icon", className, { base: "bi-", value: icon }, { value: !!onClick, onTrue: "icon-clickable" });

  const clickHandler = async () => {
    try {
      if (!onClick) return;
      setIsLoading(true);
      clearTimeout(hoverTimeout.current);
      await onClick();
    }
    catch { }
    finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <LoadingSpinner size={size} className={wrapperClass} color={color} hexColor={hexColor} />;
  if (!icon) return null;

  const labelClassName = generateClassName(labelClass, "icon-label text-nowrap");

  const iconComp = (
    iconImage
      ? <img src={iconImage} width={size} height={size} style={style ? { ...iconStyle, ...style } : iconStyle} />
      : <i className={finalIconClass} style={style ? { ...iconStyle, ...style } : iconStyle}></i>
  )

  return (
    <Tooltip
      tooltipDelay={tooltipDelay}
      tooltip={tooltip}
      tooltipAction={tooltipAction}
      tooltipTitle={tooltipTitle}
      className={wrapperClass}
    >
      <Flex row onClick={(onClick || tooltip) ? clickHandler : undefined} gap={1}>
        {
          loading
            ? <LoadingSpinner size={size} />
            : iconComp
        }
        {
          label && <Typography color={labelColor || color} className={labelClassName} size={12}>{label}</Typography>
        }
      </Flex>
    </Tooltip>
  )
}