import React from "react";
import useTiggySocket from "../hooks/useTiggiSocket";
import { useUser } from "../state/swr/user/useUser";

export default function TicketSocketConnector() {
    const { user } = useUser();

    const {
        connectToTicketSocket,
        removeTicketSocketListeners
    } = useTiggySocket();

    React.useEffect(() => {
        connectToTicketSocket();
        return () => removeTicketSocketListeners();
    }, [user]);
    
    return <></>
}