import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";
import { IMailLog } from "../../../../types/ApiTypes";

export const useTicketMessageMails = (ticketMessageId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IMailLog>>(ApiRoutes.TicketMessages.Mails.getValue({
        param: RouteParam.TicketMessageId,
        value: ticketMessageId
    }));

    return {
        ticketMessageMails: data,
        loadingTicketMessageMails: isLoading,
        reloadTicketMessageMails: mutate
    }
}