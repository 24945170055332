import React from "react";
import { useSalutations } from "../../state/swr/salutations/useSalutations";
import Flex from "../container/Flex";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Typography from "../text/Typography";

export interface ISalutationSelectProps {
    onChange: (salutation?: string) => void,
    value: string
}

export default function SalutationSelect(props: ISalutationSelectProps) {

    const {
        onChange,
        value
    } = props;

    const {
        loadingSalutations,
        salutations
    } = useSalutations();

    return (
        <Flex fullWidth gap="1">
            <SearchableComboBox 
                loading={loadingSalutations}
                values={salutations ?? []}
                itemToId={s => s._id}
                itemToString={s => s.name}
                onItemClick={s => onChange(s?._id)}
                value={value}
                label="Standard-Anrede für Mails"
            />
            {
                !value && (
                    <Typography size="12" color="primary">Es wird aktuell der eingestellte Systemstandard verwendet.</Typography>
                )
            }
        </Flex>
    )
}