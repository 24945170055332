import { Formik } from "formik";
import React from "react";
import { cdpGrantConsent } from "../../api/Api";
import { ICdpGrantConsentRequest } from "../../api/ApiRequests";
import { ApiRoutes } from "../../api/ApiRoutes";
import useApi from "../../hooks/useApi";
import { useCdp } from "../../state/swr/cdp/useCdp";
import { CdpAgreement } from "../../types/ApiTypes";
import { ApiUrl } from "../../util/envVars";
import { formatDate } from "../../util/formatter";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import Hyperlink from "../links/Hyperlink";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";

export default function GrantCdpConsent() {

  const { cdp, loadingCdp, reloadCdp } = useCdp();

  const callApi = useApi();
  const latestUrl = `${ApiUrl.replace("/v1/", "/public")}${ApiRoutes.ContractForDataPrivacy.Download}`;

  return (
    <WithPermissions permissions={["tenant.own.update"]}>
      {
        loadingCdp
          ? <LoadingSpinner />
          : (
            <Flex fullHeight fullWidth>
              <Flex fullHeight fullWidth>
                <Typography bold color="primary">Vertragsvorschau</Typography>
                <iframe
                  style={{ border: "1px solid var(--primary)", borderRadius: "var(--borderRadius)", overflowY: "auto", width: "40%", aspectRatio: "1/1.41" }}
                  src={`https://docs.google.com/gview?url=${latestUrl}&embedded=true`}
                />
                <Hyperlink to={latestUrl} externalLink openInNewTab className="align-self-end">
                  <Button text="Herunterladen" icon="download" />
                </Hyperlink>
              </Flex>
              <Flex fullWidth>
                {
                  cdp
                    ? (
                      <Flex>
                        <Typography color="primary" bold >Einverständnis</Typography>
                        <Typography>Der Datenverarbeitung wurde am {formatDate(cdp.consentedAt)} von {cdp.consentedByName} ({cdp.consentedByEmail}) zugestimmt.</Typography>
                      </Flex>
                    )
                    : (
                      <>
                        <Typography bold color="primary">Einverständnis erklären</Typography>
                        <Formik
                          initialValues={{
                            version: CdpAgreement.First
                          } as ICdpGrantConsentRequest}
                          enableReinitialize
                          onSubmit={async (values) => {
                            console.log("HELLO");
                            const res = await callApi(cdpGrantConsent(values));
                            if (!res || !res.success) return;
                            await reloadCdp();
                          }}
                        >
                          {
                            formik => (
                              <Flex fullWidth>
                                <Button type="submit" onClick={async () => formik.submitForm()} loading={formik.isSubmitting} icon="check" text="Einverständnis erklären" color="success" />
                              </Flex>
                            )
                          }
                        </Formik>
                      </>
                    )
                }
              </Flex>
            </Flex>
          )
      }
    </WithPermissions>
  )
}