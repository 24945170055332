import React from "react";
import { ITicket, TicketType } from "../../../types/ApiTypes";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import TicketAttachments from "./TicketAttachments";
import TicketGeneralInformation from "./TicketGeneralInformation";
import { AppQueryParams } from "../../../config/QueryParams";
import TicketMessageHistoryTab from "./TicketMessageHistoryTab";
import TicketActivity from "./TicketActivity";
import TicketMails from "./TicketMails";
import TicketAccess from "./TicketAccess";
import TicketEscalationSidebar from "./TicketEscalationSidebar";
import Flex from "../../container/Flex";
import TicketAssignees from "./TicketAssignees";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import TicketTasks from "./TicketTasks";
import { TicketChatSidebarTab } from "../../../hooks/useTicketSidebar";


export interface ITicketChatSidebarProps {
    ticket: ITicket
}

export default function TicketChatSidebar({ticket}: ITicketChatSidebarProps) {
    
    const {
        role
    } = useCurrentTenantRole();

    const [tab, setTab] = React.useState<string>(TicketChatSidebarTab.General);

    const saveTab = (t: string) => {
        setTab(t);
    }

    const getTab = () => {
        switch(tab) {
            case TicketChatSidebarTab.General: return <TicketGeneralInformation ticket={ticket} />
            case TicketChatSidebarTab.Attachments: return <TicketAttachments ticket={ticket} />
            case TicketChatSidebarTab.Escalation: return <TicketEscalationSidebar ticket={ticket} />
            case TicketChatSidebarTab.Mails: return <TicketMails ticket={ticket} />
            case TicketChatSidebarTab.AccessHistory: return <TicketAccess ticket={ticket} />
            case TicketChatSidebarTab.Tasks: return <TicketTasks ticket={ticket} />
            case TicketChatSidebarTab.History: return <TicketMessageHistoryTab ticket={ticket} />
            case TicketChatSidebarTab.Activity: return <TicketActivity ticket={ticket} />
            case TicketChatSidebarTab.Assignments: return <TicketAssignees ticket={ticket} />
        }
    }

    if (role && role.isClient) return null;
    
    return (
        <div className="h-100 w-100 d-flex flex-column gap-3">
            <TabSwitcher 
                gap={3}
                className="w-100"
                defaultIconSize={25}
                tabs={[
                    { iconTooltip: "Allgemein", data: TicketChatSidebarTab.General, icon: "ticket-fill" }, 
                    { iconTooltip: "Aktivität", data: TicketChatSidebarTab.Activity, icon: "arrow-left-right" },
                    { iconTooltip: "Zuweisungen", data: TicketChatSidebarTab.Assignments, icon: "person-fill" },
                    { iconTooltip: "Anhänge", data: TicketChatSidebarTab.Attachments, icon: "paperclip" },
                    { iconTooltip: "Aufgaben", data: TicketChatSidebarTab.Tasks, icon: "calendar-check"},
                    { iconTooltip: "Mails", data: TicketChatSidebarTab.Mails, icon: "envelope" },
                    { iconTooltip: "Zeiten", data: TicketChatSidebarTab.AccessHistory, icon: "clock" },
                    { iconTooltip: "Eskalation", data: TicketChatSidebarTab.Escalation, icon: "exclamation-triangle" },
                    { iconTooltip: "Verlauf", data: TicketChatSidebarTab.History, hidden: tab !== TicketChatSidebarTab.History, backColor: "#F5374A", icon: "clock-history" }
                ]} 
                saveActiveTab={saveTab} 
                tabQueryParamKey={AppQueryParams.SidebarTab} 
                variant="icons"
                align="end"
            />
            <div className="d-flex flex-column w-100 h-100 gap-3" style={{overflowY: "auto", overflowX: "hidden"}} >
                {
                    getTab()
                }
            </div>
        </div>
    )
}
