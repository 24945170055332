import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IUser } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";

export const useEmployees = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IUser>>(ApiRoutes.User.Employees);

    return {
        employees: data?.items ?? [],
        employeesById: data?.byId ?? {},
        loadingEmployees: isLoading,
        reloadEmployees: mutate
    }
}