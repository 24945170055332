import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUser } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useUsers = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IUser>>(ApiRoutes.User.All);
    
    return {
        users: data?.items ?? [],
        usersById: data?.byId ?? {},
        loadingUsers: isLoading,
        reloadUsers: mutate
    }
}