import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IClient } from "../../../types/client.schema";
import { useClients } from "../../swr/clients/useClients";

export const useClient = (id: string) => {

  const {
    clientsById
  } = useClients({ enabled: false });

  const { data, isLoading, mutate } = useTiggiQuery<IClient>(ApiRoutes.Clients.ById.getValue({ param: RouteParam.ClientId, value: id }));

  return {
    client: data ?? clientsById?.[id],
    loadingClient: isLoading,
    reloadClient: mutate
  }
}