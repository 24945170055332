import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavGroup, NavTab } from "../../types/nav.types";

export interface ISidebarSlice {
    isExpanded: boolean,
    isExpandedDesktop: boolean,
    rightSidebarExpanded: boolean,
    activeTab: NavTab,
    activeGroup: NavGroup
}

export const SidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        activeGroup: NavGroup.None,
        activeTab: NavTab.Dashboard,
        isExpanded: false,
        rightSidebarExpanded: false,
        isExpandedDesktop: true
    } as ISidebarSlice,
    reducers: {
        setSidebarExpandedDesktop: (state, action: PayloadAction<boolean>) => {
            state.isExpandedDesktop = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<NavTab>) => {
            state.activeTab = action.payload;
        },
        setActiveGroup: (state, action: PayloadAction<NavGroup>) => {
            state.activeGroup = action.payload;
        },
        setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
            state.isExpanded = action.payload;
        },
        setRightSidebarExpanded: (state, action: PayloadAction<boolean>) => {
            state.rightSidebarExpanded = action.payload;
        }
    }
});

export const { setActiveGroup, setActiveTab, setSidebarExpandedDesktop, setSidebarExpanded, setRightSidebarExpanded } = SidebarSlice.actions;

export default SidebarSlice.reducer;