import React from "react";
import Page from "../../layout/Page";
import MailLogOverview from "../../components/log/mail/MailLogOverview";
import { Locale } from "../../locale/Locale";
import RefreshMailLogButton from "../../components/log/mail/RefreshMailLogButton";

export default function MailLog() {
    return (
        <Page header={Locale.titles.mailLog} actionButtons={<RefreshMailLogButton />}>
            <MailLogOverview />
        </Page>
    )
}