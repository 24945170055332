import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import { ITemplateElement, TemplateElementType } from "../../types/globalMailTemplates.schema";
import { TemplatePlaceholder } from "../../types/templatePlaceholder.schema";
import Button from "../buttons/Button";
import Card from "../card/Card";
import Select from "../comboBox/Select";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import Icon from "../icons/Icon";
import Typography from "../text/Typography";
import MailTemplateEditElementPlaceholders from "./MailTemplateEditElementPlaceholders";

export interface ITemplateElementEditProps {
    name: string,
    readOnly?: boolean,
    label: React.ReactNode,
    availablePlaceholders?: TemplatePlaceholder[],
    availableTypes?: TemplateElementType[],
    onPlaceholderSelect?: (placeholder: TemplatePlaceholder) => void,
    onPlaceholderRemove?: (placeholder?: TemplatePlaceholder) => void
}

export const PlaceholderMatch = /%{\[([^\]]*)\]}%/g;

export default function TemplateElementEdit(props: ITemplateElementEditProps) {

    const {
        name, 
        label, 
        readOnly,
        availablePlaceholders, 
        availableTypes,
        onPlaceholderRemove, 
        onPlaceholderSelect
    } = props;

    const {
        getDisplayNameForTemplateType
    } = useTemplateUtil();

    const {
        values,
        setFieldValue
    } = useFormikContext<{ [key: string]: ITemplateElement[]}>();

    const usableTemplateTypes = availableTypes ?? [ 
        TemplateElementType.Text,
        TemplateElementType.Placeholder,
        TemplateElementType.Salutation,
        TemplateElementType.LineBreak, 
        TemplateElementType.TicketAnswerPrompt
    ];

    return (
        <FieldArray name={name}>
            {
                arrayHelpers => (
                    <Flex className="w-100">
                        <Flex row className="w-100" justify="between">
                            {
                                typeof label === "string" 
                                ? <Typography size={20} bold color="primary">{label}</Typography>
                                : label
                            }
                            {
                                !readOnly && (
                                    <Button 
                                        onClick={async () => arrayHelpers.push({ 
                                            content: "", 
                                            fallbackOnPlaceholderUnavailable: "", 
                                            placeholder: undefined,
                                            type: TemplateElementType.Text
                                        } as ITemplateElement)} 
                                        text="Neues Element" 
                                        icon="plus" 
                                    />
                                )
                            }
                        </Flex>
                        {
                            values[name] && values[name].length 
                            ? (
                                values[name].map((element: ITemplateElement, index: number) => {
                                    const saveCurrentElementPlaceholder = (placeholder: TemplatePlaceholder) => {
                                        setFieldValue(`${name}.${index}.placeholder`, placeholder);
                                        if (onPlaceholderSelect) onPlaceholderSelect(placeholder);
                                    }

                                    const removeCurrentElementPlaceholder = () => {
                                        setFieldValue(`${name}.${index}.placeholder`, undefined);
                                        if (onPlaceholderRemove) onPlaceholderRemove();
                                    }

                                    const moveItemUp = () => {
                                        if (index === 0) return;
                                        arrayHelpers.swap(index, index - 1);
                                    }

                                    const moveItemDown = () => {
                                        if (!values[name] || !values[name].length) return;
                                        if (index === values[name].length - 1) return;
                                        arrayHelpers.swap(index, index + 1);
                                    }

                                    const getValidationError = (): string | null => {
                                        switch (element.type) {
                                            case TemplateElementType.Text: 
                                                if (PlaceholderMatch.test(element.content)) return "Textblock darf keinen Platzhalter beinhalten.";
                                                return null;
                                            case TemplateElementType.Placeholder: 
                                                if (!element.content) return "Bitte wählen Sie einen Platzhalter aus!";
                                                return null;
                                        }

                                        return null;
                                    }

                                    const validationText = getValidationError();

                                    const canMoveUp = !readOnly && index !== 0;
                                    const canMoveDown = !readOnly && (values[name] && !!values[name].length && index !== values[name].length - 1);

                                    const getContent = () => {
                                        switch (element.type) {
                                            case TemplateElementType.Salutation: return (
                                                <Typography bold wrap color="primary">Wird automatisch gefüllt basierend auf Systemeinstellungen und individuellen Einstellungen für den Empfänger.</Typography>
                                            )

                                            case TemplateElementType.Text: return (
                                                <Flex className="w-100" gap={3} justify="between">
                                                    <FormikField 
                                                        className="w-100"
                                                        readOnly={readOnly}
                                                        label="Inhalt"
                                                        name={`${name}.${index}.content`}
                                                    />
                                                </Flex>
                                            )

                                            case TemplateElementType.Placeholder: 

                                                if (readOnly && !element.fallbackOnPlaceholderUnavailable) return null;

                                                return (
                                                    <Flex fullWidth>
                                                        {
                                                            !readOnly && (
                                                                <MailTemplateEditElementPlaceholders
                                                                    readOnly={readOnly}
                                                                    saveText={newVal => setFieldValue(`${name}.${index}.content`, newVal)}
                                                                    availablePlaceholders={availablePlaceholders}
                                                                    currentText={element.content}
                                                                    selectedPlaceholder={element.placeholder}
                                                                    onAdd={saveCurrentElementPlaceholder}
                                                                    onRemove={removeCurrentElementPlaceholder}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            element.placeholder && (!readOnly || !!element.fallbackOnPlaceholderUnavailable) && (
                                                                <FormikField
                                                                    readOnly={readOnly}
                                                                    className="w-100"
                                                                    label="Text bei nicht verfügbarem Platzhalter"
                                                                    placeholder="Ersatztext, falls der Platzhalter im Kontext der Mail nicht ersetzt werden kann..."
                                                                    name={`${name}.${index}.fallbackOnPlaceholderUnavailable`}
                                                                />
                                                            )
                                                        }
                                                    </Flex>
                                                )
                                        }
                                    }

                                    return (
                                        <Flex fullWidth row key={`template-element-${index}`}>
                                            {
                                                (canMoveUp || canMoveDown) && (
                                                    <Flex fullHeight>
                                                        {
                                                            canMoveUp && <Icon icon="chevron-up" onClick={moveItemUp} color="primary" />
                                                        }
                                                        {
                                                            canMoveDown && <Icon icon="chevron-down" onClick={moveItemDown} color="primary" />
                                                        }
                                                    </Flex>
                                                )
                                            }
                                            <Card 
                                                key={index} 
                                                color={validationText ? "error" : undefined}
                                                header={
                                                    <Flex row justify="between" align="start">
                                                        <Flex gap={1}>
                                                            <Flex row gap="2" fullWidth>
                                                                <Typography bold truncate={false} color={!!validationText ? "bright" : "muted"} upper>Typ</Typography>
                                                                <Select 
                                                                    readOnly={readOnly}
                                                                    color={!!validationText ? "bright" : "primary"}
                                                                    disabled={!!element.placeholder && element.type === TemplateElementType.Placeholder}
                                                                    values={usableTemplateTypes}
                                                                    getLabel={getDisplayNameForTemplateType}
                                                                    value={element.type}
                                                                    onChange={newVal => setFieldValue(`${name}.${index}.type`, newVal)}
                                                                />
                                                                {
                                                                    readOnly && element.type === TemplateElementType.Placeholder && (
                                                                        <MailTemplateEditElementPlaceholders
                                                                            readOnly
                                                                            saveText={newVal => setFieldValue(`${name}.${index}.content`, newVal)}
                                                                            availablePlaceholders={availablePlaceholders}
                                                                            currentText={element.content}
                                                                            selectedPlaceholder={element.placeholder}
                                                                            onAdd={saveCurrentElementPlaceholder}
                                                                            onRemove={removeCurrentElementPlaceholder}
                                                                        />
                                                                    )
                                                                }
                                                            </Flex>
                                                            {
                                                                !!validationText && <Typography size="12" bold basedOnThisBackground="error">{validationText}</Typography>
                                                            }
                                                        </Flex>
                                                        {
                                                            !readOnly && (
                                                                <Button 
                                                                    variant="text" 
                                                                    text="Entfernen" 
                                                                    icon="trash" 
                                                                    color={!validationText ? "error" : "bright"} 
                                                                    onClick={async () => arrayHelpers.remove(index)} 
                                                                />
                                                            )
                                                        }
                                                    </Flex>
                                                }
                                            >
                                                { getContent() }
                                            </Card>
                                        </Flex>
                                    )
                                })
                            )
                            : <em>Keine Elemente vorhanden</em>
                        }
                    </Flex>
                )
            }
        </FieldArray>
    )
}

