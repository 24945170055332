import React from "react";
import { MailLogSourceCreator, MailLogType, MailSource } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";

export default function MailLogCreatedByBanner({ creator }: { creator: MailLogSourceCreator }) {

  const getSourceName = () => {
    switch (creator) {
      case MailLogSourceCreator.Manual: return "Manuell";
      case MailLogSourceCreator.Schedule: return "Zeitplan";
    }
  }

  const getSourceColor = () => {
    switch (creator) {
      case MailLogSourceCreator.Manual: return "#00AA33";
      case MailLogSourceCreator.Schedule: return "#3300AA";
    }
  }

  return <Pill color={getSourceColor()} text={getSourceName()} />
}