import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours, ITicketStatistics } from "../../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";

export const useAnyUserTicketStatistics = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketStatistics>(ApiRoutes.Tickets.Statistics.ForUser.getValue({
        param: RouteParam.UserId,
        value: id
    }), { refetchOnWindowFocus: false });

    return {
        userTicketStatistics: data,
        loadingUserTicketStatistics: isLoading,
        reloadUserTicketStatistics: mutate
    }
}