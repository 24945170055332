import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { ReactNode } from "react";
import Button from "../buttons/Button";
import LoadingSpinner from "../loader/LoadingSpinner";
import Dialog from "./Dialog";

export interface IModalFormBase {
  className?: string,
  button?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  submitText?: string,
  submitIcon?: string,
  loading?: boolean,
}

interface IModalFormProps<T extends FormikValues> extends IModalFormBase {
  initialValues: T,
  closeAfterSubmit?: boolean,
  disabled?: boolean,
  submitDisabled?: boolean,
  enableReinitialize?: boolean,
  title: string | ((formik: FormikProps<T>) => string),
  onClose?: () => (Promise<any> | any),
  onOpen?: (abortController: AbortController) => Promise<any>,
  onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>,
  sidebar?: (formik: FormikProps<T>, close: Function) => ReactNode,
  children: (formik: FormikProps<T>, close: Function) => ReactNode
}

export default function ModalForm<T extends FormikValues>({ submitDisabled, onClose, enableReinitialize, submitText, submitIcon, loading, closeAfterSubmit = true, disabled, initialValues, title, children, className, sidebar, onOpen, button, onSubmit }: IModalFormProps<T>) {

  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!visible) return;
    if (!onOpen) return;

    const abortController = new AbortController();

    onOpen(abortController);

    return () => abortController.abort();
  }, [visible]);

  return (
    <>
      {
        button && React.cloneElement(button, { className, disabled, loading, onClick: () => setVisible(true) })
      }
      {
        visible && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={enableReinitialize}
            onSubmit={async (values, actions) => {
              const res = await onSubmit(values, actions)
              if (!res) return;
              if (!closeAfterSubmit) return;
              setVisible(false);
            }}
          >
            {
              formik => {

                const formTitle = typeof title === "function" ? title(formik) : title;

                return (
                  <Form className="text-start position-fixed bottom-0 end-0" style={{ zIndex: "999999999" }}>
                    <Dialog
                      canClose={!formik.dirty}
                      onClose={onClose}
                      sidebar={(
                        sidebar
                          ? close => sidebar(formik, close)
                          : <Button type="submit" disabled={submitDisabled || !formik.dirty} text={submitText || "Speichern"} color="success" loading={formik.isSubmitting} icon={submitIcon || "save"} />
                      )}
                      setVisible={setVisible}
                      title={formTitle}
                    >
                      {
                        close => loading ? <LoadingSpinner /> : children(formik, close)
                      }
                    </Dialog>
                  </Form>
                )
              }
            }
          </Formik>
        )
      }
    </>
  )
}