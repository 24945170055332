import React from "react";
import useFilteredUsers, { IWithFilteredUsers } from "../../hooks/useFilteredUsers";
import useUserUtil from "../../hooks/useUserUtil";
import { DbId, IUser } from "../../types/ApiTypes";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import UserRoleBanner from "./RoleBanner";

export interface IUserSelectProps extends IWithFilteredUsers {	
    onChange: (user: IUser | undefined) => void,
    idsToShow?: Array<DbId<IUser>>,
    value?: string,
    useDefaultValue?: boolean,
    className?: string,
    bold?: boolean,
    label?: string,
    placeholder?: string
}

export default function UserSelect({onChange, idsToShow, placeholder, useDefaultValue = true, label, bold, className, value, displayed = "all", showWithoutRole}: IUserSelectProps) {
   
    const {
        loading,
        users
    } = useFilteredUsers({ displayed, showWithoutRole, showTheseIds: idsToShow });

    const {
        getName
    } = useUserUtil();

    return (
        <SearchableComboBox 
            bold={bold}
            values={users}
            loading={loading}
            useDefaultValue={useDefaultValue}
            itemToId={(u) => u._id}
            renderItem={(u) => <UserRoleBanner className="w-100" displayNameAsValue user={u} showMail />}
            itemToString={(u) => u.mailAddress}
            filterFunction={(u, f) => {
                if (!u) return false;

                const mailIncludesValue = u.mailAddress.toUpperCase().includes(f);

                if (mailIncludesValue) return true;

                const name = getName(u);

                if (!name) return false;
                
                return name.toUpperCase().includes(f);
            }}
            renderValue={(u) => <UserRoleBanner displayNameAsValue user={u} showMail />}
            clearValue={() => onChange(undefined)}
            onItemClick={onChange}
            label={label}
            placeholder={placeholder || "Nutzer auswählen..."}
            value={value}

        />
    )

}