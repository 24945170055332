import React from "react";
import Button, { IButtonProps } from "./Button";
import "./CopyToClipboardButton.css";

interface ICopyToClipboardButtonProps extends IButtonProps{
  value: string
}

export default function CopyToClipboardButton(props: ICopyToClipboardButtonProps) {

  const {
    value
  } = props;

  const [copied, setCopied] = React.useState<boolean>(false);

  const cooldownTimer = React.useRef<any>(null);

  React.useEffect(() => {
    return () => clearTimeout(cooldownTimer.current);
  }, []);

  const copyToClipboard = () => {

    if (copied) return;

    clearTimeout(cooldownTimer.current);

    navigator.clipboard.writeText(value);
    setCopied(true);
    cooldownTimer.current = setTimeout(() => setCopied(false), 1000);
  }

  const icon = copied ? "check" : "layers";
  const text = copied ? "Kopiert" : "Kopieren";

  return (
    <Button
      onClick={copyToClipboard}
      readOnly={copied}
      icon={icon}
      iconPosition="start"
      color={copied ? "success" : props.color ?? "dark"}
      variant={props.variant ?? copied ? "solid" : "subtle"}
      size="small"
      text={text}
      {...props}
    />
  )
}