import { ISortedClientContact } from "../components/clientContacts/ClientContactList";
import { useAddressees } from "../state/swr/addressees/useAddressees";
import { useClientContacts } from "../state/swr/clientContacts/useClientContacts";
import { useLegalForms } from "../state/swr/legalForms/useLegalForms";
import { useUsers } from "../state/swr/user/useUsers";
import { DatevAddresseeType, DatevDuplicateReason, IAddressee, IDatevAddresseeAddress, IUser } from "../types/ApiTypes";
import { getId } from "../util/mongoUtil";

export default function useAddresseeUtil() {

    const { clientContacts } = useClientContacts();
    const { addressees } = useAddressees();
    const { legalFormsById, loadingLegalForms } = useLegalForms();
    
    const {
        usersById
    } = useUsers();

    const getAddresseeTypeColor = (a: IAddressee) => a.type === DatevAddresseeType.LegalPerson ? "#F0F0F0" : "#8383FF";
    const getAddresseeTypeIcon = (a: IAddressee) => a.type === DatevAddresseeType.LegalPerson ? "building" : "person";

    const addresseeIsProbablyEqual = (a: IAddressee, b: IAddressee) => {
        if (!a || !b) return false;

        if (a._id === b._id) return true;
        if (a.type !== b.type) return false;

        if (a.actualName !== b.actualName) return false;

        const addressA = getAddresseeAddress(a);
        const addressB = getAddresseeAddress(b);

        if (!addressA || !addressB) return true;
        
        return addressA.postal_code === addressB.postal_code;
    }

    const getAddresseeDuplicates = (a: IAddressee): Array<IAddressee> | null | undefined => {
        if (!addressees || !addressees.length) return null;
        if (!a || !a.isDuplicate) return null;

        return addressees.filter(b => {
            if (!b) return false;
            if (b._id === a._id) return false;
            if (!b.isDuplicate) return false;
            if (b.duplicateReason !== a.duplicateReason) return false;

            switch (a.duplicateReason) {
                case DatevDuplicateReason.Mail:

                    return b.originalMail === a.originalMail;
                
                default:

                    return b.actualName === a.actualName;
            }
        });
    }

    const getAddresseeAddress = (a: IAddressee): IDatevAddresseeAddress | undefined => {
        if (!a) return;
        if (!a.addresses || !a.addresses.length) return;
        
        const correspondenceAddress = a.addresses.find(a => a.is_correspondence_address);

        if (!!correspondenceAddress) return correspondenceAddress;

        const managementAddress = a.addresses.find(a => a.is_management_address);

        if (!!managementAddress) return managementAddress;

        const mainAddress = a.addresses.find(a => a.is_main_street_address);

        if (!!mainAddress) return mainAddress;
        
        return a.addresses[0];
    }

    const getAddresseeLegalForm = (a: IAddressee): string | null => {
        if (!a) return null;
        if (a.type === DatevAddresseeType.NaturalPerson) return "Natürliche Person";

        if (!a.legalForm) {
            if (a.type === DatevAddresseeType.LegalPerson) return "Rechtliche Einheit";
            else return null;
        }

        if (!legalFormsById) return "Rechtliche Einheit";

        const matchingLegalForm = legalFormsById[a.legalForm];

        if (!matchingLegalForm) return "Rechtliche Einheit";

        if (matchingLegalForm.friendlyName) return matchingLegalForm.friendlyName;
        if (matchingLegalForm.short_name) return matchingLegalForm.short_name;
        if (matchingLegalForm.name) return matchingLegalForm.short_name;
        if (matchingLegalForm.display_name) return matchingLegalForm.display_name;
        if (matchingLegalForm.long_name) return matchingLegalForm.long_name;

        return "Rechtliche Einheit";
    }

    const getSortedClientContacts = (): ISortedClientContact[] => {
        if (!addressees || !addressees.length) {
            if (!clientContacts || !clientContacts.length) return [];
            return clientContacts.map((e: IUser): ISortedClientContact => ({
                addressees: [],
                user: e
            }));
        }

        const noUserItem: ISortedClientContact = {
            addressees: []
        }
        
        const items: { [key: string]: ISortedClientContact} = {};

        for (var addressee of addressees) {
            if (!addressee) continue;
            
            if (addressee.type === DatevAddresseeType.LegalPerson) continue;
            
            const userId = getId(addressee.user);

            if (!userId) {
                noUserItem.addressees.push(addressee);
                continue;
            }

            const u = usersById[userId];

            if (!u) continue;
            
            if (!items[userId]) items[userId] = {
                addressees: [],
                user: u
            };

            items[userId].addressees.push(addressee);
        }

        const result = Object.values(items);    
        
        result.sort((a, b) => {
            if (!a.user && !b.user) return 0;
            if (!a.user) return -1;
            if (!b.user) return 1;

            return a.user.firstName.localeCompare(b.user.firstName);
        })

        if (result.length > 0) result.push(noUserItem);
        
        return result;
    }

    return {
        loadingLegalForms,
        getAddresseeLegalForm,
        addresseeIsProbablyEqual,
        getAddresseeTypeIcon,
        getAddresseeTypeColor,
        getAddresseeAddress,
        getAddresseeDuplicates,
        getSortedClientContacts
    }
}