import React from "react";
import useFilteredTickets, { TicketSort } from "../../../hooks/useFilteredTickets";
import SortDirectionToggle from "../../buttons/SortDirectionToggle";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";
import "./SortTicketsButton.css";

export default function SortTicketsButton() {
    const {
        direction,
        sort,
        updateFilter,
        getSortOptions
    } = useFilteredTickets();

    return (
        <Flex gap={2} row >
            <Select 
                size={14}
                onChange={(sort) => updateFilter({sort: sort as TicketSort})}
                value={sort}
                values={getSortOptions()}
            />
            <SortDirectionToggle direction={direction} saveDirection={(direction) => updateFilter({direction: direction})} />
        </Flex>
    )
}