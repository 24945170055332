import React from "react";
import Flex from "../../container/Flex";
import { useTicketAccessHistory } from "../../../state/swr/tickets/useTicketAccessHistory";
import { ITicket } from "../../../types/ApiTypes";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { formatDuration, formatNumber } from "../../../util/formatter";
import useUserUtil from "../../../hooks/useUserUtil";
import UserAvatar from "../../user/profile/UserAvatar";
import Card from "../../card/Card";
import SidebarHeader from "../../sidebar/SidebarHeader";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";

export default function TicketAccess({ticket}: {ticket: ITicket}) {

    const {
        role
    } = useCurrentTenantRole();

    const {
        getName
    } = useUserUtil();

    const { loadingTicketAccessHistory, reloadTicketAccessHistory, ticketAccessHistory } = useTicketAccessHistory(ticket);
    
    if (role && role.isClient) return null;
    if (loadingTicketAccessHistory) return <LoadingSpinner />

    return (
        <Flex className="w-100">
            <SidebarHeader text="Bearbeitungszeiten" />
            {
                ticketAccessHistory 
                ? (
                    <Flex fullWidth>
                        <Flex row fullWidth justify="between">
                            <span>Gesamte Bearbeitungszeiten</span>
                            <strong>{formatDuration(ticketAccessHistory.totalTimeAccessed)}</strong>
                        </Flex>
                        <strong>Zugriffe</strong>
                        {
                            ticketAccessHistory.access.map((access, i) => (
                                <Card wrapperClass="w-100" key={`ticket-access-${ticketAccessHistory._id}-${i}`}>
                                    <Flex row fullWidth>
                                        <UserAvatar user={access.user} mailAddress={access.user.mailAddress} />
                                        <Flex gap={0} fullWidth>
                                            <Flex row fullWidth justify="between">
                                                <strong>{getName(access.user)}</strong>
                                                <strong>{formatDuration(access.totalTimeAccessed)}</strong>
                                            </Flex>
                                            <Flex row fullWidth justify="between">
                                                <em>Davon abrechenbar</em>
                                                <em>{formatDuration(access.history.reduce((a, b) => b.wasAssignedToTicketAtStart ? a + b.minutesLength : a, 0))}</em>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Card>
                            ))
                        }
                    </Flex>
                )
                : <span>Keine Bearbeitungszeit</span>
            }
        </Flex>
    )
}