import React from "react";
import { useUsers } from "../state/swr/user/useUsers";
import { DbId, IUser } from "../types/ApiTypes";
import useUserUtil from "./useUserUtil";
import { useTeams } from "../state/swr/teams/useTeams";
import { getByDisplayValue } from "@testing-library/react";
import { getId } from "../util/mongoUtil";

export interface IWithFilteredUsers {
    displayed?: DisplayedUsers,
    showTheseIds?: Array<DbId<IUser>>,
    showWithoutRole?: boolean
}
export type DisplayedUsers = "all" | "clients" | "teamMembers" | "employees";

export default function useFilteredUsers({displayed = "all", showTheseIds, showWithoutRole = false}: IWithFilteredUsers) {
    
    const [filteredUsers, setFilteredUsers] = React.useState<Array<IUser>>([]);

    const {
        users,
        loadingUsers
    } = useUsers();

    const {
        teams
    } = useTeams();

    const {
        getRole
    } = useUserUtil();

    const checkIfUserCanBeDisplayed = (u: IUser) => {
        if (!showTheseIds || !showTheseIds.length) return true;
        return showTheseIds.some(id => getId(id) === getId(u));
    }
    const getFilteredUsers = (): Array<IUser> => {
        if (displayed === "all") return users;

        const usersForRole = users.filter(u => {
            if (!checkIfUserCanBeDisplayed(u)) return false;
            const role = getRole(u);
            if (!role) return showWithoutRole;
            return role.isClient === (displayed === "clients");
        });

        switch (displayed) {
            case "clients":
            case "employees": 
                
                return usersForRole;
            
            default:
                
                if (!teams || !teams.length) return [];
        
                const teamMembers = usersForRole.filter(u => {
                    teams.some(t => t.members.some(m => m === u._id))
                });
        
                return teamMembers;
        }
    }

    React.useEffect(() => {
        if (loadingUsers) return;
        if (!users || !users.length) return;

        const u = getFilteredUsers();
        setFilteredUsers(u);
    }, [users, loadingUsers, displayed, showWithoutRole, teams]);
    
    return {
        loading: loadingUsers,
        users: filteredUsers
    }
}