import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTenantOpenState = () => {
  const { data, isLoading, mutate } = useTiggiQuery<boolean>(ApiRoutes.Tenant.IsTenantOpenRightNow);

  return {
    isOpen: data,
    loadingIsOpen: isLoading,
    reloadIsOpen: mutate
  }
}