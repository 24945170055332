import React from "react";
import IElementProps from "../../types/element.types";
import LoadingSpinner from "../loader/LoadingSpinner";
import LabelButton from "../buttons/LabelButton";
import Typography from "../text/Typography";

interface IInfiniteScrollProps extends IElementProps {
    itemsPerPage?: number,
}

export default function InfiniteScroll({children, itemsPerPage = 25}: IInfiniteScrollProps) {

    const [renderIndex, setRenderIndex] = React.useState<number>(0);
    const [currentItems, setCurrentItems] = React.useState<Array<any>>([]);
    const [page, setPage] = React.useState<number>(1);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);

    const loadMoreRef = React.useRef<HTMLDivElement>(null);

    const observer = React.useRef(
        new IntersectionObserver(
            (entries) => {
                if (loading) return;
                if (entries[0].isIntersecting) setPage(n => n + 1);
            })
    );

    React.useEffect(() => {
        setHasMore(true);
    }, [children]);

    React.useEffect(() => {
        if (page === 1) setCurrentItems([]);
    }, [page]);
    
    React.useEffect(() => {

        setLoading(true);

        try {
            const realChildren = children ? (Array.isArray(children) ? children : [ children ]) : undefined;
    
            if (!realChildren) {
                setHasMore(false);
                return;
            }

            const maxItemsVisible = itemsPerPage * page;
            const possibleItems = realChildren.length;
            const displayedItems = maxItemsVisible < possibleItems ? maxItemsVisible : possibleItems;

            const newItems = realChildren.slice(0, displayedItems);
            
            setCurrentItems(newItems);


        }
        finally {
            setLoading(false);
        }

    }, [page, children]);

    React.useEffect(() => {
        const realChildren = children ? (Array.isArray(children) ? children : [ children ]) : undefined;
        
        if (!realChildren) {
            setHasMore(false);
            return;
        }

        if (!currentItems || !currentItems.length) {
            setHasMore(true);
            return;
        }

        if (realChildren.length === currentItems.length) setHasMore(false);

    }, [children, currentItems]);

    React.useEffect(() => {
        if (!loadMoreRef || !loadMoreRef.current) return;
        if (!observer || !observer.current) return;

        observer.current.observe(loadMoreRef.current);
    }, [loadMoreRef, children]);
    
    return (
        <>
            {
                currentItems 
            }
            <div id="infinite-scroll-observer" ref={loadMoreRef} style={{height: "1px", width: "100%", gridColumn: "1 / -1"}} />
            <div className="d-flex flex-row align-items-center justify-content-center w-100" style={{color: "#A0A0A0", gridColumn: "1 / -1"}}>
                {
                    loading 
                    ? <LoadingSpinner />
                    : (
                        hasMore
                        ? <LabelButton onClick={async () => setPage(n => n + 1)} text="Mehr laden" />
                        : <Typography color="#A0A0A0" className="pb-2" size={9}>Keine weiteren Einträge.</Typography>
                    )
                }
            </div>
        </>
    )
}