import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ILegalForm } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";

export const useLegalForms = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<ILegalForm>>(ApiRoutes.LegalForms);

    return {
        legalForms: data?.items ?? [],
        legalFormsById: data?.byId ?? {},
        loadingLegalForms: isLoading,
        reloadLegalForms: mutate
    }
}