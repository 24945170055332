import React from "react";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import { useMailTemplates } from "../../state/swr/templates/useMailTemplates";
import { getId } from "../../util/mongoUtil";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import MailTemplateListItem from "./MailTemplatesListItem";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import UpdateMailTemplateButton from "./UpdateMailTemplateButton";
import UsedMailTemplateIndicator from "./UsedMailTemplateIndicator";
import { IGlobalMailTemplate, MailTemplate } from "../../types/globalMailTemplates.schema";
import Typography from "../text/Typography";

export interface ITenantMailTemplatesForTemplateType {
    type: MailTemplate,
    isInitiallyExpanded?: boolean
}

export default function TenantMailTemplatesForTemplateType(props: ITenantMailTemplatesForTemplateType) {

    const {
        type,
        isInitiallyExpanded
    } = props;
    
    const {
        loadingMailTemplates,
        mailTemplates     
    } = useMailTemplates();

    const {
        globalMailTemplates
    } = useGlobalMailTemplates();

    const baseTemplate = globalMailTemplates?.find(g => g.templateType === type) ?? { templateType: type } as IGlobalMailTemplate;
    
    const templatesForType = mailTemplates?.filter(t => {
        if (t.templateType === type) return true;
        const bt = getId(t.baseTemplate);
        if (!bt) return false;
        return bt === getId(baseTemplate);
    }) ?? [];

    const [isExpanded, setIsExpanded] = React.useState<boolean>((isInitiallyExpanded && !!templatesForType.length) ?? false);

    return (
        <Flex fullWidth>
            <Flex justify="between" fullWidth row>
                <Flex row>
                    <MailTemplateTypeBanner template={type} />
                    <UsedMailTemplateIndicator showAsUsingCustomMailTemplateIndicator template="" templateType={type} />
                </Flex>
                <Flex row>
                    <Button
                        disabled={!templatesForType.length}
                        loading={loadingMailTemplates} 
                        onClick={() => setIsExpanded(!isExpanded)} 
                        icon={isExpanded ? "chevron-up" : "chevron-down"}
                        text={(isExpanded && !!templatesForType.length) ? "Ausblenden" : `${templatesForType.length} anzeigen`} 
                    />
                    <UpdateMailTemplateButton isCreate templateType={type} baseTemplate={baseTemplate} />
                </Flex>
            </Flex>
            {
                isExpanded && (
                    <Flex fullWidth row wrap>
                        {
                            templatesForType.map(t => <MailTemplateListItem key={t._id} template={t} />)
                        }
                    </Flex>
                )
            }
        </Flex>
    )
}