import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import "./Flex.css";

export type FlexGap = 0 | 1 | 2 | 3 | 4 | 5;

export interface IFlexProps extends IElementProps {
  row?: boolean,
  align?: "start" | "center" | "baseline" | "top" | "end" | "bottom",
  justify?: "start" | "center" | "end" | "between" | "around",
  overflowX?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
  overflowY?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
  wrap?: boolean,
  reverse?: boolean,
  fullWidth?: boolean,
  fullHeight?: boolean,
  gap?: `${FlexGap}` | FlexGap
}

export default function Flex(props: IFlexProps) {

  const {
    wrap,
    fullHeight,
    fullWidth,
    row = false,
    overflowX,
    overflowY,
    gap,
    reverse,
    children,
    onClick,
    style,
    className,
    align,
    justify = "start"
  } = props;

  const defaultAlign = row ? "center" : "start";

  const flexClass = generateClassName(className, "flexbox d-flex", {
    base: "flex-",
    value: row,
    onTrue: `row${reverse ? "-reverse" : ""}`,
    standard: `column${reverse ? "-reverse" : ""}`
  }, {
    base: "gap-",
    value: gap ?? 2
  }, {
    base: "align-items-",
    value: align ?? defaultAlign
  }, {
    base: "justify-content-",
    value: justify
  }, {
    value: wrap,
    onTrue: "flex-wrap"
  }, {
    value: fullWidth,
    onTrue: "w-100"
  }, {
    value: fullHeight,
    onTrue: "h-100"
  }, {
    value: !!onClick,
    onTrue: "flex-clickable"
  }, {
    base: "overflow-x-",
    value: overflowX,
  }, {
    base: "overflow-y-",
    value: overflowY

  });

  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      onClick={props.onClick}
      className={flexClass}
      style={style}
    >
      {children}
    </div>
  )
}