import React from "react";
import { useSearchParams } from "react-router-dom";
import { AppQueryParams } from "../config/QueryParams";
import { useAppDispatch } from "./reduxHooks";
import { setRightSidebarExpanded } from "../state/slices/sidebarSlice";
import { TicketChatSidebarTab } from "./useTicketSidebar";

export function useTicketSidebarHistory() {
    const [messageId, setMessageId] = React.useState<string>("");
    const [query, setQuery] = useSearchParams();

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!query) return;
        const val = query.get(AppQueryParams.TicketMessageId) || "";
        setMessageId(val);
    }, [query]);

    const showTicketMessageHistory = (messageId: string) => {
        const newQuery = new URLSearchParams(query);
    
        if (messageId) {
            newQuery.set(AppQueryParams.TicketMessageId, messageId);
            newQuery.set(AppQueryParams.SidebarTab, TicketChatSidebarTab.History);
        }
        else newQuery.delete(AppQueryParams.TicketMessageId);
    
        setQuery(newQuery, {replace: true});

        dispatch(setRightSidebarExpanded(true));
    }

    return {
        showTicketMessageHistory,
        messageId
    }
}
