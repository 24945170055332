import { Gender } from "../types/salutation.schema";

export default function useGenderUtil() {
    const getLabelForGender = (g: Gender) => {
        switch (g) {
            case Gender.Female: return "Weiblich";
            case Gender.Male: return "Männlich";
            case Gender.Diverse: return "Divers";
            case Gender.Unknown: return "Unbekannt";
        }
    }

    return {
        getLabelForGender
    }
}