import { MailFolder } from "@microsoft/microsoft-graph-types";
import React from "react";
import { generateClassName, generateStyle } from "../../../../hooks/useAttributes";
import { useOfficeAcountFolderChildFolders } from "../../../../state/swr/office/useOfficeAccountFolderChildFolders";
import Flex from "../../../container/Flex";
import Icon from "../../../icons/Icon";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import Typography from "../../../text/Typography";
import "./OfficeFolder.css";
import OfficeFolderIcon from "./OfficeFolderIcon";
import OfficeFolderUnreadCount from "./OfficeFolderUnreadCount";
import { IOfficeAccount } from "../../../../types/officeAccount.types";

export interface IOfficeMailFolder {
    folder: MailFolder,
    hierarchyLevel: number,
    account: IOfficeAccount,
    onClick: (folder: MailFolder) => void,
    checkIsActive: (folder: MailFolder) => boolean
}

export default function OfficeFolder({folder, hierarchyLevel = 0, account, checkIsActive, onClick}: IOfficeMailFolder) {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const { childFolders, loadingChildFolders, reloadChildFolders } = useOfficeAcountFolderChildFolders(account._id, folder.id!);

    const isActive = checkIsActive(folder);

    const folderBackgroundClass = generateClassName("office-folder-background position-absolute", {
        value: isActive,
        onTrue: "office-folder-background-active"
    });

    const folderClass = generateClassName("office-folder w-100 position-relative", {
        value: isActive,
        onTrue: "office-folder-active"
    });

    const clickHandler = () => {
        onClick(folder);
        if (!folder.childFolderCount || folder.childFolderCount === 0) return;
        setExpanded(!expanded);
    }

    const parentStyle = generateStyle({
        value: (hierarchyLevel + 1) * 30,
        name: "paddingLeft",
        unit: "px"
    })

    return (
        <Flex className="w-100" gap={1}>
            <Flex row onClick={clickHandler} className={folderClass}>
                <Flex row style={{width: "60px"}} justify="center">
                    {
                        !!folder.childFolderCount && folder.childFolderCount > 0 && (loadingChildFolders || (childFolders && !!childFolders.length)) && <Icon color="primary" icon={expanded ? "chevron-down" : "chevron-right"} loading={loadingChildFolders} />
                    }
                </Flex>
                <div className={folderBackgroundClass} />
                <Flex row className="pe-3 p-2 h-100 w-100" justify="between">
                    <Flex row>
                        <OfficeFolderIcon folder={folder} />
                        <Typography size={12} >{folder.displayName}</Typography>
                    </Flex>
                    <OfficeFolderUnreadCount account={account} folder={folder} />
                </Flex>
            </Flex>
            <Flex style={parentStyle} className="w-100" gap={0}>
                {
                    expanded && (
                        loadingChildFolders
                        ? <LoadingSpinner />
                        : (
                            childFolders && !!childFolders.length
                            ? childFolders.map((f: MailFolder) => <OfficeFolder key={f.id} hierarchyLevel={hierarchyLevel + 1} account={account} folder={f} onClick={onClick} checkIsActive={checkIsActive} />)
                            : <span>Keine Unterordner vorhanden</span>
                        )
                    )
                }
            </Flex>
        </Flex>
    )
}