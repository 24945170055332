import React from "react";
import { ticketCategoriesGetAll, ticketEscalate, ticketsDelete, usersVerify } from "../../../api/Api";
import { AppColor, UserRoleColor } from "../../../app/AppStyles";
import { useAppSelector } from "../../../hooks/reduxHooks";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useTicketUtil from "../../../hooks/useTicketUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket, IUser, TicketState, UserRole } from "../../../types/ApiTypes";
import Button, { ButtonSize } from "../../buttons/Button";
import UserRoleIndicator from "../../user/RoleBanner";
import "./EscalateTicketButton.css";
import Expandable from "../../comboBox/Expandable";
import AssignmentUserItem from "./AssignmentUserItem";
import { useTicketAssignee } from "../../../state/swr/tickets/assignments/useTicketAssignee";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";
import { useTeams } from "../../../state/swr/teams/useTeams";

interface IEscalateTicketButtonProps {
    afterEscalatingTicket?: () => void,
    ticket: ITicket
}

export default function EscalateTicketButton({ticket, afterEscalatingTicket}: IEscalateTicketButtonProps) {

    const permissions = useTicketPermission("update", ticket);

    const {
        teamsById
    } = useTeams();

    const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);

    const {
        getUsersForRole,
        getCurrentTenantRole,
    } = useUserUtil();

    const {
        convertToUserRole
    } = useRoleUtil();

    const {
        isClosed
    } = useTicketUtil();

    const callApi = useApi();

    const role = getCurrentTenantRole(ticketAssignee?.user);
    const userRole = convertToUserRole(role);

    const escalateTicket = async (user: IUser) => {
        const res = await callApi(ticketEscalate({ticketId: ticket._id, escalateTo: user._id}));

        if (!res) return;
        if (afterEscalatingTicket) afterEscalatingTicket();
    }
    
    const ticketIsClosed = isClosed(ticket);

    const usedTeam = teamsById[ticket.team];

    const users = getUsersForRole(usedTeam?.members, userRole === UserRole.TeamLead ?  [UserRole.Partner] : [UserRole.TeamLead, UserRole.Partner]);

    const hasUsers = users && !!users.length;
    
    const buttonText = ticketIsClosed ? "Ticket ist geschlossen" : (hasUsers ? (userRole === UserRole.Partner ? "Beim Partner" : userRole === UserRole.TeamLead ? "Zum Partner eskalieren" : "An höhere Ebene eskalieren") : "Keine passenden Teammitglieder");

    return (
        <WithPermissions permissions={permissions}>
            <Expandable
                disabled={!hasUsers}
                expander={
                    <Button variant="outline" color="error" icon="chevron-right" iconPosition="end">{buttonText}</Button>
                }
            >
                {
                    hasUsers && users.map(u => <AssignmentUserItem key={u._id} user={u} onClick={async () => await escalateTicket(u)} />)
                }
            </Expandable>
        </WithPermissions>
    )
}