import useSWR from "swr";
import { ITicketNote } from "../../../../types/ticketNotes.schema";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";

export const useTicketNotes = (ticket: string) => {
  const { data, isLoading, mutate } = useTiggiQuery<ITicketNote[]>(ApiRoutes.Tickets.Notes.All.getRoute({
    [RouteParam.TicketId]: ticket
  }));

  return {
    ticketNotes: data,
    loadingTicketNotes: isLoading,
    reloadTicketNotes: mutate
  }
}