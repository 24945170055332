import React from "react";
import Pill from "../pills/Pill";
import { ExternalService, ILogEntry } from "../../types/log.schema";

export interface IExternalServiceBannerProps {
    service?: ExternalService
}

export default function ExternalServiceBanner(props: IExternalServiceBannerProps) {

    const {
        service
    } = props;

    if (!service || service === ExternalService.None) return null;

    const getText = () => {
        switch (service) {
            case ExternalService.SendGrid: return "SendGrid";
            case ExternalService.Azure: return "Microsoft Azure";
            case ExternalService.CloudGateway: return "CloudGateway";
            case ExternalService.PointChamp: return "PointChamp";
            case ExternalService.Stripe: return "Stripe";
            case ExternalService.TiggiDatevDesktopClient: return "tiggi DATEV-Client";
            case ExternalService.TiggiWebClient: return "tiggi Web";

        }
    }

    const getColor = () => {
        switch (service) {
            case ExternalService.SendGrid: return "#F39F18";
            case ExternalService.Azure: return "#0078D4";
            case ExternalService.CloudGateway: return "#ff9400";
            case ExternalService.PointChamp: return "#a1ad03";
            case ExternalService.Stripe: return "#635bff";
            case ExternalService.TiggiDatevDesktopClient: return "#eeeeFF";
            case ExternalService.TiggiWebClient: return "#eeeeFF";
        }
    }

    const getIcon = () => {
        switch (service) {
            case ExternalService.SendGrid: return "mail";
            case ExternalService.Azure: return "microsoft";
            case ExternalService.CloudGateway: return "cloud";
            case ExternalService.PointChamp: return "cloud";
            case ExternalService.Stripe: return "credit-card";
            case ExternalService.TiggiDatevDesktopClient: return "laptop";
            case ExternalService.TiggiWebClient: return "globe";
        }
    }

    return (
        <Pill 
            text={getText()} 
            color={getColor()} 
            withDivider
            icon={getIcon()}
        />
    )
}