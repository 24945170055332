import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useFolderUnreadMessagesCount = (accountId: string, folderId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<{unreadItemCount: number}>(ApiRoutes.OfficeAccounts.GetFolderUnreadMessagesCount.getValue({ 
        param: RouteParam.OfficeAccountId, 
        value: accountId 
    }, { 
        param: RouteParam.FolderId, 
        value: folderId
    }));

    return {
        folderUnreadMessagesCount: data,
        loadingFolderUnreadMessagesCount: isLoading,
        reloadFolderUnreadMessagesCount: mutate
    }
}