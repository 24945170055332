import useSWR from "swr";
import { ITask } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTasks = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITask>>(ApiRoutes.Tasks.All);

    return {
        tasks: data,
        loadingTasks: isLoading,
        reloadTasks: mutate
    }
} 