import useSWR from "swr";
import { ITask } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketTasks = (ticketId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITask>>(ApiRoutes.Tickets.Tasks.getValue({ param: RouteParam.TicketId, value: ticketId }));

    return {
        ticketTasks: data,
        loadingTicketTasks: isLoading,
        reloadTicketTasks: mutate
    }
} 