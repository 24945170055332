import React from "react";
import { usedMailTemplatesResetToGlobal, usedMailTemplatesUpdateAssignment } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useUsedMailTemplates } from "../../state/swr/templates/useUsedMailTemplates";
import { IUsedMailTemplate, IUsedMailTemplateDocument } from "../../types/usedMailTemplates.schema";
import { getModel } from "../../util/mongoUtil";
import Pill from "../pills/Pill";

export interface IUsedMailTemplateIndicatorProps extends IUsedMailTemplateDocument {
  showAsUsingCustomMailTemplateIndicator?: boolean
}

export default function UsedMailTemplateIndicator(props: IUsedMailTemplateIndicatorProps) {

  const callApi = useApi();

  const {
    template,
    templateType,
    showAsUsingCustomMailTemplateIndicator
  } = props;

  const {
    loadingUsedMailTemplates,
    usedMailTemplatesByTemplate,
    reloadUsedMailTemplates
  } = useUsedMailTemplates();

  const templateAssignmentForTemplate = templateType ? getModel<IUsedMailTemplate>(templateType, usedMailTemplatesByTemplate) : null;
  const hasCustomTemplateAssigned = !!templateAssignmentForTemplate;
  const isUsed = templateAssignmentForTemplate && templateAssignmentForTemplate.template === template;
  const canReset = (hasCustomTemplateAssigned && showAsUsingCustomMailTemplateIndicator);

  const getText = () => {
    if (showAsUsingCustomMailTemplateIndicator) return hasCustomTemplateAssigned ? "Eigene Vorlage" : "Standardvorlage";
    return isUsed ? "Verwendet" : "Verwenden";
  }

  const getColor = (): string => {
    if (showAsUsingCustomMailTemplateIndicator) return hasCustomTemplateAssigned ? "primary" : "muted";
    return isUsed ? "success" : "#D3F3D3";
  }

  const getIcon = () => {
    if (showAsUsingCustomMailTemplateIndicator) return hasCustomTemplateAssigned ? "brush" : "cloud-check";
    return isUsed ? "check" : "plus";
  }

  const clickHandler = async () => {
    if (canReset) await callApi(usedMailTemplatesResetToGlobal(props));
    else await callApi(usedMailTemplatesUpdateAssignment(props));

    await reloadUsedMailTemplates();
  }

  return (
    <Pill
      loadingText="Lädt..."
      hoverText={canReset ? "Nicht mehr verwenden?" : undefined}
      hoverColor={canReset ? "error" : undefined}
      hoverIcon={canReset ? "x" : undefined}
      onClick={(isUsed || (showAsUsingCustomMailTemplateIndicator && !hasCustomTemplateAssigned)) ? undefined : clickHandler}
      loading={loadingUsedMailTemplates}
      text={getText()}
      color={getColor()}
      icon={getIcon()}
    />
  )
}