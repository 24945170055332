import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITenantOpeningHours } from "../../../types/ApiTypes";

export const useOpeningHours = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ITenantOpeningHours>(ApiRoutes.OpeningHours.All);

    return {
        openingHours: data,
        loadingOpeningHours: isLoading,
        reloadOpeningHours: mutate
    }
}