import React from "react";
import { ICoupon } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { couponsDelete } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useCoupons } from "../../state/swr/subscriptions/useCoupons";
import { useUser } from "../../state/swr/user/useUser";

export default function DeleteCouponButton({coupon}: {coupon: ICoupon}) {

    const {
        user
    } = useUser();

    const { reloadCoupons } = useCoupons();

    const callApi = useApi();
    const dispatch = useAppDispatch();

    const clickHandler = async () => {
        const res = await callApi(couponsDelete({id: coupon._id}));
        if (!res || !res.success) return;
        await reloadCoupons();
    }

    if (!user || !user.isSuperAdmin) return null;
    
    return <Button secondStepQuestion="Sicher?" text="Löschen" icon="trash" loadingText="Wird gelöscht..." onClick={clickHandler} color="error" />
}