import { MailFolder } from "@microsoft/microsoft-graph-types";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useOfficeAcountFolders = (accountId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<MailFolder>>(ApiRoutes.OfficeAccounts.GetOfficeAccountMailFolders.getValue({ 
        param: RouteParam.OfficeAccountId, 
        value: accountId 
    }));

    return {
        accountFolders: data,
        loadingAccountFolders: isLoading,
        reloadAccountFolders: mutate
    }
}