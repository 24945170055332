import React from "react";
import Pill from "../../pills/Pill";
import { ILogEntry, LogEntryType } from "../../../types/log.schema";
import useLogUtil from "../../../hooks/useLogUtil";



export default function LogEntryTypeBanner({log}: {log: ILogEntry}) {

    const {
        getTextFromLogEntryType,
        getColorFromLogEntryType,
        getIconFromLogEntryType
    } = useLogUtil();

    

    return (
        <Pill 
            color={getColorFromLogEntryType(log.type)} 
            text={getTextFromLogEntryType(log.type)} 
            icon={getIconFromLogEntryType(log.type)} 
        />
    )
}