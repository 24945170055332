import React from "react";
import useFilteredTickets, { TicketFilter, TicketView } from "../../../hooks/useFilteredTickets";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { Locale } from "../../../locale/Locale";
import { useTicketsWithFilter } from "../../../state/swr/tickets/useTicketsWithFilter";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { ITicket, IUser, TicketState } from "../../../types/ApiTypes";
import { IHydratedTicket } from "../../../types/ticket.schema";
import Button from "../../buttons/Button";
import ClientSelect from "../../clients/ClientSelect";
import Flex from "../../container/Flex";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import PageHeader from "../../label/PageHeader";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Divider from "../../sidebar/Divider";
import Table from "../../tables/Table";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import TeamSelect from "../../teams/TeamSelect";
import UserSelect from "../../user/UserSelect";
import CreateTicketModal from "../CreateTicketModal";
import SortTicketsButton from "../filter/SortTicketsButton";
import TicketSearchBox from "../filter/TicketSearchBox";
import MergeTicketButton from "../status/MergeTicketButton";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";
import "./TicketOverview.css";
import TicketOverviewItem from "./TicketOverviewItem";

export interface ISortedTicketElement {
  team: {
    _id: string,
    name: string,
    members: IUser[]
  },
  tickets: ITicket[]
}

export default function TicketOverview() {
  const [selectedTickets, setSelectedTickets] = React.useState<Map<string, IHydratedTicket>>(new Map());
  const [activeTab, setActiveTab] = React.useState<TicketFilter>();

  const {
    getDisplayId
  } = useTicketUtil();

  const {
    view,
    updateFilter,
    team,
    getViewTabs,
    assignee,
    getSubcategoryTab,
    getNotAssignedTypeTabs,
    client,
    getFilterTabs,
    getFilterForRoute
  } = useFilteredTickets();

  const { role } = useCurrentTenantRole();
  const { user } = useUser();

  const {
    loadingTickets,
    tickets,
    validatingTickets
  } = useTicketsWithFilter(getFilterForRoute());

  const isClient = !(role && !role.isClient) && !(!!user && user.isSuperAdmin);
  const hasAnySelection = !!selectedTickets?.size;

  const getSelectedItems = () => {
    const result = [];
    for (const [_, value] of selectedTickets) {
      result.push(value.ticket);
    }
    return result;
  }

  const selectedItems = getSelectedItems();

  return (
    <Flex className="w-100 h-100" gap={4} style={{ overflowY: "auto" }}>
      <Flex className="w-100" gap={1}>
        <Flex row gap={3} className="w-100" justify="between" wrap>
          <Flex row gap={3} >
            <PageHeader text={Locale.titles.tickets} />
            <CreateTicketModal />
          </Flex>
          {
            hasAnySelection && !isClient
              ? (
                <Flex row wrap gap={4}>
                  <Button variant="text" icon="x" iconSize={20} onClick={async () => setSelectedTickets(new Map())}>Aufheben</Button>
                  <Flex row wrap justify="end">
                    <MergeTicketButton selectedTickets={selectedItems} variant="solid" afterAction={() => setSelectedTickets(new Map())} />
                    {
                      activeTab === TicketFilter.Closed
                        ? <UpdateTicketStatusButton tickets={selectedItems} variant="solid" afterAction={() => setSelectedTickets(new Map())} />
                        : (
                          <>
                            <UpdateTicketStatusButton tickets={selectedItems} variant="solid" afterAction={() => setSelectedTickets(new Map())} state={TicketState.Solved} />
                            <UpdateTicketStatusButton tickets={selectedItems} variant="solid" afterAction={() => setSelectedTickets(new Map())} state={TicketState.Resubmission} />
                          </>
                        )
                    }
                  </Flex>
                  <UpdateTicketStatusButton tickets={selectedItems} variant="solid" afterAction={() => setSelectedTickets(new Map())} isDelete />
                </Flex>
              )
              : (

                <Flex row gap={3} className="ms-auto">
                  <Flex row>
                    {
                      activeTab === TicketFilter.All && (
                        <UserSelect
                          onChange={u => updateFilter({ assignee: u ? u._id : undefined })}
                          value={assignee}
                          useDefaultValue={false}
                          displayed="employees"
                          placeholder="Bearbeiter"
                        />
                      )
                    }
                    <ClientSelect
                      hideLabel
                      useDefaultValue={false}
                      saveClient={c => updateFilter({ client: c?._id })}
                      clientId={client}
                      showAllClients
                    />
                  </Flex>
                  <Divider height={24} color="muted" opacity={0.25} width={2} />
                  <SortTicketsButton />
                  <TabSwitcher
                    variant="icons"
                    tabQueryParamKey="view"
                    tabs={getViewTabs()}
                  />
                </Flex>
              )
          }
        </Flex>
        <Flex row justify="between" className="w-100" wrap>
          <TabSwitcher tabs={getFilterTabs()} primaryPriority={1} displayedPriority={1} tabQueryParamKey="show" saveActiveTab={s => setActiveTab(s as TicketFilter)} />
          <Flex row gap={4}>
            <TabSwitcher variant="muted" primaryPriority={1} tabs={getFilterTabs()} displayedPriority={2} tabQueryParamKey="show" saveActiveTab={s => setActiveTab(s as TicketFilter)} />
            <TicketSearchBox />
          </Flex>
        </Flex>
        {
          !isClient
          && (

            (activeTab === TicketFilter.ForMe || activeTab === TicketFilter.All || activeTab === TicketFilter.MyTeam)
              ? (
                <TabSwitcher
                  tabQueryParamKey="subcategory"
                  tabs={getSubcategoryTab()}
                  size="tiny"
                />
              )
              : (
                activeTab === TicketFilter.NotAssigned
                  ? (
                    <TabSwitcher
                      tabQueryParamKey="notAssignedType"
                      tabs={getNotAssignedTypeTabs()}
                      size="tiny"
                    />
                  )
                  : <div></div>
              )
          )
        }
        {
          activeTab === TicketFilter.MyTeam && (
            <Flex row justify="between" className="w-100">
              <TeamSelect onTeamClick={t => updateFilter({ team: t ? t._id : "" })} selectedTeam={team} />
            </Flex>
          )
        }
      </Flex>
      {
        (validatingTickets || loadingTickets) && <LoadingSpinner size={12} color="primary" text={validatingTickets ? "Tickets werden aktualisiert" : "Tickets werden geladen..."} />
      }
      {
        tickets && !!tickets.length
          ? (
            view === TicketView.Grid
              ? (
                <div className="w-100 ticket-overview-item-container ticket-grid">
                  <InfiniteScroll itemsPerPage={5}>
                    {
                      tickets && !!tickets.length
                        ? tickets.map(s => <TicketOverviewItem key={s.ticket?._id} hydratedTicket={s} />)
                        : <span className="fw-bold">Keine Tickets</span>
                    }
                  </InfiniteScroll>
                </div>
              )
              : (
                <Table
                  headers={[
                    { label: "Aktualisiert" },
                    {
                      label: "Nummer",
                      filterItem: (t: IHydratedTicket, f: string) => getDisplayId(t.ticket).toLowerCase().includes(f.toLowerCase())
                    },
                    {
                      label: "Ticket",
                      filterItem: (t: IHydratedTicket, f: string) => t.ticket?.subject.toLowerCase().includes(f.toLowerCase())
                    },
                    { label: "Eröffnet von" },
                    { label: "Zuweisung", hidden: isClient },
                    { label: "Nachrichten" },
                    { label: "Status" },
                    { label: "" }
                  ]}
                  getItemId={t => t._id}
                  items={tickets}
                  canSelect
                  selectedItems={selectedTickets}
                  onSelectionChange={setSelectedTickets}
                  renderItem={(t, select) => (
                    <TicketOverviewItem
                      select={select}
                      key={t.ticket?._id}
                      hydratedTicket={t}
                      renderAsTableRow
                    />
                  )}
                />
              )
          )
          : <span className="fw-bold">Keine Tickets</span>
      }
    </Flex>
  )
}