import useSWR from "swr";
import { IAddressee, IAddresseeRelationship, IAlias } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { Sorted } from "../../../types/collection.types";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAddresseeRelationships = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IAddresseeRelationship[]>(ApiRoutes.Addressees.RelationshipsForAddressee.getValue({ 
      param: RouteParam.Addressee, 
      value: id 
    }));

    return {
        addresseeRelationships: data ?? [],
        loadingAddresseeRelationships: isLoading,
        reloadAddresseeRelationships: mutate
    }
} 