import React from "react";
import { addresseesDeleteAll, clientsDeleteAll, usersDeleteAllDatevClients, usersDeleteAllDatevEmployees } from "../../api/Api";
import useApi from "../../hooks/useApi";
import useDatevImport from "../../hooks/useDatevImport";
import { useUser } from "../../state/swr/user/useUser";
import Button from "../buttons/Button";

export default function DeleteAllDatevImportsButton() {

    const { reloadDatevImport } = useDatevImport();

    const [text, setText] = React.useState<string>();

    const callApi = useApi();

    const { user } = useUser();

    if (!user) return null;
    if (!user.isSuperAdmin) return null;

    const deleteClients = async () => {
        setText("Mandanten werden gelöscht...");
        await callApi(clientsDeleteAll());
        setText("Addressaten werden gelöscht...");
        await callApi(addresseesDeleteAll());
        setText("Mandantenkontakte werden gelöscht...");
        await callApi(usersDeleteAllDatevClients());
        setText("Mitarbeiter werden gelöscht...");
        await callApi(usersDeleteAllDatevEmployees(), true);

        await reloadDatevImport();
    }

    return (
        <Button icon="trash" color="error" text="Alle aus DATEV importierten Daten löschen" loadingText={text} onClick={deleteClients} />
    )
}