import React from "react";
import { IMailTemplate } from "../../types/ApiTypes";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import UpdateMailTemplateButton from "./UpdateMailTemplateButton";
import "./MailTemplatesListItem.css";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import ResponsiveText from "../text/ResponsiveText";
import TestMailTemplateButton from "./TestMailTemplateButton";
import UsedMailTemplateIndicator from "./UsedMailTemplateIndicator";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";

export interface IMailTemplateListItemProps {
  template: IMailTemplate
}

export default function MailTemplateListItem({ template }: IMailTemplateListItemProps) {

  const { getStringified } = useTemplateUtil();

  return (
    <Card
      wrapperClass="mw-100 h-100"
      header={
        <Flex fullWidth row justify="between">
          <Typography bold>{getStringified(template.subject)}</Typography>
          <UsedMailTemplateIndicator template={template._id} templateType={template.templateType ?? template.baseTemplate?.templateType} />
        </Flex>
      }
    >
      <Flex fullHeight fullWidth justify="between">
        <ResponsiveText content={getStringified(template.body)} />
        <Flex row fullWidth justify="between">
          <TestMailTemplateButton templateId={template._id} />
          <UpdateMailTemplateButton existingTemplate={template} />
        </Flex>
      </Flex>
    </Card>
  )
}