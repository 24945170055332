import React from "react";
import { testMailTemplates } from "../../api/Api";
import { ITestMailTemplateRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { TicketFilter } from "../../hooks/useFilteredTickets";
import { useTicketMessages } from "../../state/swr/tickets/messages/useTicketMessages";
import { useTicketsWithFilter } from "../../state/swr/tickets/useTicketsWithFilter";
import { useUser } from "../../state/swr/user/useUser";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import TicketFriendlyIdBanner from "../ticketing/banner/TicketFriendlyIdBanner";

export interface ITestMailTemplateButtonProps {
    isGlobal?: boolean,
    templateId: string
}

export default function TestMailTemplateButton({isGlobal = false, templateId}: ITestMailTemplateButtonProps) {

    const [selectedTicketId, setSelectedTicketId] = React.useState<string>("");
    const { tickets, loadingTickets } = useTicketsWithFilter({ show: TicketFilter.All });
    const { messages, loadingMessages } = useTicketMessages(selectedTicketId);
    const { user } = useUser();

    const callApi = useApi();

    return (
        <WithPermissions permissions={["templates.mails.update"]}>
            <ModalForm
                loading={loadingTickets}
                button={<Button text="Testmail" variant="subtle" icon="envelope" />}
                initialValues={{
                    isGlobal: isGlobal,
                    templateId: templateId,
                    ticketId: "",
                    ticketMessageId: ""
                } as ITestMailTemplateRequest}
                onSubmit={async (values) => {
                    const res = await callApi(testMailTemplates(values));
                    if (!res || !res.success) return false;
                    return true;
                }}
                sidebar={formik => <Button className="mt-auto" text="Senden" icon="envelope" onClick={async () => await formik.submitForm()} loading={formik.isSubmitting} />}
                title="Vorlage testen"
            >
                {
                    formik => (
                        <Flex>
                            <Typography>Die E-Mail wird an den aktuell angemeldeten Nutzer versendet: {user ? user.mailAddress : "Kein Nutzer gefunden."} </Typography>
                            <SearchableComboBox 
                                label="Ticket"
                                value={formik.values.ticketId}
                                values={tickets || []}
                                itemToId={t => t._id} 
                                itemToString={t => t.ticket?.subject} 
                                onItemClick={t => {
                                    formik.setFieldValue("ticketId", t?._id);
                                    setSelectedTicketId(t?._id ?? "")
                                }}
                                renderItem={t => (
                                    <Flex>
                                        <Flex row justify="between">
                                            <Typography bold color="primary">{t.ticket?.subject}</Typography>
                                            <TicketFriendlyIdBanner size={12} ticket={t.ticket} />
                                        </Flex>
                                    </Flex>
                                )}
                            />
                            {
                                loadingMessages
                                ? <LoadingSpinner />
                                : (
                                    <SearchableComboBox
                                        label="Ticket-Nachricht"
                                        value={formik.values.ticketMessageId}
                                        values={messages || []}
                                        itemToId={m => m._id}
                                        itemToString={m => m.content && m.content.length ? m.content[m.content.length - 1].text : "Kein Inhalt"}
                                        onItemClick={m => formik.setFieldValue("ticketMessageId", m?._id)}
                                        
                                    />
                                )
                            }
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>
    )
}