import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import { IAuthorizationOptions } from "../../hooks/useAuthorized";
import IElementProps from "../../types/element.types";
import { formatDate } from "../../util/formatter";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import WithPermissions from "../permissions/WithPermissions";
import "./TableCell.css";

export enum TableDensity {
  Condensed = "condensed",
  Standard = "standard",
  Comfortable = "comfortable"
}

interface ITableCellProps extends IElementProps, IAuthorizationOptions {
  content?: any,
  maxWidth?: number,
  bold?: boolean,
  value?: boolean,
  date?: Date | string | number,
  labelOnTrue?: string,
  fillAvailableSpace?: boolean,
  density?: TableDensity,
  labelOnFalse?: string,
  align?: "top" | "middle" | "bottom",
  justify?: "end" | "center" | "start",
  colSpan?: number,
  showTime?: boolean,
  rowSpan?: number
}

export default function TableCell(props: ITableCellProps) {

  const {
    maxWidth,
    superAdminOnly,
    align = "top",
    permissions,
    justify = "start",
    value,
    showTime = false,
    density = TableDensity.Standard,
    date,
    labelOnFalse,
    labelOnTrue,
    className,
    bold,
    colSpan,
    rowSpan,
    content,
    children
  } = props;

  const cellClass = generateClassName("table-cell", className, {
    base: "align-",
    value: align
  }, {
    base: "table-cell-density-",
    value: density,
  }, {
    base: "text-",
    value: justify
  }, {
    value: bold,
    onTrue: "fw-bold"
  });

  const contentWrapperClass = generateClassName("w-100 h-100", {
    base: "table-cell-density-",
    value: density,
  });

  const getChildren = () => {
    if (value !== undefined) return <CheckmarkIcon value={value} trueLabel={labelOnTrue} falseLabel={labelOnFalse} />
    else if (date !== undefined) return <span>{formatDate(date, showTime)}</span>
    else if (!children && content !== undefined) return <span>{content}</span>
    else return children;
  }

  return (
    <WithPermissions permissions={permissions} superAdminOnly={superAdminOnly}>
      <td className={cellClass} rowSpan={rowSpan} colSpan={colSpan}>
        <div className={contentWrapperClass} style={{ maxWidth: maxWidth ? `${maxWidth}px` : "" }}>
          {getChildren()}
        </div>
      </td>
    </WithPermissions>
  );
}