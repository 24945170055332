import React from "react";
import { useServices } from "../../state/swr/subscriptions/useServices";
import { useUser } from "../../state/swr/user/useUser";
import PageLoader from "../loader/PageLoader";
import ServiceCard from "./ServiceCard";
import Flex from "../container/Flex";

export default function ServiceOverview() {

    const {
        user
    } = useUser(); 
    
    const { services, loadingServices } = useServices();

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <Flex fullWidth gap="3">
            {
                loadingServices
                ? <PageLoader />
                : (

                    services && !!services.length
                    ? services.map(s => <ServiceCard key={s._id} service={s} />)
                    : <span>Aktuell sind keine Services hinterlegt</span>
                )
            }
        </Flex>
    )
}