import React from "react";
import { IPlan, IService } from "../../types/ApiTypes";
import Card from "../card/Card";
import PlanFeaturesContainer from "../subscription/PlanFeaturesContainer";
import PlanPrice from "../subscription/PlanPrice";
import IElementProps from "../../types/element.types";
import { generateClassName, generateStyleWithBase } from "../../hooks/useAttributes";
import { AppColor } from "../../app/AppStyles";

export interface IPlanCardProps extends IElementProps {
    plan: IPlan,
    color?: AppColor | string
}

export default function PlanCard({plan, onClick, style, color, className, children}: IPlanCardProps) {
    if (!plan) return null;

    return (
        <Card 
            fitContent
            onClick={onClick}
            color={color}
            strongColor
            wrapperClass={className}
            className="d-flex text-center flex-column justify-content-between gap-2"
            style={generateStyleWithBase(style, [{
                name: "minWidth",
                value: "min(350px, 100%)"
            }])}
        >
            <h6 className="w-100 fw-bold m-0 p-0 text-uppercase">{plan.name}</h6>
            <div className="d-flex flex-column gap-2">
                <PlanPrice plan={plan} />
                <PlanFeaturesContainer plan={plan} />
            </div>
            <div className="d-flex flex-column gap-1">
                {
                    children
                }
            </div>
        </Card>
    )
}