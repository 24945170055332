import React from "react";
import Card from "../../card/Card";
import ImportClientsForm from "../../clients/ImportClientsForm";
import Flex from "../../container/Flex";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import Typography from "../../text/Typography";
import CloudGatewaySetUp from "../CloudGatewaySetUp";
import DownloadDatevExporter from "../DownloadDatevExporter";
import CurrentClientImports from "./CurrentClientImports";

export enum ClientImportTab {
    Options = "options",
    CurrentImports = "currentImports"
}

export default function ClientImport() {

    const [tab, setTab] = React.useState<ClientImportTab>(ClientImportTab.Options);

    return (
        <Flex fullWidth> 
            <TabSwitcher
                tabQueryParamKey="details"
                size="tiny"
                tabs={[
                    { data: ClientImportTab.Options, label: "Import-Einstellungen" },
                    { data: ClientImportTab.CurrentImports, label: "Aktuelle Importe" }
                ]}
                saveActiveTab={t => setTab(t as ClientImportTab)}
            />
            {
                tab === ClientImportTab.Options 
                ? (
                    <Flex fullWidth>
                        <Card 
                            className="w-100"
                            header={<Typography bold>Option 1: Über CloudGateway-Schnittstelle importieren</Typography>}
                        >
                            <CloudGatewaySetUp />
                        </Card>
                        <Card 
                            className="w-100"
                            header={<Typography bold>Option 2: Über Desktop-Client importieren</Typography>}
                        >
                            <DownloadDatevExporter />
                        </Card>
                        <Card 
                            className="w-100"
                            header={<Typography bold>Option 3: DATEV-Export direkt einlesen</Typography>}
                        >
                            <ImportClientsForm />
                        </Card>
                    </Flex>
                )
                : <CurrentClientImports />
            }
        </Flex>
    )
}