import React from "react";
import { clientsUpdateManager, IApiResponse } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useClient } from "../../state/api/clients/useClient";
import { useClients } from "../../state/swr/clients/useClients";
import { IWithClient } from "../../types/client.element.schema";
import { IClient } from "../../types/client.schema";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import UserSelect from "../user/UserSelect";
import "./ImportClientsForm.css";

export default function UpdateClientManagerDialog(props: IWithClient) {

  const {
    client
  } = props;

  const { reloadClients } = useClients();
  const { reloadClient } = useClient(client._id);

  const callApi = useApi();

  return (
    <WithPermissions permissions={["clients.all.update"]} >
      <ModalForm
        button={<Button size="small" variant="subtle" text="Bearbeiten" icon="pen" />}
        title="Mandatsverantwortung"
        initialValues={client}
        enableReinitialize
        onSubmit={async (values) => {

          const res: IApiResponse | null = await callApi(clientsUpdateManager(values as IClient));

          if (!res || !res.success) return false;

          await reloadClients();
          await reloadClient();

          return true;
        }}
      >
        {
          formik => (
            <UserSelect
              displayed="employees"
              onChange={e => formik.setFieldValue("clientManager", e?._id)}
              value={formik.values.clientManager}
            />
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}