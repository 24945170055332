import IElementProps from "../../types/element.types";
import React from "react";
import "./TableRow.css";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { AppColor } from "../../app/AppStyles";
import { hexWithOpacity } from "../../util/util";

interface ITableRowProps extends IElementProps {
  color?: AppColor
}

export default function TableRow({ children, color, onClick }: ITableRowProps) {

  const className = generateClassName("table-row", {
    value: onClick,
    onTrue: "table-row-clickable"
  });

  const style = generateStyle({
    name: "backgroundColor",
    value: hexWithOpacity(color || "", 0.5, true),
    applyCondition: !!color
  });

  return (
    <tr className={className} onClick={onClick} style={style}>
      {children}
    </tr >
  )
}