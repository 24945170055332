import { Form, Formik } from "formik";
import React, { useRef } from "react";
import * as yup from "yup";
import { ticketsAddMessage } from "../../../api/Api";
import { ITicketMessageAddRequest } from "../../../api/ApiRequests";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useTicketMessages } from "../../../state/swr/tickets/messages/useTicketMessages";
import { useUser } from "../../../state/swr/user/useUser";
import { ITicket } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import FileSelectButton from "../../buttons/FileSelectButton";
import Flex from "../../container/Flex";
import WithPermissions from "../../permissions/WithPermissions";
import Editor from "../../richText/Editor";
import UserAvatar from "../../user/profile/UserAvatar";
import TicketChatAttachmentsField from "./TicketChatAttachmentsField";
import "./TicketChatForm.css";
import TicketMessageMentions from "./messages/TicketMessageMentions";


export default function TicketChatForm({ ticket }: { ticket: ITicket }) {

  const permissions = useTicketPermission("update", ticket);

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [isTyping, setIsTyping] = React.useState<boolean>(false);
  const [mouseOverForm, setMouseOverForm] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);
  const [dragging, setDragging] = React.useState<boolean>(false);

  const { user } = useUser();
  const { reloadMessages } = useTicketMessages(ticket._id);

  const dragTarget = useRef<HTMLDivElement>(null);

  const callApi = useApi();

  const cardClass = generateClassName("w-100 d-flex flex-column position-relative gap-2 ticket-chat-form position-relative ticket-attachments-drag-drop-field", {
    value: dragging && hover,
    onTrue: "ticket-attachments-drag-drop-field-hover"
  });

  const preventDefaults = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const toggleDrag = (e: any, val: boolean) => {
    preventDefaults(e);
    setDragging(val);
  }

  const toggleHover = (e: any, val: boolean) => {
    if (e.buttons !== 1) setDragging(false);
    setHover(val);
  }

  const ticketMessageFieldClass = generateClassName("ticket-chat-message-input", {
    value: expanded,
    onTrue: "ticket-chat-message-input-expanded"
  })
  return (
    <WithPermissions permissions={permissions}>
      <Formik
        initialValues={{
          text: "",
          attachments: [],
          mentions: [],
          id: ticket._id
        } as ITicketMessageAddRequest}
        validationSchema={yup.object().shape({
          text: yup.string().required("Bitte geben Sie eine Nachricht ein.")
        })}
        onSubmit={async (values, actions) => {
          if (!values.text) return;

          const realText = values.text.trim();

          if (!realText) return;

          const res = await callApi(ticketsAddMessage({ ...values, text: realText }));

          if (!res || !res.success) return;

          await reloadMessages();
          actions.resetForm();
        }}
      >
        {
          (formik) => {

            const onBlur = (e: any) => {
              if (!mouseOverForm) setExpanded(false);
              formik.handleBlur(e);
            }

            const onFocusChat = () => {
              setExpanded(true);
              setIsTyping(true);
            }

            const dragEntry = (e: any) => {
              toggleDrag(e, true);
              toggleHover(e, true);
            }

            const dragLeave = (e: any) => {
              toggleDrag(e, false);
              toggleHover(e, false);
            }

            return (
              <Form
                className="w-100 d-flex flex-column"
                onMouseEnter={(e) => {
                  toggleHover(e, true);
                  setMouseOverForm(true);
                }}
                onMouseOver={() => setMouseOverForm(true)}
                onMouseLeave={() => {
                  if (!isTyping) setExpanded(false);
                  setMouseOverForm(false);

                }}
              >
                <Flex row className="w-100" align="start">
                  <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@tiggi.de"} />
                  <div
                    className={cardClass}
                    onDragEnter={(e) => dragEntry(e)}
                    ref={dragTarget}
                  >
                    <div
                      className="d-flex flex-column w-100 h-100 position-relative gap-2"
                      onDragEnter={(e) => dragEntry(e)}
                      onDragOver={(e) => dragEntry(e)}
                    >
                      {
                        (dragging && hover) && (
                          <div
                            className="position-absolute top-0 start-0 w-100 h-100 bg-white d-flex flex-row align-items-center justify-content-center w-100 h-100"
                            style={{ zIndex: 2 }}
                            onDragLeave={(e) => dragLeave(e)}
                          >
                            <strong style={{ color: "var(--primaryAccent)" }}>Anhang anfügen</strong>
                          </div>
                        )
                      }
                      <Editor
                        className={ticketMessageFieldClass}
                        onChange={val => formik.setFieldValue("text", val)}
                        content={formik.values.text}
                        disabled={formik.isSubmitting}
                        onBlur={onBlur}
                        onFocus={onFocusChat}
                      />
                      <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <TicketChatAttachmentsField name="attachments" />
                      </div>
                      <Flex row justify="between" align="start" fullWidth>
                        <TicketMessageMentions mentions={formik.values.mentions} ticket={ticket} saveMentions={(mentions) => formik.setFieldValue("mentions", mentions)} />
                        <div className="d-flex flex-row align-items-center justify-content-end gap-2">
                          <FileSelectButton
                            onChange={attachments => formik.setFieldValue("attachments", attachments)}
                            dragTarget={dragTarget}
                            values={formik.values.attachments}
                            accept="*"
                            text="Anhang hinzufügen"
                            maxBytes={1000 * 1000 * 50}
                            icon="paperclip"
                          />
                          <Button className="align-self-end" icon="send" type="submit" text="Senden" loading={formik.isSubmitting} loadingText="Sendet..." />
                        </div>
                      </Flex>
                    </div>
                  </div>
                </Flex>
              </Form>
            )
          }
        }
      </Formik>
    </WithPermissions>
  )
}