import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { IDatevImport } from "../../../types/datevImport.schema";

export const useDatevImports = (opts?: TiggiQueryOpts) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IDatevImport>>(ApiRoutes.Datev.Import.All, opts);

    return {
        datevImports: data,
        loadingDatevImports: isLoading,
        reloadDatevImports: mutate
    }
}