import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IUserStatistics } from "../../../types/ApiTypes";

export const useTenantUserStatistics = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserStatistics>(ApiRoutes.Statistics.CurrentTenant, { refetchOnWindowFocus: false });

    return {
        tenantUserStatistics: data,
        loadingTenantUserStatistics: isLoading,
        reloadTenantUserStatistics: mutate
    }
}