import { IMongooseDocument } from "./ApiTypes";

export enum BlacklistScope {
  Tenant = "tenant",
  OfficeAccount = "officeAccount"
}

export enum BlacklistType {
  Email = "email",
  Domain = "domain",
  IP = "ip",
  Phone = "phone"
}

export interface IBlacklistEntry {
  value: string,
  type: BlacklistType,
}

export interface IBlacklistDocument extends IBlacklistEntry {
  officeAccount?: string,
  scope: BlacklistScope
}

export interface IBlacklist extends IBlacklistDocument, IMongooseDocument {
}

