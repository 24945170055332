import React from "react";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import { ITicketAssignmentAddRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import { ticketAssignmentsAdd, ticketAssignmentsUpdate } from "../../../api/Api";
import { useTicketAssignee } from "../../../state/swr/tickets/assignments/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/assignments/useTicketClientContact";
import { useTicketAssignments } from "../../../state/swr/tickets/assignments/useTicketAssignments";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import useTicketPermission from "../../../hooks/useTicketPermissions";

export interface IUpdateTicketAssignmentButtonProps extends Omit<Omit<ITicketAssignmentAddRequest, "ticketId">, "isCc"> {
    color: AppColor,
    ticket: ITicket,
    assignment: ITicketAssignment,
    text: string,
    icon: string
}

export default function UpdateTicketAssignmentButton({color, icon, text, clientId, assignment, isMainAssignee, isMainClientContact, ticket, userId }: IUpdateTicketAssignmentButtonProps) {
    const permissions = useTicketPermission("update", ticket);
    const { reloadTicketAssignee } = useTicketAssignee(ticket);
    const { reloadTicketClientContact } = useTicketClientContact(ticket);
    const { reloadTicketAssignments } = useTicketAssignments(ticket);

    const callApi = useApi();

    const doAction = async () => {
        const res = await callApi(ticketAssignmentsUpdate({
            ticketId: ticket._id,
            clientId,
            isCc: !isMainAssignee && !isMainClientContact,
            id: assignment._id,
            isMainAssignee,
            isMainClientContact,
            userId
        }));

        if (!res || !res.success) return;

        await reloadTicketAssignee();
        await reloadTicketAssignments();
        await reloadTicketClientContact();
    }

    if (!assignment.isActive) return null;

    return (
        <WithPermissions permissions={permissions}>
            <Button variant="text" text={text} color={color} icon={icon} onClick={doAction} />
        </WithPermissions>
    )
}