import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITenant } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";

export const useTenants = () => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<ITenant>>(ApiRoutes.Tenant.All);

  return {
    tenants: data?.items ?? [],
    tenantsById: data?.byId ?? {},
    loadingTenants: isLoading,
    reloadTenants: mutate
  }
}