import { FormikProps } from "formik";
import React from "react";
import { clientsUpdateSettings, IApiResponse } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useClient } from "../../state/api/clients/useClient";
import { useClients } from "../../state/swr/clients/useClients";
import { IWithClient } from "../../types/client.element.schema";
import { IClient } from "../../types/client.schema";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import SalutationSelect from "../salutations/SalutationSelect";
import Typography from "../text/Typography";
import "./ImportClientsForm.css";
import MailTemplateSelect from "../templates/MailTemplateSelect";

export interface IUpdateClientFormProps extends IWithClient { }

export default function UpdateClientForm(props: IUpdateClientFormProps) {

  const {
    client
  } = props;

  const { reloadClients } = useClients();
  const { reloadClient } = useClient(client._id);

  const callApi = useApi();

  return (
    <WithPermissions permissions={["clients.all.update"]} >
      <ModalForm
        button={<Button text="Mandat bearbeiten" icon="pen" />}
        title="Mandant anpassen"
        initialValues={client as IClient}
        enableReinitialize
        onSubmit={async (values) => {

          const res: IApiResponse | null = await callApi(clientsUpdateSettings(values as IClient));

          if (!res || !res.success) return false;

          await reloadClients();
          await reloadClient();

          return true;
        }}
      >
        {
          (formik) => (
            <Flex fullWidth gap="4">
              <Typography color="primary" size="18" bold>Mails</Typography>
              <SalutationSelect
                value={formik.values.settings?.mails?.defaultSalutation ?? ""}
                onChange={e => formik.setFieldValue("settings.mails.defaultSalutation", e)}
              />
              <MailTemplateSelect 
                bold
                label="Vorlage für Dokumentenfreigabe"
                value={formik.values.settings?.mails?.templateForDocumentClearance ?? ""}
                onChange={e => formik.setFieldValue("settings.mails.templateForDocumentClearance", e)}
              />
            </Flex>
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}