import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IDocumentClearanceReminder } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useDocumentClearanceReminder = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IDocumentClearanceReminder>(ApiRoutes.DocumentClearance.ById.getValue({param: RouteParam.DocumentClearanceReminder, value: id}));

    return {
        documentClearanceReminder: data,
        loadingDocumentClearanceReminder: isLoading,
        reloadDocumentClearanceReminder: mutate
    }
}