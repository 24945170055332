import React from "react";
import { DbId, ITicket } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import Pill from "../pills/Pill";
import Flex from "../container/Flex";
import TicketFriendlyIdBanner from "./banner/TicketFriendlyIdBanner";
import { useTicket } from "../../state/swr/tickets/useTicket";
import { getId } from "../../util/mongoUtil";

export interface ITicketLink {
    ticket: DbId<ITicket>
}

export default function TicketLink(props: ITicketLink) {

    const {
        ticket
    } = props;

    const ticketId = getId(ticket);

    const {
        ticket: ticketData,
        loadingTicket
    } = useTicket(ticketId);

    const navigate = useNavigate();

    if (!loadingTicket && !ticketData) return null;

    return (
        <Pill 
            loading={loadingTicket}
            onClick={() => navigate(`/tickets/${ticketId}`)} 
            color="#D0D0E0"
        >
            {
                ticketData && (
                    <Flex row>
                        <Icon icon="link" size={20} hexColor="#00AAFF" />
                        <TicketFriendlyIdBanner ticket={ticketData.ticket} />
                    </Flex>
                )
            }
        </Pill>
    )
}