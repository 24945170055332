import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUserClients } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useUserClients = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserClients>(ApiRoutes.User.Authority.CurrentUserClients);
    
    return {
        currentUserClients: data,
        loadingCurrentUserClients: isLoading,
        reloadCurrentUserClients: mutate
    }
} 