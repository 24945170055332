import { IPermissions, Permission } from "../types/ApiTypes";
import { PermissionCheckMode } from "./useAuthorized";

export default function usePermissions() {
    
    const checkPermission = (p: {[key: string]: any}, key: string): boolean => {
        if(typeof p === 'undefined') return false;
        
        var nextKey = key.indexOf('.')

        if(nextKey > -1) {
            return checkPermission(p[key.substring(0, nextKey)], key.substring(nextKey + 1));
        }

        return p[key];
    }

    const hasPermissions = (available: IPermissions, required: Array<Permission>, mode: PermissionCheckMode) => {
        if (!available) return false;
        if (!required || !required.length) return true;

        let hasPermission = false;

        for (const r of required) {
            hasPermission = !!checkPermission(available, r);

            if (mode === "all") {
                if (!hasPermission) return false;
                else continue;
            }

            if (hasPermission) return true;
        }

        return false;
    };

    return { 
        hasPermissions
    }
}