import React from "react";
import useFilteredOfficeMails, { OfficeMailSort } from "../../../../hooks/useFilteredOfficeMails";
import SortDirectionToggle from "../../../buttons/SortDirectionToggle";
import Select from "../../../comboBox/Select";
import Flex from "../../../container/Flex";

export default function OfficeInboxSortToggle() {

    const { sort, direction, updateFilter } = useFilteredOfficeMails();

    return (
        <Flex row>
            <Select
                values={[
                    { label: "Datum", data: OfficeMailSort.Date },
                    { label: "Absender", data: OfficeMailSort.From },
                    { label: "Betreff", data: OfficeMailSort.Subject },
                    { label: "Empfänger", data: OfficeMailSort.To },
                ]} 
                value={sort}
                onChange={s => updateFilter({ sort: s as OfficeMailSort}) }
            />
            <SortDirectionToggle saveDirection={d => updateFilter({ sortDirection: d })} direction={direction} />
        </Flex>
    )   
}