import React from "react";
import { officeAccountsDownloadAllAttachments } from "../../../../api/Api";
import useApi from "../../../../hooks/useApi";
import useDownloads from "../../../../hooks/useDownloads";
import Button from "../../../buttons/Button";
import { IOfficeAccount } from "../../../../types/officeAccount.types";

export interface IDownloadAllAttachmentsButtonProps {
    mailId: string,
    account: IOfficeAccount,
}

export default function DownloadAllAttachmentsButton({account, mailId}: IDownloadAllAttachmentsButtonProps) {

    const {
        downloadFile
    } = useDownloads();

    const callApi = useApi();

    const downloadAllAttachments = async () => {
        const res = await callApi(officeAccountsDownloadAllAttachments({id: account._id, messageId: mailId}));
        if (!res || !res.success) return;
        downloadFile(res.data, res.data.content);
    }

    return (
        <Button variant="text" text="Alle Anhänge herunterladen" icon="download" onClick={downloadAllAttachments} style={{fontSize: "0.8em"}} />
    )
}