import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IImportValidationError, IValidationErrorEntities } from "../../../types/ApiTypes";

export const useImportValidationError = (entities: IValidationErrorEntities) => {
    const getUrl = () => {
        if (!entities) return null;
        if (entities.addressee) return ApiRoutes.ImportValidationError.ForAddressee.getValue({ param: RouteParam.Addressee, value: entities.addressee });
        else if (entities.client) return ApiRoutes.ImportValidationError.ForClient.getValue({ param: RouteParam.ClientId, value: entities.client });
        else if (entities.employee) return ApiRoutes.ImportValidationError.ForEmployee.getValue({ param: RouteParam.Employee, value: entities.employee });
        else return null;
    }

    const { data, isLoading, mutate } = useTiggiQuery<Array<IImportValidationError>>(getUrl());

    return {
        importValidationErrors: data,
        loadingImportValidationErrors: isLoading,
        reloadImportValidationErrors: mutate
    }
}