import React from "react";
import { ICoupon } from "../../types/ApiTypes";
import Card from "../card/Card";
import CreatePromotionCodeButton from "./CreatePromotionCodeButton";
import PromotionCodeCard from "./PromotionCodeCard";
import DeleteCouponButton from "./DeleteCouponButton";
import CouponAmount from "./CouponAmount";
import CouponDuration from "./CouponDuration";
import { usePromotionCodes } from "../../state/swr/subscriptions/usePromotionCodes";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useUser } from "../../state/swr/user/useUser";
import WithPermissions from "../permissions/WithPermissions";
import Flex from "../container/Flex";
import Typography from "../text/Typography";

export default function CouponGroup({coupon}: {coupon: ICoupon}) {

    const { 
        loadingPromotionCodes, 
        promotionCodes, 
        reloadPromotionCodes 
    } = usePromotionCodes(coupon._id);
    
    return (
        <WithPermissions superAdminOnly>
            <Card 
                header={
                    <Flex row justify="between">
                        <Flex gap="1">
                            <Typography size="18">Code: <strong>{coupon.name}</strong></Typography>
                            <Flex row justify="between">
                                <CouponAmount coupon={coupon} />
                                <CouponDuration coupon={coupon} />
                            </Flex>
                        </Flex>
                        <Flex row>
                            <CreatePromotionCodeButton coupon={coupon} />
                            <DeleteCouponButton coupon={coupon} />
                        </Flex>
                    </Flex>
                }
            >
                <Typography color="primary" upper bold>
                    Gutscheincodes
                </Typography>
                <Flex row wrap fullWidth>
                    {
                        loadingPromotionCodes
                        ? <LoadingSpinner />
                        : (
                            !!promotionCodes?.length
                            ? promotionCodes.map(p => <PromotionCodeCard key={p._id} code={p} />)
                            : <span>Keine Gutscheincodes. Hinterlegen Sie einen Gutscheincode, um das Verwenden dieser Rabattgruppe zu ermöglichen.</span>
                        )
                    }
                </Flex>
            </Card>
        </WithPermissions>
    )
}