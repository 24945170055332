import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserStatistics } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserStatistics = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserStatistics>(ApiRoutes.Statistics.AnyUser.getValue({ 
        param: RouteParam.UserId, 
        value: id
    }), { refetchOnWindowFocus: false });

    return {
        userStatistics: data,
        loadingUserStatistics: isLoading,
        reloadUserStatistics: mutate
    }
}