import React from "react";
import AddDomainForm from "../../components/domain/AddDomainForm";
import DomainList from "../../components/domain/DomainList";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useDomains } from "../../state/swr/domains/useDomains";

export default function AllDomainsManagement() {
    const { domains, loadingDomains } = useDomains();

    return (
        <Page header={Locale.titles.domainManagement} actionButtons={<AddDomainForm />}>
            {
                loadingDomains
                ? <LoadingSpinner />
                : <DomainList domains={domains || []} />
            }
        </Page>
    )
}