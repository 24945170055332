import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { Sorted } from "../../../types/collection.types";
import { ITeam } from "../../../types/team.schema";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTeams = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<ITeam>>(ApiRoutes.Teams.All);

    return {
        teams: data?.items ?? [],
        teamsById: data?.byId ?? {},
        loadingTeams: isLoading,
        reloadTeams: mutate
    }
} 