import React from "react";
import { CouponType, ICoupon } from "../../types/ApiTypes";
import { formatCurrency, formatNumber } from "../../util/formatter";
import Pill from "../pills/Pill";

export default function CouponAmount({coupon}: {coupon: ICoupon}) {

    if (!coupon) return null;

    const getType = () => coupon.type === CouponType.PercentOff && "%";
    const getValue = () => coupon.type === CouponType.AmountOff ? formatCurrency(coupon.amount) : formatNumber(coupon.amount);

    return (
        <Pill 
            color="#F0F0F0"
        >
            <strong>{getValue()} {getType()}</strong>
        </Pill>
    )
}