import { addNotification, INotification } from "../state/slices/notificationSlice";
import { useAppDispatch } from "./reduxHooks";

export default function useModalNotifications() {
    const dispatch = useAppDispatch();

    return (notification: INotification) => dispatch(addNotification({ 
        ...notification, 
        id: notification.id ?? Date.now() + Math.floor(Math.random() * 100000) 
    }));   
}