import DOMPurify from "dompurify";
import { Form, Formik } from "formik";
import React from "react";
import { ticketMessagesUpdate } from "../../../../api/Api";
import { ITicketMessageUpdateRequest } from "../../../../api/ApiRequests";
import useApi from "../../../../hooks/useApi";
import { generateClassName } from "../../../../hooks/useAttributes";
import useTicketMessageUtil from "../../../../hooks/useTicketMessageUtil";
import Button from "../../../buttons/Button";
import Editor from "../../../richText/Editor";
import TicketChatHtmlMessageContent from "./TicketChatHtmlMessageContent";
import "./TicketMessageText.css";
import { useTicket } from "../../../../state/swr/tickets/useTicket";
import { useTicketMessages } from "../../../../state/swr/tickets/messages/useTicketMessages";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export interface ITicketMessageTextProps {
  message: ITicketMessage,
  ticketId: string,
  text?: string,
  canEdit?: boolean,
  shorten?: boolean
}

export default function TicketMessageText(props: ITicketMessageTextProps) {
  const {
    message,
    text = "",
  } = props;

  const {
    getCurrentMessage
  } = useTicketMessageUtil();

  if (!message) return <span>Kein Text</span>;

  const realText = !!text ? text : getCurrentMessage(message);

  if (!realText) return <span>Kein Text</span>;

  return (
    <EditableTicketMessageText
      {...props}
      text={realText}
    />
  );
}

function EditableTicketMessageText(props: ITicketMessageTextProps) {

  const {
    message,
    ticketId,
    canEdit,
    shorten,
    text
  } = props;

  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const {
    reloadMessages
  } = useTicketMessages(ticketId);

  const showTimeout = React.useRef<any>();
  const hideTimeout = React.useRef<any>();

  const callApi = useApi();
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const resizeInput = () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.style.height = '';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  }

  React.useEffect(() => resizeInput(), [inputRef])

  const setHover = (val: boolean) => {
    if (!canEdit) {
      setIsHovering(false);
      return;
    }

    if (!val && isEditing) return;

    clearTimeout(hideTimeout.current);
    clearTimeout(showTimeout.current);

    if (!val) hideTimeout.current = setTimeout(() => setIsHovering(false), 120);
    else showTimeout.current = setTimeout(() => setIsHovering(true), 400);
  }

  const toggleEdit = (val: boolean) => {
    if (!val) return setIsEditing(false);

    setIsEditing(true);

    setTimeout(() => {
      if (!inputRef || !inputRef.current) return;
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
    }, 0);
  }

  if (!text) return null;

  const inputClassName = generateClassName("editable-ticket-message-field", {
    value: !canEdit,
    onTrue: "editable-ticket-message-field-read-only"
  }, {
    value: shorten,
    onTrue: "editable-ticket-message-field-short text-truncate"
  });

  return (
    <Formik
      initialValues={{
        text: text,
        id: message._id
      } as ITicketMessageUpdateRequest}
      enableReinitialize
      onSubmit={async (values) => {

        const text = DOMPurify.sanitize(values.text).toString().trim();

        if (!text) return;

        const res = await callApi(ticketMessagesUpdate({
          id: values.id,
          text: text
        }));

        if (!res) return;

        await reloadMessages();
        toggleEdit(false);
      }}
    >
      {
        formik => {

          const penClick = () => {
            if (isEditing) {
              formik.resetForm();
              toggleEdit(false);
              return;
            }

            toggleEdit(true);
          }

          return (
            <Form
              className="position-relative w-100 d-flex flex-column gap-2 align-items-start"
              onMouseEnter={() => setHover(true)}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {
                isEditing
                  ? (
                    <Editor
                      className={inputClassName}
                      onChange={val => formik.setFieldValue("text", val)}
                      disabled={formik.isSubmitting || !isEditing}
                      content={formik.values.text}
                    />
                  )
                  : <TicketChatHtmlMessageContent message={formik.values.text} isEditForm={!!canEdit} isPreview={!!shorten} />
              }
              <div className="w-100 d-flex ticket-message-text-edit-options-container gap-2 flex-row align-items-center justify-content-end p-0 m-0">
                {
                  isEditing && <Button icon="check" variant="icon" iconSize={28} loading={formik.isSubmitting} color="success" type="submit" />
                }
                {
                  (isHovering || isEditing) && (
                    <Button
                      text={isEditing ? "Abbruch" : "Bearbeiten"}
                      iconSize={isEditing ? 18 : 14}
                      icon={isEditing ? "x" : "pen"}
                      color={isEditing ? "error" : "bright"}
                      onClick={async () => penClick()}
                    />
                  )
                }
              </div>
            </Form>
          )
        }
      }
    </Formik>
  )
}