import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicketNote } from "../../../types/ticketNotes.schema";

export const useTicketNote = (ticketId: string, ticketNoteId: string) => {
  const { data, isLoading, mutate } = useTiggiQuery<ITicketNote>(ApiRoutes.Tickets.Notes.ById.getValue({
    param: RouteParam.TicketId,
    value: ticketId
  }, {
    param: RouteParam.TicketNoteId,
    value: ticketNoteId
  }));

  return {
    ticketNote: data,
    loadingTicketNote: isLoading,
    reloadTicketNote: mutate
  }
}