import { Message, Recipient } from "@microsoft/microsoft-graph-types";
import { Form, Formik } from "formik";
import React from "react";
import { officeAccountsSendMailForAccount } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { IMailData } from "../../../types/ApiTypes";
import { IOfficeAccount } from "../../../types/officeAccount.types";
import { formatMailAddress, formatOfficeMailAddressByRecipient } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import FieldWithLabel from "../../formik/FormikField";
import Icon from "../../icons/Icon";
import WithPermissions from "../../permissions/WithPermissions";
import Editor from "../../richText/Editor";
import "./OfficeMailForm.css";
import AttachmentForm from "./AttachmentSelect";

export interface IOfficeMailFormValues extends IMailData {
    cc: Recipient[]
}

export default function OfficeMailForm({item, account, respondAll, discardMail}: {item?: Message, respondAll: boolean, account: IOfficeAccount, discardMail: () => void}) {
    
    const {
        getAccountPermissions
    } = useInboxPermissions();

    const [minimized, setMinimized] = React.useState<boolean>(false);

    const getCcRecipients = () => {
        if (!respondAll) return [];
        if (!item) return [];

        const ccRecipients = item && item.ccRecipients || [];
        const toRecipients = item && item.toRecipients && item.toRecipients.length ? item.toRecipients.filter(r => r.emailAddress && r.emailAddress.address !== account.accountMail) : [];

        return [...ccRecipients, ...toRecipients];
    }

    React.useEffect(() => {
        setMinimized(false);
    }, [item]);

    const callApi = useApi();

    const className = generateClassName("w-100 office-mail-form", {
        value: minimized,
        onTrue: "office-mail-form-minimized"
    })

    const contentClass = generateClassName("w-100 office-mail-form-content", {
        value: minimized,
        onTrue: "office-mail-form-content-minimized"
    })

    const getSubject = () => {
        if (!item || !item.subject) return "";
        
        const upperSubject = item.subject.toUpperCase().trim();

        if (upperSubject.startsWith("RE:") || upperSubject.startsWith("AW:")) return item.subject;

        return `RE: ${item.subject}`;
    }

    const permissions = getAccountPermissions("update", account);

    return (
        <WithPermissions permissions={permissions} >
            <Formik
                initialValues={{
                    subject: getSubject(),
                    attachments: [],
                    to: item ? item.from?.emailAddress?.address : "",
                    fromMail: account.accountMail,
                    fromName: account.accountName,
                    html: "",
                    cc: getCcRecipients()
                } as IOfficeMailFormValues}
                onSubmit={async (values) => {
                    const bodyWithLineBreaks = values.html && values.html.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    values.html = bodyWithLineBreaks;
                    const res = await callApi(officeAccountsSendMailForAccount({ 
                        id: account._id, 
                        mail: values, 
                        originalSubject: item && item.subject ? item.subject : undefined,
                        inResponseToMailId: item && item.id ? item.id : undefined
                    }), true);
                    
                    if (!res || !res.success) return;
                    discardMail();
                }}
            >
                {
                    formik => (
                        <Form className={className} >
                            <Card className="w-100 h-100">
                                <Flex className="w-100 h-100">
                                    <Flex row className="w-100" justify="between">
                                        <Flex row className="w-100">
                                            <Icon onClick={() => setMinimized(!minimized)} icon={minimized ? "chevron-up" : "chevron-down"} />
                                            {
                                                minimized
                                                ? <span className="fw-bold">{formik.values.subject || "Kein Betreff"}</span>
                                                : <FieldWithLabel inline label="Betreff" className="w-100" bold name="subject" placeholder="Betreff..."/>
                                            }
                                        </Flex>
                                        {
                                            minimized && <Icon icon="trash" onClick={() => discardMail()} />
                                        }
                                    </Flex>
                                    <Flex className={contentClass}>
                                        <div style={{width: "100%", height: "1px", backgroundColor: "var(--backgroundDarker)"}} />
                                        {
                                            account.accountMail && (
                                                <Flex row>
                                                    <span>Von</span>
                                                    <span>{formatMailAddress(account.accountName, account.accountMail)}</span>
                                                </Flex>
                                            )
                                        }
                                        <FieldWithLabel bold label="An" name="to" className="w-100" placeholder="An..." inline />
                                        {
                                            formik.values.cc && !!formik.values.cc.length && (
                                                <Flex row>
                                                    <span>Cc</span>
                                                    <span>{formik.values.cc.map(c => formatOfficeMailAddressByRecipient(c)).join(", ")}</span>
                                                </Flex>
                                            )
                                        }
                                        <AttachmentForm 
                                            label="Anhänge"
                                            disabled={formik.isSubmitting}
                                        />
                                        <div style={{width: "100%", height: "1px", backgroundColor: "var(--backgroundDarker)"}} />
                                        <Editor
                                            onChange={val => formik.setFieldValue("html", val)}
                                            content={formik.values.html}
                                            className="office-mail-form-field"
                                            disabled={formik.isSubmitting}
                                        />
                                        <Flex row justify="between" className="w-100">
                                            <Button icon="send" type="submit" text="Mail senden" loading={formik.isSubmitting} />
                                            <Icon icon="trash" onClick={() => discardMail()} />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>
                        </Form>
                    )
                }
            </Formik>
        </WithPermissions>
    )

}