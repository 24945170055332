import React from "react";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useUsers } from "../../state/swr/user/useUsers";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import TeamBanner from "./TeamBanner";
import TeamMemberList from "./TeamMemberList";
import TeamUpdateForm from "./TeamUpdateForm";
import TeamUpdateMembersFromResponsibilityButton from "./TeamUpdateMembersFromResponsibilityButton";
import { ITeam } from "../../types/team.schema";


export default function TeamsListItem({ team }: { team: ITeam }) {

  const {
    usersById,
    loadingUsers
  } = useUsers();

  const {
    areasOfResponsibilityById,
    loadingAreasOfResponsibility
  } = useAreasOfResponsibility();

  const areaOfResponsibility = team.areaOfResponsibility ? areasOfResponsibilityById[team.areaOfResponsibility as string] : null;

  return (
    <TableRow>
      <TableCell>
        <Flex gap={1}>
          <TeamBanner team={team} />
          {
            team.areaOfResponsibility ?
              (
                <Pill
                  color="#330053"
                  withDivider
                  loading={loadingAreasOfResponsibility}
                  icon="star"
                  text={areaOfResponsibility?.name}
                />
              )
              : <span>-</span>
          }
        </Flex>
      </TableCell>
      <TableCell>
        {
          team.supervisor
            ? (
              loadingUsers
                ? <LoadingSpinner />
                : <UserRoleIndicator user={usersById[team.supervisor]} displayNameAsValue />
            )
            : <span>-</span>
        }
      </TableCell>
      <TableCell>
        <TeamMemberList members={team.members} />
      </TableCell>
      <TableCell>
        <Flex row wrap justify="end">
          <TeamUpdateMembersFromResponsibilityButton team={team} />
          <TeamUpdateForm team={team} />
        </Flex>
      </TableCell>
    </TableRow >
  )
}
