import React from "react";
import Button from "./Button";

export interface IEditButtonProps{
  readOnly?: boolean,
  isCreate?: boolean,
  onClick?: () => (any | Promise<any>)
}

export default function EditButton(props: IEditButtonProps) {
  const {
    isCreate,
    readOnly,
    onClick
  } = props;


  const getEditButtonIcon = () => {
    if (readOnly) return "eye";
    if (isCreate) return "plus";
    return "pen";
  }

  const getEditButtonColor = () => {
    if (readOnly) return "secondary";
    if (isCreate) return "success";
    return "primary";
  }

  const getEditButtonText = () => {
    if (readOnly) return "Ansehen";
    if (isCreate) return "Erstellen";
    return "Bearbeiten";
  }

  return (
    <Button
      text={getEditButtonText()}
      icon={getEditButtonIcon()}
      onClick={onClick}
      variant={readOnly ? "subtle" : "solid"}
      color={getEditButtonColor()}
    />
  )
}
