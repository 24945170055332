import React from "react";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../api/Api";
import { AppRoutes, IAppRoute } from "../../config/AppRoutes";
import useApi from "../../hooks/useApi";
import useUserUtil from "../../hooks/useUserUtil";
import { useSession } from "../../state/swr/session/useSession";
import { useUser } from "../../state/swr/user/useUser";
import { Cookies, deleteCookie } from "../../util/cookies";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Divider from "../sidebar/Divider";
import Typography from "../text/Typography";
import UserAvatar from "./profile/UserAvatar";
import "./UserMenu.css";

export default function UserMenu() {

  const { reloadSession } = useSession();

  const callApi = useApi();

  const logOut = async () => {
    await callApi(userLogOut());
    deleteCookie(Cookies.Session);
    reloadSession(undefined, { revalidate: true });
  }

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const hideTimer = React.useRef<any>(null);

  React.useEffect(() => {
    return () => clearTimeout(hideTimer.current);
  }, []);

  const { user } = useUser();
  const { getName } = useUserUtil();

  return (
    <div
      onMouseLeave={() => {
        clearTimeout(hideTimer.current);
        hideTimer.current = setTimeout(() => setExpanded(false), 500);
      }}
      onMouseEnter={() => clearTimeout(hideTimer.current)}
      onClick={() => setExpanded(true)}
      className="position-relative user-menu-container"
    >
      <Flex className="user-menu-avatar" onClick={() => setExpanded(false)}>
        <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@test.de"} />
      </Flex>
      {
        expanded && (
          <Flex className="user-menu pb-2 position-absolute">
            <Flex row justify="between" className="ps-3" fullWidth>
              <Flex gap={0}>
                <Typography color="primary" bold>{getName(user)}</Typography>
                <Typography size={9} >{user?.mailAddress}</Typography>
              </Flex>
              <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@test.de"} />
            </Flex>
            <Divider height={1} color="muted" opacity={0.2} />
            <UserMenuItem to={AppRoutes.CurrentUserProfile} text="Profil" icon="person-fill" />
            <UserMenuItem to={AppRoutes.Settings} text="Einstellungen" icon="gear-fill" />
            <Divider height={1} color="muted" opacity={0.2} />
            <UserMenuItem
              text="Abmelden"
              icon="power"
              onClick={logOut}
            />
          </Flex>
        )
      }
    </div>
  )
}

function UserMenuItem({ icon, text, to, onClick }: { to?: IAppRoute, text: string, icon: string, onClick?: () => Promise<void> }) {

  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const clickHandler = async () => {
    if (to) {
      navigate(to.path);
      return;
    }

    if (!onClick) return;

    setLoading(true);
    await onClick();
    setLoading(false);
  }


  return (
    <Flex row onClick={clickHandler} className="user-menu-item ps-3 pe-3 pt-2 pb-2">
      <Flex align="center" justify="center" className="user-menu-item-icon">
        <Icon size={22} loading={loading} icon={icon} color="muted" />
      </Flex>
      <Typography color="primary">{text}</Typography>
    </Flex>
  )
}