import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import "./MultiStepWizardPage.css";

export interface IMultiStepWizardPageProps extends IElementProps {
    active?: boolean,
}

export default function MultiStepWizardPage({children, active}: IMultiStepWizardPageProps) {
    
    if (!active) return null;
    
    const className = generateClassName("multi-page-wizard-page d-flex flex-column h-100 w-100", {
        value: active,
        onTrue: "multi-page-wizard-page-active"
    });

    return (
        <div className={className}>
            {
                children
            }
        </div>
    )
}