import React from "react";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import { formatDateTiggiStyle } from "../../../util/formatter";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Typography from "../../text/Typography";
import TicketFriendlyIdBanner from "../banner/TicketFriendlyIdBanner";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";

export interface ITicketCardHeaderProps {
  ticket: ITicket,
  hover?: boolean,
  onHoverOverAction?: () => void,
  onStopHoverOverAction?: () => void,
  loading?: boolean
}

export default function TicketCardHeader(props: ITicketCardHeaderProps) {

  const {
    ticket,
    onHoverOverAction,
    onStopHoverOverAction,
    loading,
    hover
  } = props;

  const { role } = useCurrentTenantRole();

  const isClient = !!role && role.isClient;

  return (
    <Flex row justify="between" wrap className="w-100" style={{ minHeight: "28px" }}>
      <Flex row>
        <Flex row gap={1}>
          {loading && <LoadingSpinner color="secondary" />}
          <TicketFriendlyIdBanner ticket={ticket} bold size={12} />
          {
            !isClient && (
              <>
                <Typography bold>|</Typography>
                <TicketTypeBanner ticket={ticket} />
              </>
            )
          }
        </Flex>
      </Flex>
      <div
        className="d-flex flex-row align-items-center ms-auto gap-0"
        onMouseEnter={() => onHoverOverAction && onHoverOverAction()}
        onMouseLeave={() => onStopHoverOverAction && onStopHoverOverAction()}
      >
        {
          hover
            ? (
              <>
                <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Open} variant="text" />
                <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Resubmission} variant="text" />
                <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Solved} variant="text" />
                <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket isDelete variant="text" />
              </>
            )
            : <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
        }
      </div>
    </Flex>
  )
}
