import { ErrorMessage, Field, FieldAttributes, useFormikContext } from "formik";
import { generateClassName } from "../../hooks/useAttributes";
import React from "react";
import IElementProps from "../../types/element.types";
import "./CheckBox.css";
import Tooltip, { IWithTooltip } from "../tooltip/Tooltip";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import { AppColor } from "../../app/AppStyles";

interface ICheckBoxProps extends IElementProps, IWithTooltip {
    label?: string,
    labelClass?: string,
    name: string,
    readOnly?: boolean,
    onChange?: (val?: boolean) => void,
    textColor?: AppColor,
    bold?: boolean,
    type?: string
}

export default function CheckBox({label, textColor = "primary", labelClass, onChange, tooltip, readOnly, name, bold}: ICheckBoxProps ) {

    const {
        handleChange
    } = useFormikContext();

    const fieldId = `${name}-checkbox-field`;

    const labelClassName = generateClassName("form-label check-box-label ms-1 m-0", labelClass, {value: bold, onTrue: "fw-bold"});

    const changeHandler = (e: any) => {
        handleChange(e);
        if (onChange) onChange(!!e.target.checked);
    }

    const field = (
        <Field 
            disabled={readOnly} 
            id={fieldId} 
            onChange={changeHandler} 
            name={name}
            style={{
                marginTop: "3px",
                width: "14px",
                minWidth: "14px",
                height: "14px",
                minHeight: "14px",
            }} 
            type="checkbox" 
            className="form-check-input p-0" 
        />
    );
    
    if (!label) return field;

    return (
        <Tooltip tooltip={tooltip}>
            <Flex>
                <Flex row align="start" gap={1}>
                    { field }
                    <label htmlFor={fieldId} className={labelClassName} style={{userSelect: "none", color: `var(--${textColor})`, opacity: readOnly ? "0.6" : undefined}}>{label}</label>
                    {
                        tooltip && <Icon icon="info-circle" size={12} className="mt-1" />
                    }
                </Flex>
                <ErrorMessage name={name} render={(msg) => <span className="mt-2" style={{color: "#FF0000"}}>{msg}</span>} />
            </Flex>
        </Tooltip>
    )
}