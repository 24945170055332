import React from "react";
import useTicketUtil from "../../hooks/useTicketUtil";
import { TicketType } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import WithPermissions from "../permissions/WithPermissions";
import "./TicketTypeRadioGroup.css";
import { IAuthorizationOptions } from "../../hooks/useAuthorized";
import Flex from "../container/Flex";

export interface ITicketTypeRadioGroupProps {
    value: TicketType,
    saveValue: (t: TicketType) => void
}

interface ITicketTypeRadioData extends IAuthorizationOptions {
    type: TicketType
}

export default function TicketTypeRadioGroup({value, saveValue}: ITicketTypeRadioGroupProps) {

    const {
        getTicketTypeName,
        getTicketTypeColor
    } = useTicketUtil();

    const data: Array<ITicketTypeRadioData> = [
        { type: TicketType.TicketByClient },
        { type: TicketType.TicketToClient },
        { type: TicketType.InternalTicket, permissions: ["tickets.internal.create"] },
        { type: TicketType.ExternalTicket, permissions: ["tickets.external.create"] }
    ];

    return (
        <Flex row>
            {
                data.map(d => {
                    const isActive = value === d.type;

                    return (
                        <WithPermissions
                            key={d.type}
                            permissions={d.permissions}
                        >
                            <Button
                                onClick={() => saveValue(d.type)}
                                variant={isActive ? "solid" : "subtle"}
                                hexColor={getTicketTypeColor(d.type)}
                                text={getTicketTypeName(d.type)}
                            />
                        </WithPermissions>
                    )
                })
            }
        </Flex>
    )
}