import React from "react";
import { IPromotionCode } from "../../types/ApiTypes";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Typography from "../text/Typography";

export default function PromotionCodeCard({code}: {code: IPromotionCode}) {
    return (
        <Card 
            style={{minWidth: "225px"}}
            fitContent
            strongColor
        >
            <Flex fullWidth justify="between" row>
                <Typography size="16" bold>{code.code}</Typography>
                <CopyToClipboardButton value={code.code} />
            </Flex>
            <Flex row>
                <span>Gültig für: </span>
                <strong>
                    {
                        `${code.limitToTenant?.name ?? "Alle Tenants"}`
                    }
                </strong>
            </Flex>
        </Card>
    )
}