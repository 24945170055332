import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ISignature } from "../../../types/ApiTypes";

export const useTenantSignature = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ISignature>(ApiRoutes.Signatures.All);

    return {
        tenantSignature: data,
        loadingTenantSignature: isLoading,
        reloadTenantSignature: mutate
    }
}