import React from "react";
import { IApiResponse } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { BlacklistType, IBlacklist, IBlacklistEntry } from "../../types/blacklist.schema";
import { Sorted } from "../../types/collection.types";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Button from "../buttons/Button";
import BlacklistEntries from "./BlacklistEntries";
import LoadingSpinner from "../loader/LoadingSpinner";

export interface IBlacklistOverviewProps {
  loading: boolean,
  deleteBlacklistEntry: (id: string) => Promise<IApiResponse<any>>,
  createBlacklistEntry: (entry: IBlacklistEntry) => Promise<IApiResponse<any>>,
  reloadEntries: () => Promise<any>,
  entries?: { [key in BlacklistType]: Array<IBlacklist> }
}

export default function BlacklistOverview(props: IBlacklistOverviewProps) {

  const {
    createBlacklistEntry,
    deleteBlacklistEntry,
    reloadEntries,
    loading,
    entries
  } = props;

  return (
    <Flex fullWidth>
      {
        loading 
        ? <LoadingSpinner />
        : Object.values(BlacklistType).map(t => (
          <BlacklistEntries
            reloadEntries={reloadEntries}
            deleteEntry={deleteBlacklistEntry}
            saveEntry={createBlacklistEntry}
            type={t as BlacklistType}
            entries={entries?.[t as BlacklistType] ?? []}
          />
        ))
      }
    </Flex>
  )
}