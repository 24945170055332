import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";
import { ITicket, ITicketAssignment } from "../../../../types/ApiTypes";


export const useTicketAssignee = (ticket: ITicket) => {

  const { data, isLoading, mutate } = useTiggiQuery<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetMainAssignment.getValue({
    param: RouteParam.TicketId,
    value: ticket._id
  }));

  return {
    ticketAssignee: data,
    loadingTicketAssignee: isLoading,
    reloadTicketAssignee: mutate
  }
}

// export const useTicketAssignee = (ticket: ITicket) => {
//     const { data, isLoading, mutate } = useSWR<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetMainAssignment.getValue({
//         param: RouteParam.TicketId,
//         value: ticket._id
//     }), { revalidateOnFocus: false, revalidateIfStale: false });

//     return {
//         ticketAssignee: data,
//         loadingTicketAssignee: isLoading,
//         reloadTicketAssignee: mutate
//     }
// } 