import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { IEmployeeResponsibility } from "../../../types/employeeResponsibility.schema";

export const useClientEmployeeResponsibilities = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IEmployeeResponsibility>>(ApiRoutes.Clients.Responsibilities.ById.getValue({param: RouteParam.ClientId, value: id}), {
        refetchOnWindowFocus: false
    });

    return {
        clientEmployeeResponsibilities: data?.items ?? [],
        clientEmployeeResponsibilitiesById: data?.byId ?? {},
        loadingClientEmployeeResponsibilities: isLoading,
        reloadClientEmployeeResponsibilities: mutate
    }
}