import React from "react";
import Button from "../../components/buttons/Button";
import Typography from "../../components/text/Typography";
import { AppRoutes } from "../../config/AppRoutes";
import Page from "../../layout/Page";

export default function NoTenantFound() {
    return (
        <Page fullscreen centered className="p-5 text-center">
            <Typography bold size={50} color="primary">Willkommen bei Tiggi!</Typography>
            <h6> Sie befinden sich auf einer Domain, die auf unseren Service verweist.<br/>Wenn Sie diese Verknüpfung selbst angelegt haben und trotzdem dieses Fenster sehen, nehmen Sie bitte Kontakt mit uns auf.<br/>Nennen Sie uns dabei den Namen Ihres Tenants sowie Ihren Namen, damit wir unseren Service schnellstmöglich für Sie freischalten können.</h6>
            <div className="d-flex flex-row align-items-center gap-3 mt-3">
                <Button to="https://tiggi.io" className="fs-6" color="secondary" externalLink text="Über Tiggi" />
                <Button to={AppRoutes.RequestTenantAccess.path} className="fs-6" text="Jetzt Registrieren" />
            </div>
        </Page>
    )
}