import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IService } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useServices = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IService>>(ApiRoutes.Subscriptions.ServicesGetAll);

    return {
        services: data,
        loadingServices: isLoading,
        reloadServices: mutate
    }
}