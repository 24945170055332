import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { IClient } from "../../../types/client.schema";
import { Sorted } from "../../../types/collection.types";

export const useClients = (opts?: TiggiQueryOpts) => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IClient>>(ApiRoutes.Clients.All, opts);

    return {
        clients: data?.items ?? [],
        clientsById: data?.byId ?? {},
        loadingClients: isLoading,
        reloadClients: mutate
    }
}