import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppRouteParams, AppRoutes } from "../../config/AppRoutes";
import Page from "../../layout/Page";
import PageLoader from "../../components/loader/PageLoader";
import GiveDocumentClearanceButton from "../../components/documentClearance/GiveDocumentClearanceButton";
import { useDocumentClearanceReminder } from "../../state/swr/documentClearance/useDocumentClearanceReminder";
import { getMonthName } from "../../util/formatter";
import Error from "../Error";

export default function GiveDocumentClearance() {

    const clearanceReminderId = AppRouteParams.DocumentClearanceReminderId.getValue(useParams());

    const { 
        documentClearanceReminder, 
        loadingDocumentClearanceReminder
    } = useDocumentClearanceReminder(clearanceReminderId ?? "");

    if (loadingDocumentClearanceReminder) return <PageLoader />
    if (!clearanceReminderId || !documentClearanceReminder) return (
        <Error status={404} message="Dieser Link existiert nicht. Bitte überprüfen Sie die Quelle." />
    );

    return (
        <Page fullscreen className="p-5 d-flex flex-column align-items-start">
            <h1>Abgabe aller Belege bestätigen</h1>
            <h5>Hiermit bestätigen Sie, dass Sie alle Belege für {documentClearanceReminder.month ? `den Monat ${getMonthName(documentClearanceReminder.month)}` : "den aktuellen Monat"} an uns übermittelt haben.</h5>
            <GiveDocumentClearanceButton 
                isDone={documentClearanceReminder.isDone} 
                reminderId={clearanceReminderId} 
            />
        </Page>
    )
}