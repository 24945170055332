import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITenant } from "../../../types/ApiTypes";

export const useTenant = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ITenant>(ApiRoutes.Tenant.Active);

    return {
        tenant: data!,
        loadingTenant: isLoading,
        reloadTenant: mutate
    }
}