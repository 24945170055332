import React from "react";
import { azureGetSignInUrl } from "../../../api/Api";
import { IAzureGetSignInUrlRequest } from "../../../api/ApiRequests";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import { OfficeAccountType } from "../../../types/officeAccount.types";

export default function LinkOfficeAccountButton(props: IAzureGetSignInUrlRequest) {

    const callApi = useApi();

    const {
        getInboxPermissions
    } = useInboxPermissions();

    const isPersonalWithoutUser = props.type === OfficeAccountType.Personal && !props.onBehalfOfUser;
    const isReAuth = !!props.reAuthOfficeAccountId;

    const text = isReAuth 
    ? "Authentifizierung erforderlich" 
    : (
        isPersonalWithoutUser 
        ? "Bitte loggen Sie sich ein" 
        : "Office365-Account verknüpfen"
    );

    const color = isReAuth ? "error" : "primary";
    const icon = isReAuth ? "exclamation" : "plus";

    const permissions = getInboxPermissions("create", props.type, props.onBehalfOfUser);

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                disabled={!isReAuth && isPersonalWithoutUser}
                text={text}
                color={color}
                onClick={async () => {
                    const res = await callApi(azureGetSignInUrl(props));
    
                    if (!res || !res.success) return;
                    
                    window.location.href = res.data.url;
                }}
                icon={icon}
            />
        </WithPermissions>
    )
}