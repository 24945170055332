import { LogEntryType } from "../types/log.schema";

export default function useLogUtil() {
    const getTextFromLogEntryType = (type?: LogEntryType) => {
        if (!type) return "";
        switch (type) {
            case LogEntryType.Error: return "Error";
            case LogEntryType.General: return "Info";
            case LogEntryType.Event: return "Event";
            case LogEntryType.Schedule: return "Zeitplan";
        }
    }

    const getColorFromLogEntryType = (type?: LogEntryType) => {
        if (!type) return "";
        switch (type) {
            case LogEntryType.Error: return "#FF0000";
            case LogEntryType.General: return "#0E294B";
            case LogEntryType.Event: return "#F3A505";
            case LogEntryType.Schedule: return "#6C6960";
        }
    }
    
    const getIconFromLogEntryType = (type?: LogEntryType) => {
        if (!type) return "";
        switch (type) {
            case LogEntryType.Error: return "bug";
            case LogEntryType.General: return "info-circle";
            case LogEntryType.Event: return "lightning-charge";
            case LogEntryType.Schedule: return "stopwatch";
        }
    }

    return {
        getTextFromLogEntryType,
        getColorFromLogEntryType,
        getIconFromLogEntryType
    }
}