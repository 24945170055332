import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export const useCurrentUserOfficeAccount = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IOfficeAccount>(ApiRoutes.OfficeAccounts.Accounts.ForCurrentUser);

    return {
        currentUserOfficeAccount: data,
        loadingCurrentUserOfficeAccount: isLoading,
        reloadCurrentUserOfficeAccount: mutate
    }
}