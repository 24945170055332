import React from "react";
import useClientResponsibility from "../../hooks/useClientResponsibility";
import Page from "../../layout/Page";
import ClientResponsibilityList from "../../components/clientResponsibility/ClientResponsibilityList";
import { Locale } from "../../locale/Locale";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import RawClientResponsibilityList from "../../components/clientResponsibility/RawClientResponsibilityList";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import { ModalType } from "../../config/ModalTypes";
import { AppRoutes } from "../../config/AppRoutes";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";
import WithPermissions from "../../components/permissions/WithPermissions";

export enum ClientResponsibilityOverviewTab {
    MappedToClient = "pretty",
    RawData = "raw"
}

export default function ClientResponsibilityOverview() {

    const [activeTab, setActiveTab] = React.useState<string>(ClientResponsibilityOverviewTab.MappedToClient);

    return (
        <WithPermissions permissions={[ "clientResponsibilities.all.read" ]}>
            <Page header={Locale.titles.clientResponsibilityManagement}>
            <WithPermissions permissions={[ "interfaces.datev.read" ]}>
                <TabSwitcher 
                    tabQueryParamKey="format" 
                    tabs={[
                        {
                            data: ClientResponsibilityOverviewTab.MappedToClient,
                            label: "Mit Mandatszuweisung"
                        },
                        {
                            data: ClientResponsibilityOverviewTab.RawData,
                            label: "Rohdaten aus DATEV"
                        }
                    ]} 
                    saveActiveTab={t => setActiveTab(t)}
                />
            </WithPermissions>
                {
                    activeTab === ClientResponsibilityOverviewTab.MappedToClient
                    ? <ClientResponsibilityList />
                    : (
                        <WithPermissions permissions={[ "interfaces.datev.read" ]}>
                            <RawClientResponsibilityList />
                        </WithPermissions>
                    )
                }
            </Page>
        </WithPermissions>
    )
}