import React from "react";
import { usePlans } from "../../state/swr/subscriptions/usePlans";
import { IService, ITenant } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import EditablePlanCard from "../services/PlanCard";
import SubscribeToServiceButton from "./SubscribeToServiceButton";

export default function SubscriptionServiceGroup({service, tenant}: {service: IService, tenant: ITenant}) {

    const { plans, loadingPlans, reloadPlans } = usePlans(service._id);

    if (loadingPlans) return <LoadingSpinner />
    if (!plans || !plans.length) return null;

    return (
        <div className="d-flex flex-column gap-1">
            <strong>{service.name}</strong>
            <span>{ service.description || "Keine Beschreibung hinzugefügt." }</span>
            <strong>Ihre Optionen</strong>
            <div className="d-flex flex-row align-items-center w-100 gap-3" style={{overflowX: "auto"}}>
                {
                    plans.map(p => <EditablePlanCard key={p._id} plan={p}><SubscribeToServiceButton plan={p} tenant={tenant} /></EditablePlanCard>)
                }
            </div>
        </div>
    )

}