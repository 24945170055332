import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import useAuthorized from "../../hooks/useAuthorized";
import Icon from "../icons/Icon";
import "./Tab.css";
import { ITabData, TabSwitcherSize, TabSwitcherVariant } from "./TabSwitcher";
import Flex from "../container/Flex";

interface ITabProps<T = string | boolean | number | undefined> {
    content: ITabData,
    size: TabSwitcherSize,
    variant?: TabSwitcherVariant,
    defaultIconSize?: number,
    currentData: T,
    setActiveTab: (data: ITabData) => void
}

export default function Tab({content, defaultIconSize, size, currentData, variant, setActiveTab}: ITabProps) {

    const {
        isAuthorized,
        loading
    } = useAuthorized(content);

    const [isActive, setIsActive] = React.useState<boolean>(false);

    const setCurrentTabAsActive = () => setActiveTab(content);

    React.useEffect(() => {
        if (content.hidden) {
            setIsActive(false);
            return;
        }
        
        const tabIsActive = currentData !== undefined ? currentData === content.data.toString() : false;

        if (!tabIsActive) {
            setIsActive(false);
            return;
        }

        if (tabIsActive === isActive) return;

        setIsActive(tabIsActive);
        setCurrentTabAsActive();

    }, [isActive, content, currentData, loading]);

    if (!content || content.hidden || !isAuthorized) return null;

    const className = generateClassName("tab", {
        value: isActive,
        base: `tab-${variant}-`,
        onTrue: "active",
        standard: "inactive"
    }, {
        value: isActive,
        base: `tab-${size}-`,
        onTrue: "active",
        standard: "inactive"
    }, {
        value: size,
        base: "tab-"
    }, {
        value: variant,
        base: "tab-"
    });

    const labelClass = generateClassName("text-nowrap", {
        value: size === "regular",
        onTrue: "text-uppercase"
    })

    const getIconSize = () => {
        if (content.iconSize) return content.iconSize;
        else if (defaultIconSize) return defaultIconSize;

        switch (size) {
            case "regular": return 22;
            case "small": return 16;
            case "tiny": return 12;
        }
    }

    if (variant === "icons" && !content.icon) return null;
    else if (variant !== "icons" && !content.label) return null;

    return (
        <Flex row onClick={setCurrentTabAsActive} className={className} gap="2">
            { content.icon && <Icon icon={content.icon} color={isActive ? "secondary" : "muted"} tooltip={content.iconTooltip} size={getIconSize()} />}
            { variant !== "icons" && <span className={labelClass}>{content.label}</span>}
        </Flex>
    )
}