import React from "react";
import { useTeams } from "../../state/swr/teams/useTeams";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import TeamBanner from "./TeamBanner";
import { ITeam } from "../../types/team.schema";

export interface ITeamSelectProps { 
    onTeamClick: (team: ITeam) => void,
    selectedTeam?: string,
    returnNullOnEmpty?: boolean,
    saveAvailableTeams?: (teams: Array<ITeam>) => void,
    filterTeams?: (team: ITeam) => boolean
}

export default function TeamSelect({onTeamClick, returnNullOnEmpty, saveAvailableTeams, selectedTeam, filterTeams}: ITeamSelectProps) {
    
    const [availableTeams, setAvailableTeams] = React.useState<Array<ITeam>>([]);

    const { teams, loadingTeams } = useTeams();

    React.useEffect(() => {
        if (loadingTeams) return;

        if (!teams || !teams.length) {
            setAvailableTeams([]);
            return;
        }

        if (!filterTeams) {
            setAvailableTeams(teams);
            return;
        }

        const matchingTeams = teams.filter(filterTeams);
        setAvailableTeams(matchingTeams);
    }, [filterTeams, teams, loadingTeams]);

    React.useEffect(() => {
        if (!availableTeams || !availableTeams.length) return;
        onTeamClick(availableTeams[0]);
    }, [availableTeams]);
    
    React.useEffect(() => {
        if (!saveAvailableTeams) return;
        saveAvailableTeams(availableTeams)
    }, [availableTeams]);

    if (loadingTeams) return <LoadingSpinner text="Teams werden geladen..." />
    if (!availableTeams || !availableTeams.length) return returnNullOnEmpty ? null : <span>Keine Teams</span>

    return (
        <Flex row wrap >
            {
                availableTeams.map(t => <TeamBanner team={t} isActive={t._id === selectedTeam} onClick={() => onTeamClick(t)} key={t._id} />)
            }
        </Flex>
    )
}