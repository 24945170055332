export enum DatevEndpoint {
    Clients = "clients",
    CountryCodes = "country-codes",
    Addressees = "addressees",
    Relationships = "relationships",
    RelationshipTypes = "relationship-types",
    LegalForms = "legal-forms",
    Employees = "employees",
    EmployeeResponsibilities = "responsibilities",
    AreasOfResponsibility = "area-of-responsibilities"
}

export interface IPartialDatevImport {
    limitTo?: Array<DatevEndpoint>
}