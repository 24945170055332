import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IMailLog } from "../../../types/ApiTypes";

export const useMailLog = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IMailLog>>(ApiRoutes.MailLog.GetAll);

    return {
        mailLogs: data,
        loadingMailLogs: isLoading,
        reloadMailLogs: mutate
    }
}