import React from "react";
import Flex from "../../../container/Flex";
import TicketChatMessageAttachment from "./TicketChatMessageAttachment";
import useApi from "../../../../hooks/useApi";
import Button from "../../../buttons/Button";
import { ticketMessagesDownloadAttachments } from "../../../../api/Api";
import useDownloads from "../../../../hooks/useDownloads";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export default function TicketChatMessageAttachments({message}: {message: ITicketMessage}) {

    const callApi = useApi();

    const {
        downloadFile    
    } = useDownloads();

    const downloadAll = async () => {
        if (!message.attachments || !message.attachments.length) return;
        const res = await callApi(ticketMessagesDownloadAttachments({attachments: message.attachments, id: message._id}));
        if (res && res.success) downloadFile(res.data, res.data.content);
    }

    if (!message.attachments || !message.attachments.length) return null;

    return (
        <div className="d-flex flex-column w-100 gap-2 h-100">
            <Flex row justify="between" className="w-100">
                <strong>Anhänge</strong>
                <Button size="small" variant="text" onClick={downloadAll} >Alle herunterladen</Button>
            </Flex>
            <div className="d-flex flex-row align-items-center gap-2 flex-wrap">
                {
                    message.attachments.map(a => <TicketChatMessageAttachment key={a.googleCloudFileId} attachment={a} message={message}/>)
                }
            </div>
        </div>
    )
}