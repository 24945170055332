import { Field, Form, Formik } from "formik";
import React from "react";
import { ModalType } from "../../../config/ModalTypes";
import { generateClassName } from "../../../hooks/useAttributes";
import useModal from "../../../hooks/useModal";
import useUserUtil from "../../../hooks/useUserUtil";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket } from "../../../types/ApiTypes";
import { formatDate } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import Typography from "../../text/Typography";
import "./TicketNotes.css";
import { useTicketNotes } from "../../../state/swr/tickets/notes/useTicketNotes";
import TicketNote from "./TicketNote";
import Pill from "../../pills/Pill";

export default function TicketNotes({ ticket }: { ticket: ITicket }) {

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const {
    loadingTicketNotes,
    reloadTicketNotes,
    ticketNotes
  } = useTicketNotes(ticket._id);

  const { role } = useCurrentTenantRole();
  const showModal = useModal();

  if (!role || role.isClient) return null;

  const handleClass = generateClassName("ticket-notes-expander-handle d-flex gap-2 flex-row align-items-center justify-content-center", {
    base: "ticket-notes-expander-handle-",
    value: isExpanded,
    onTrue: "expanded"
  })

  const notesContainerClass = generateClassName("ticket-notes-container ", {
    base: "ticket-notes-container-",
    value: isExpanded,
    onTrue: "expanded p-3",
    standard: "hidden"
  })

  const iconClass = generateClassName("me-2 ticket-expander-icon", {
    value: isExpanded,
    onTrue: "ticket-expander-icon-expanded"
  })

  return (
    <div className="ticket-notes">
      <div className={handleClass} onClick={() => {
        if (loadingTicketNotes) return;
        setIsExpanded(!isExpanded)
      }
      }>
        {
          !!ticketNotes?.length && (
            <div style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              backgroundColor: "var(--error)"
            }}
            >
              {ticketNotes.length}
            </div>
          )
        }
        <Typography bold size={14} color="primary">{isExpanded ? "Notizen verstecken" : "Notizen anzeigen"}</Typography>
        <Icon loading={loadingTicketNotes} className={iconClass} icon="chevron-up" color="primary" />
      </div>
      <div className={notesContainerClass}>
        <div className="d-flex flex-row flex-wrap justify-content-start gap-3">
          {
            !!ticketNotes?.length && ticketNotes.map(n => <TicketNote note={n} key={n._id} ticket={ticket._id} />)
          }
          <TicketNote ticket={ticket._id} />
        </div>
      </div>
    </div>
  )
}