import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { IDatevEmployee } from "../../../types/employee.schema";

export const useDatevEmployees = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IDatevEmployee>>(ApiRoutes.Employees.All);

    return {
        datevEmployees: data?.items ?? [],
        datevEmployeesById: data?.byId ?? {},
        datevEmployeesByDatevId: data?.id ?? {},
        loadingDatevEmployees: isLoading,
        reloadDatevEmployees: mutate
    }
}