import React from "react";
import { teamsCreate, teamsUpdate } from "../../api/Api";
import { ITeamCreateRequest, ITeamUpdateRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useTeams } from "../../state/swr/teams/useTeams";
import { ITeam } from "../../types/team.schema";
import { getId } from "../../util/mongoUtil";
import Button from "../buttons/Button";
import Card from "../card/Card";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import ImageSelect from "../files/ImageSelect";
import FieldWithLabel from "../formik/FormikField";
import Icon from "../icons/Icon";
import Hyperlink from "../links/Hyperlink";
import LoadingSpinner from "../loader/LoadingSpinner";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import UserPicker from "../user/UserPicker";
import UserSelect from "../user/UserSelect";
import TeamBanner from "./TeamBanner";
import TeamDeleteButton from "./TeamDeleteButton";
import ColorPicker from "../formik/ColorPicker";

interface ITeamUpdateForm {
  team?: ITeam
}

export default function TeamUpdateForm({ team }: ITeamUpdateForm) {

  const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
  const { reloadTeams } = useTeams();

  const callApi = useApi();

  const formValues = (
    team
      ? {
        ...team,
        id: team._id,
        areaOfResponsibilityId: getId(team.areaOfResponsibility)
      } as ITeamUpdateRequest
      : {
        name: "",
        supervisor: "",
        color: "#FFFFFF",
        members: []
      } as ITeamCreateRequest
  )

  const buttonText = team ? "Bearbeiten" : "Team erstellen";
  const formText = team ? "Team bearbeiten" : "Neues Team";
  const buttonColor: AppColor = team ? "primary" : "success";
  const icon = team ? "pen" : "plus";

  return (
    <WithPermissions permissions={["teams.all.delete"]}>
      <ModalForm
        title={formText}
        button={<Button icon={icon} text={buttonText} color={buttonColor} />}
        initialValues={formValues}
        onSubmit={async (values) => {

          const result = team
            ? await callApi(teamsUpdate(values as ITeamUpdateRequest), true)
            : await callApi(teamsCreate(values as ITeamCreateRequest), true);

          if (!result) return false;

          await reloadTeams();
          return true;
        }}
        sidebar={(formik, close) => (
          <Flex>
            {
              team && (
                <TeamDeleteButton teamId={team._id} afterDelete={() => {
                  close();
                  reloadTeams();
                }} />
              )
            }
            <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
          </Flex>
        )}
      >
        {
          formik => (
            <Flex gap="4" fullWidth>
              <FieldWithLabel bold label="Name" name="name" className="w-100" />
              <Flex fullWidth>
                <Flex row fullWidth justify="between">
                  <Typography bold color="primary">Darstellung</Typography>
                  <TeamBanner team={formik.values} />
                </Flex>
                <Flex fullWidth>
                  {
                    (!formik.values.iconBase64 || formik.values.iconName) && (
                      <Flex fullWidth>
                        <Typography color="primary" bold>Icon</Typography>
                        <Flex row fullWidth>
                          <FieldWithLabel
                            placeholder="Name des Icons"
                            name="iconName"
                            className="w-100"
                          >
                            {
                              formik.values.iconName && <Icon color="muted" icon={formik.values.iconName} wrapperClass="position-absolute top-50 end-0 translate-middle" />
                            }
                          </FieldWithLabel>
                          {
                            formik.values.iconName && (
                              <Button
                                text="Icon entfernen"
                                onClick={() => formik.setFieldValue("iconName", "")}
                                color="error"
                                icon="trash"
                                variant="text"
                                size="small"
                              />
                            )
                          }
                        </Flex>
                        <Typography wrap color="muted">Icons: <Hyperlink externalLink openInNewTab to="https://icons.getbootstrap.com/">Bootstrap Icons.</Hyperlink>Bitte kopieren Sie den in der Liste angezeigten Namen in das Feld. Sollte ein Icon nicht angezeigt werden, ist es nicht verfügbar.</Typography>
                      </Flex>
                    )
                  }
                  {
                    (!formik.values.iconName || formik.values.iconBase64) && (
                      <ImageSelect
                        saveImage={(v) => formik.setFieldValue("iconBase64", v)}
                        image={formik.values.iconBase64}
                        render={select => (
                          <Flex fullWidth>
                            <Typography color="primary" bold>Bild</Typography>
                            <Flex row fullWidth justify="between" gap="4">
                              {
                                formik.values.iconBase64
                                  ? <img src={formik.values.iconBase64} width="50" height="50" />
                                  : <Typography color="muted">Kein Bild ausgewählt</Typography>
                              }
                              <Flex row>
                                <Button
                                  text="Bild auswählen"
                                  onClick={select}
                                  icon="image"
                                  variant="subtle"

                                />
                                {
                                  formik.values.iconBase64 && (
                                    <Button
                                      text="Bild entfernen"
                                      onClick={() => formik.setFieldValue("iconBase64", "")}
                                      color="error"
                                      icon="trash"
                                      variant="text"
                                    />)
                                }
                              </Flex>
                            </Flex>
                          </Flex>
                        )}
                      />
                    )
                  }
                </Flex>
                <Flex row>
                  <ColorPicker name="color" />
                </Flex>
              </Flex>
              <UserSelect
                bold
                label="Eskalations-Nutzer"
                onChange={v => formik.setFieldValue("supervisor", v)}
                value={formik.values.supervisor}
                displayed="employees"
              />
              <UserPicker
                bold
                displayed="employees"
                label="Team-Mitglieder"
                saveValues={v => formik.setFieldValue("members", v)}
                values={formik.values.members ?? []}
              />
              {
                team && (
                  <Card color="muted">
                    <strong>DATEV-Zuständigkeit</strong>
                    {
                      loadingAreasOfResponsibility
                        ? <LoadingSpinner text="Zuständigkeiten werden geladen..." centered={false} />
                        : (
                          areasOfResponsibility && areasOfResponsibility.length
                            ? (
                              <SearchableComboBox
                                values={areasOfResponsibility}
                                itemToId={r => r._id}
                                onItemClick={r => formik.setFieldValue("areaOfResponsibilityId", r?._id)}
                                value={(formik.values as ITeamUpdateRequest).areaOfResponsibilityId}
                                itemToString={r => r.name}
                              />
                            )
                            : <span>Keine Zuständigkeiten verfügbar. Diese können in DATEV angelegt werden.</span>
                        )
                    }
                  </Card>
                )
              }
            </Flex>
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}