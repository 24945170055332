import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IUserStatisticsForTeam } from "../../../types/ApiTypes";

export const useAllTeamsStatistics = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IUserStatisticsForTeam>>(ApiRoutes.Statistics.CurrentTenantTeams, { refetchOnWindowFocus: false });

    return {
        allTeamsStatistics: data,
        loadingAllTeamsStatistics: isLoading,
        reloadAllTeamsStatistics: mutate
    }
}